<div class="container login-container py-5">
  <div class="row">
      <div class="col-md-12">
          
          <div class="row">
              <div class="col-md-6 mx-auto" style="margin:auto">

                  <!-- form card login -->
                  <div class="card rounded-0">
                      <div class="card-header">
                        <p align="center">
                          <img width="150"  src="../../../assets/img/Elico-white.png" alt="" srcset="">
                        </p>
                      </div>
                      <div class="card-body">
                          <h5>CHOOSE AN ORGANISATION</h5>
                          <hr>
                          <form class="form">
                              <div class="form-group">
                                  
                                <input  type="radio" data-toggle="modal" data-target="#neworg"  name="uname1" id="cre"> <label for="cre">  &nbsp; *New Organisation</label>
                                <hr>
                              </div>


                              <div *ngFor="let companyUser of userManagementService.companyUsers" class="form-group">

                                 <input type="radio" [id]="companyUser.companyId._id" (click)="select(companyUser)"> <label [for]="companyUser.companyId.name"> &nbsp; {{companyUser.companyId.name}}</label>
                                 <hr>
                                  
                              </div>
                       
                            <div class="text-center">
                              <a id="footer" href=""><small><small>Elico Asset Management Application &copy; {{year}} All Rights Reserved </small></small></a> &nbsp; &nbsp;
                            </div>
                          </form>
                      </div>
                      <!--/card-block-->
                  </div>
                  <!-- /form card login -->

              </div>


          </div>
          <!--/row-->

      </div>
      <!--/col-->
  </div>
  <!--/row-->
</div>
<!--/container-->

<!-- Modals -->

<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="neworg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">*New Organisation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
           
      <div class="modal-body">
          <form class="form" #orgForm="ngForm">
              <div class="form-group">
                
                  <input type="text" [(ngModel)]="company.name"  name="cname" placeholder="Name" class="form-control rounded-0" required>
                
              </div>
              <div class="form-group">
                
                  <input type="email" [(ngModel)]="company.email"  placeholder="E-mail" name="cemail" class="form-control rounded-0" required>
                  
              </div>

              <div class="form-group">
                
                <input type="text" [(ngModel)]="company.tellphone"  name="ctell" placeholder="Telephone" class="form-control rounded-0" required >
                
              </div>
            
              
          </form>
        </div> 
            
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
          <button type="button" [disabled]="!orgForm.form.valid" (click)="this.userManagementService.signupCompany(company)" class="btn btn-elico" data-dismiss="modal">Continue</button>
        </div>   
        
      </div>
      
    </div>
  </div>


