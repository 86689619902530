import { UserManagementService } from './../services/user-management.service';
import { AssetsService } from './../services/assets.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { Router, NavigationEnd } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor( public spinner: NgxSpinnerService, public report:ReportingService, public assetService: AssetsService, public userManagementService:UserManagementService) {
    
  }

  ngOnInit() {
    
    this.assetService.getAssets();
    //this.assetService.getAssetClasses()
    this.report.initialiseDashboardCounts();
    this.report.initialiseDashboardStats();
  }

 

}
