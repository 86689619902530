import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class RestService {

  constructor() { }

   ENDPOINT= environment.API_URL; 
   //ENDPOINT="/api"; 

   Financial_Year = new Date('2019-02-28');

   ERROR_MESSAGE="Something went wrong. This might be a connection problem, please contact your system administrator."

  httpOtions = {
    headers : new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
}
