<div class="container-fluid">
  <div class="row">

    <!-- Main Sidebar -->

    <app-sidemenu></app-sidemenu>
    <!-- End Main Sidebar -->

    <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

     <app-topmenu></app-topmenu>


      <!-- / .main-navbar   DASH BOARD CONTENT HERE-->
      <div class="main-content-container container-fluid px-4">
        <!-- Page Header -->
        <div class="page-header row no-gutters py-4">
          <div class="col-12 col-sm-12 text-center text-sm-left mb-0">
            <span class="text-uppercase page-subtitle">Dashboard</span>
            <h3 align="center" class="page-title">Assets Overview</h3>
          </div>
        </div>
        <!-- End Page Header -->
        <!-- Small Stats Blocks -->

        <!----Instant Overviews Small columns---->
        <div class="row">
         
          <div class="col-lg col-md-6 col-sm-6 mb-4">
            <div class="stats-small stats-small--1 card card-small">
              <div class="card-body p-0 d-flex">
                <div class="d-flex flex-column m-auto">
                  <div class="stats-small__data text-center">
                    <span class="stats-small__label text-uppercase">Active Assets</span>
                    <h6 class="stats-small__value count my-3">{{report.activeassets}}</h6>
                  </div>
                  
                </div>
                
              </div>
            </div>
          </div>
          <div class="col-lg col-md-4 col-sm-6 mb-4">
            <div class="stats-small stats-small--1 card card-small">
              <div class="card-body p-0 d-flex">
                <div class="d-flex flex-column m-auto">
                  <div class="stats-small__data text-center">
                    <span class="stats-small__label text-uppercase">Assets Written Off</span>
                    <h6 class="stats-small__value count my-3">{{report.writtenoffassets}}</h6>
                  </div>
                  
                </div>
               
              </div>
            </div>
          </div>
          <div class="col-lg col-md-4 col-sm-6 mb-4">
            <div class="stats-small stats-small--1 card card-small">
              <div class="card-body p-0 d-flex">
                <div class="d-flex flex-column m-auto">
                  <div class="stats-small__data text-center">
                    <span class="stats-small__label text-uppercase">Under Maintenance</span>
                    <h6 class="stats-small__value count my-3">{{report.assetsundermaintenance}}</h6>
                  </div>
                  
                </div>
               
              </div>
            </div>
          </div>
          <div class="col-lg col-md-4 col-sm-12 mb-4">
            <div class="stats-small stats-small--1 card card-small">
              <div class="card-body p-0 d-flex">
                <div class="d-flex flex-column m-auto">
                  <div class="stats-small__data text-center">
                    <span class="stats-small__label text-uppercase">Asset Locations</span> 
                    <h6 class="stats-small__value count my-3">{{report.assetlocations}}</h6>
                  </div>
                  
                </div>
                <canvas height="120" class="blog-overview-stats-small-5"></canvas>
              </div>
            </div>
          </div>
        </div>
        <!-- End Small Stats Blocks -->


        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div class="card card-small h-100">
              <div class="card-header stat-header text-center">
                <h6 align="center" class="m-0">Work In Progress</h6>

              </div>   
              <div>
                <table class="table">
                  <tr>
                    <th>Asset Name</th>
                    <th>Started Working On</th>
                  </tr>
                  <tr *ngFor="let asset of report.workInGrogress">
                    <td>{{asset.name}}</td>
                    <td>{{asset.created | date:'fullDate'}}</td>
                  </tr>
              </table>
              </div>
            </div>
          </div>
          <!-- End Asset locator map -->


          
          <!-- Asset Location Map here -->
          <div class="col-lg-6 col-md-6 col-sm-12 mb-4">
            <div class="card card-small h-100">
              <div class="card-header stat-header text-center">
                <h6 align="center" class="m-0">Assets Per Department</h6>

              </div>   
              <div>
                <table class="table">
                    <tr>
                      <th>Departments</th>
                      <th>Assets</th>
                      <th>Cost</th>
                    </tr>
                    <tr *ngFor="let obj of report.assetsPerDepartment">
                        <td>{{obj['department']}}</td>
                        <td>{{obj['assets']}}</td>
                        <td>N$ {{this.report.roundTo2dp(obj['cost'])}}</td>  
                    </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- End Users By Device Stats -->

          <!-- End Top Referrals Component -->
        </div>
      </div>
      <app-footer></app-footer>
    </main>
  </div>
</div>