<footer class="main-footer d-flex p-2 px-3 bg-white border-top">
  <div class="container-fluid Bottom">

      <div class="row">
        <div class="col-lg-4 col-md-4"></div>
        
        <div class="col-lg-4 col-md-4 col-xs-12  wow fadeInRight"><p style="text-align:center">  </p></div>
      </div> 
    </div>
</footer>
