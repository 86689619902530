export class AssetComponent {
    
    _id:any;

    assetId : String;

    name: String;

    imageUrl:String;

    created: Date;

    deleted: boolean;

    modified: Date;
}
