
<div class="container-fluid">
  <div class="row">

    <!-- Main Sidebar -->

    <app-sidemenu></app-sidemenu>
    <!-- End Main Sidebar -->

    <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

     <app-topmenu></app-topmenu>


      <!-- / .main-navbar   DASH BOARD CONTENT HERE-->
      <div class="main-content-container container-fluid px-4">
        <!-- Page Header -->
        <div class="page-header row no-gutters py-4">
          <div class="col-12 col-sm-12 text-center text-sm-left mb-0">
            <span class="text-uppercase page-subtitle">Reports</span>
            <h3 align="center" class="page-title">Reports</h3>
          </div>
        </div>

        <div class="row"> 
 

          <div class="col-md-6 reports ">
            <div class="card" style="width: 100%;">
              <div class="card-header table-header text-center">
                A Statistical Insight On Your Assets' Data
              </div>
              <ul class="list-group list-group-flush ">
                <li  data-toggle="modal" (click)="selectAsset('Active Assets')" data-target="#range" class="list-group-item">Active Assets</li>
                <li  data-toggle="modal" (click)="selectAsset('Assets Under Maintenance')" data-target="#range" class="list-group-item">Assets Under Maintenance</li>
                <li  data-toggle="modal" (click)="selectAsset('Assets Sold')" data-target="#range" class="list-group-item">Assets Sold / Written Off</li>
                <li  data-toggle="modal" (click)="selectAsset('Assets Per Location')" data-target="#range" class="list-group-item">Assets Per Location</li>
                <li  data-toggle="modal" (click)="selectAsset('Assets Per User')" data-target="#range" class="list-group-item">Assets Per User</li>
                <li  data-toggle="modal" (click)="selectAsset('Fully Depreciated Assets')" data-target="#range" class="list-group-item">Fully Depreciated Assets</li>
                <li  data-toggle="modal" (click)="selectAsset('Work In Progress')" data-target="#range" class="list-group-item">Work In Progress</li>
                <li  data-toggle="modal" (click)="selectAsset('Assets Per Class')" data-target="#range" class="list-group-item">Assets Per Class</li> 
                <li  data-toggle="modal" (click)="selectAsset('Unassigned Assets')" data-target="#range" class="list-group-item">Unassigned Assets</li>
                <li  data-toggle="modal" (click)="selectAsset('Transferred Assets')" data-target="#range" title="Moved from one class to another class" class="list-group-item">Transferred Assets</li>  
                
              </ul>
            </div>
          </div>
        </div>
        

      </div>
      <app-footer></app-footer>
    </main>
  </div>
</div>

<button id="btn_open_json" hidden data-toggle="modal" data-target="#currentReport"> </button>

<div class="modal fade" id="currentReport" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog large-modal " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" [ngClass]="{'tableFixHead': this.reportService.data_format === 'Web'}" id="report">
          <div class="row">
            <div class="col-md-6">
              <img class="company-avater mr-2" width="200" [src]="auth.company_link.value" onError="this.src='../../../assets/img/com-default.png'" >
            </div>
            <div class="col-md-6">
              <h5  align="right">{{reportService.report_name}}</h5>
              <h5  *ngIf="this.reportService.select_date_by!='life_to_a_date'" class="" align="right" > From: {{reportService.start_date}}</h5>
              <h5  *ngIf="this.reportService.select_date_by=='life_to_a_date'" class="" align="right" > From: Inception</h5>
              <h5 class="" align="right" id="exampleModalLabel"> To: {{reportService.end_date}}</h5>
            </div>
           
           
        
         </div>
         
           <table class="table table-striped table-bordered">
             <thead>
              <tr class="table-header" >  
                <th>Asset No.</th>
                <th>Asset Name</th>
                <th>Serial Number</th>
                <th>Location</th>
                <th>Assigned</th>
                <th>Date Acquired</th>
                <th>Brought In Use</th>
                <th>Cost</th>
                <th>Opening Acc Depreciation</th>
                <th>Current Year Acc Depreciation</th>
                <th>Closing Acc Depreciation</th>
                <th>Net Book Value</th>
              </tr>
             </thead>
             
             <tr *ngFor="let asset of this.reportService.currentAssetsReport">
                <td>{{asset.assetNo}}</td>
                <td>{{asset.assetName}}</td>
                <td>{{asset.serialNumber}}</td>
                <td>{{asset.location}}</td>
                <td>{{asset.person}}</td>
                <td width="12%" >{{asset.acquired | date:'dd-MMM-yyyy'}}</td>
                <td width="12%" >{{asset.broughtInUse | date:'dd-MMM-yyyy'}}</td>
                <td align="right" width="12%">N$ {{this.reportService.roundTo2dp(asset.cost)}}</td>
                <td align="right" width="12%">N$ {{this.reportService.roundTo2dp(this.reportService.getOpenning(asset))}}</td>
                <td align="right" width="12%">N$ {{this.reportService.roundTo2dp(asset.adty)}}</td>
                <td align="right" width="12%">N$ {{this.reportService.roundTo2dp(asset.adld)}}</td> 
                <td align="right" width="12%">N$ {{this.reportService.roundTo2dp(asset.netBookValue)}}</td>
             </tr>
             <tr class="bottom-header" style="color:black; font-weight:bolder;">
              <th> Total</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>

              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.total_cost)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.total_opening)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.accumulatedDepThisYear)}}</th> 
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.accumulatedDepLifeToDate)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.total_nbv)}}</th>
             </tr> 
    
           </table>
           
  
        </div>
  
        <div class="modal-footer"> 
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="this.reportService.data_format === 'JSON'" type="button" data-dismiss="modal" (click)="printDiv('report')" class="btn btn-elico" >Print PDF</button> 
          
        </div>
      </div>
    </div>
  </div>

  <button id="btn_sold_items" hidden data-toggle="modal" data-target="#sold_items"> </button>

  <div class="modal fade" id="sold_items" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog large-modal " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"  id="sold_a">
          <div class="row">
            <div class="col-md-6">
              <img class="company-avater mr-2" width="200" [src]="auth.company_link.value" onError="this.src='../../../assets/img/com-default.png'" >
            </div>
            <div class="col-md-6">
              <h5  align="right">{{reportService.report_name}}</h5>
              <h5  *ngIf="this.reportService.select_date_by!='life_to_a_date'" class="" align="right" > From: {{reportService.start_date}}</h5>
              <h5  *ngIf="this.reportService.select_date_by=='life_to_a_date'" class="" align="right" > From: Inception</h5>
              <h5 class="" align="right" id="exampleModalLabel"> To: {{reportService.end_date}}</h5>
            </div>
           
           
        
         </div>
         
           <table class="table table-striped table-bordered">
             <thead>
              <tr class="table-header">  
                <th *ngFor="let sold_item of reportService.sold_assets_hearder">{{sold_item}}</th>
              </tr>
             </thead>
           
   
             <tr *ngFor="let asset of this.reportService.assets_sold">
                <td>{{asset.assetName}}</td>
                <td>{{asset.barcode}}</td>
                <td>{{asset.serialNumber}}</td>
                <td>{{asset.assetClass}}</td>
                <td>{{asset.location}}</td>
                <td>{{asset.reason_for_sale}}</td>
                <td>{{asset.dateSold | date:'dd-MMM-yyyy'}}</td>
                <td align="right" width="15%">N$ {{this.reportService.roundTo2dp(asset.cost)}}</td>
                <td align="right" width="15%">N$ {{this.reportService.roundTo2dp(asset.amount_sold_for)}}</td>
                <td align="right" width="15%">N$ {{this.reportService.roundTo2dp(asset.profit_or_loss)}}</td>
                <td align="right" width="15%">N$ {{this.reportService.roundTo2dp(asset.accumulated_depreciation_life_to_sale_date)}}</td>
                <td align="right" width="15%">N$ {{this.reportService.roundTo2dp(asset.net_book_value_at_sale)}}</td>
                
             </tr>
             <tr  class="bottom-header">
              <th>Total</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.is_cost)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.is_amount)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.is_profit)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.is_total_depreciation)}}</th> 
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.is_total__nbv)}}</th>
             </tr> 
    
           </table>
           
  
        </div>
  
        <div class="modal-footer"> 
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="this.reportService.data_format === 'JSON'" type="button" data-dismiss="modal" (click)="printDiv('sold_a')" class="btn btn-elico" >Print PDF</button> 
          
        </div>
      </div>
    </div>
  </div>

  <button id="btn_trans_items" hidden data-toggle="modal" data-target="#trans_items"> </button>

  <div class="modal fade" id="trans_items" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog large-modal " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Report</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body"  id="trans_a">
          <div class="row">
            <div class="col-md-6">
              <img class="company-avater mr-2" width="200" [src]="auth.company_link.value" onError="this.src='../../../assets/img/com-default.png'" >
            </div>
            <div class="col-md-6">
              <h5  align="right">{{reportService.report_name}}</h5>
              <h5  *ngIf="this.reportService.select_date_by!='life_to_a_date'" class="" align="right" > From: {{reportService.start_date}}</h5>
              <h5  *ngIf="this.reportService.select_date_by=='life_to_a_date'" class="" align="right" > From: Inception</h5>
              <h5 class="" align="right" id="exampleModalLabel"> To: {{reportService.end_date}}</h5>
            </div>
           
           
        
         </div>
         
           <table class="table table-striped table-bordered">
             <thead>
              <tr class="table-header">  
                <th *ngFor="let trans_item of reportService.transffered_assets_hearder">{{trans_item}}</th>
              </tr>
             </thead>
             
   
             <tr *ngFor="let asset of this.reportService.assets_transffered">
                <td>{{asset.assetName}}</td>
                <td>{{asset.serialNumber}}</td>
                <td>{{asset.assetClass}}</td>
                <td>{{asset.location}}</td>
                <td>{{asset.old_asset_no}}</td>
                <td>{{asset.new_asset_no}}</td>
                <td>{{asset.date_brought_in_use | date:'dd-MMM-yyyy'}}</td>
                <td width="15%">N$ {{this.reportService.roundTo2dp(asset.cost)}}</td>
                <td width="15%">N$ {{this.reportService.roundTo2dp(asset.accumulated_depreciation_life_to_transfere_date)}} </td>
                <td width="15%">N$ {{this.reportService.roundTo2dp(asset.net_book_value_at_trans)}}</td>
             </tr>
             <tr>
              <th><strong> Total</strong></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.tr_cost)}}</th>
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.tr_total_depreciation)}}</th> 
              <th align="right">N$ {{this.reportService.roundTo2dp(this.reportService.tr_total__nbv)}}</th>
             </tr> 
    
           </table>
           
  
        </div>
  
        <div class="modal-footer"> 
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="this.reportService.data_format === 'JSON'" type="button" data-dismiss="modal" (click)="printDiv('trans_a')" class="btn btn-elico" >Print PDF</button> 
          
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="range" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{selected_asset}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <form #rangeForm="ngForm">
             <div class="row">
               
                 <div class="col-md-12">
                   <label>Pull By</label>
                   <select name="date_by" id="dby" [(ngModel)]="this.reportService.select_date_by"  class="form-control">
                     <option value="created">Date Created</option>
                     <option value="aquistionDate">Date Acquired</option>
                     <option value="DateBroughtInUse">Date Brought In Use</option>
                     <option value="life_to_a_date">Life To A Date</option>
                   </select>
                 </div>
               
             </div>
             <p></p>
             <div class="row">
               <br>
                <div *ngIf="this.reportService.select_date_by!='life_to_a_date'" class="col-md-6">
                  <label>Start Date</label>
                  <input type="date" [(ngModel)]="this.reportService.start_date" name="_start" class="form-control">
                </div>
                <div class="col-md-6">
                  <label>End Date</label>
                  <input type="date" [(ngModel)]="this.reportService.end_date" name="_end" class="form-control">
                </div>
              
            </div>
            <p></p>
            <div class="row">
              
                <div class="col-md-6">
                  <label>Format</label>
                  <select name="format_by" id="fby" [(ngModel)]="this.reportService.data_format"  class="form-control">
                    <option value="Web">View On Web</option>
                    <option value="JSON">PDF</option>
                    <option value="Excel">Excel</option>
                  </select>
                
              </div>
              <div class="col-md-6">
                <label>Ownership</label>
                <select name="_ownership" id="owners" [(ngModel)]="this.reportService.ownership"  class="form-control">
                  <option value="Owned">Owned</option>
                  <option value="Leased">Leased</option>
                </select>
              
            </div>

            </div>
            
           </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="reportService.populateReportData(selected_asset)" class="btn btn-elico" data-dismiss="modal">Proceed</button>
        </div>
      </div>
    </div>
  </div>


