import { AssetComponent } from './../models/asset-component';
import { AssetMaintenanceHistory } from './../models/asset-maintenance-history';
import { AssetLocation } from './../models/asset-location';
import { DisposedAssets } from './../models/disposed-assets';
import { AssetLog } from './../models/asset-log';
import { UserManagementService } from './user-management.service';
import { AssetClass } from './../models/asset-class';
import { Asset } from './../models/asset';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, assertPlatform } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Fullasset } from '../models/fullasset';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { NgxSpinnerService } from 'ngx-spinner';
import { HIGH, MEDIUM } from '../../config/enums/app-usage-effect-level.enum';
import { ASSETS } from '../../config/constants/end-points.const';
import { formatDate } from '../util';



@Injectable()
export class AssetsService {

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private rest: RestService, private userManagementService: UserManagementService) { }

  /**
   * Assets
   */

  selectedAsset = new Asset();
  assets = new Array<Asset>();

  /**
   * Full Assets (Elico cloud 22-04-2022)
   */

  selectedFullAsset = new Fullasset();
  fullAssets = new Array<Fullasset>();

  /**
   * Assets Class
   */

  selectedAssetClass = new AssetClass();
  assetClasses = new Array<AssetClass>();

  /**
   * Asset Logs
   */

  selectedLog = new AssetLog();
  assetLogs = new Array<AssetLog>();

  /**
  * Disposed Assets
  */

  selectedDisposedAsset = new DisposedAssets();
  disposedAssests = new Array<DisposedAssets>();

  /**
   * Locations
   */

  selectedAssetLocation = new AssetLocation;
  locations = new Array<AssetLocation>();

  /**
   * Assets Maintance History,
   */
  selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
  assetMaintenanceHistories = Array<AssetMaintenanceHistory>();


  /**
   * Asset components 
   */

  selectedAssetComponent = new AssetComponent();
  assetComponents = new Array<AssetComponent>();


  status_filter = 'Active';
  class_filter = 'All';
  user_filter = 'All';
  group_filter = "All";




  async addAsset() {
    try {

      this.spinner.show();

      this.selectedAsset.companyId = this.userManagementService.getCompany()._id;
      this.selectedAsset.createdBy = this.userManagementService.getUser()._id;

      const asset = await this.http.post<Asset>(this.rest.ENDPOINT + ASSETS, {...this.selectedAsset,userId:this.userManagementService.getUser()._id}).toPromise();

      console.log(asset);

      //this.addAssetLog(asset._id, "Asset asset created", HIGH, asset);

      this.getAssets();

      this.toastr.success(`Asset ${asset.name} successfully added!`);

    } catch (error) {
      console.error(error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }

  async ImportAsset(asset: Asset, index: number) {
    try {
      this.spinner.show();
      asset.companyId = this.userManagementService.getCompany()._id;
      const response = await this.http.post<Asset>(this.rest.ENDPOINT + "/assets", {...asset,userId:this.userManagementService.getUser()._id}).toPromise();
      console.log(`Asset ${asset.name} successfully imported!`);
    } catch (error) {
      console.error("Error importing asset: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      //this.getAssets();
      this.spinner.hide();
    }
  }
  



  async getAssets() {
    this.spinner.show();
    try {
      const fullassets = await this.http.post<Array<Fullasset>>(this.rest.ENDPOINT + "/fullassets", this.get_query()).toPromise();
      this.fullAssets = fullassets;
      console.log(fullassets);
    } catch (error) {
      console.error("Error fetching assets: ", error);
      this.toastr.info("No assets found");
    } finally {
      this.spinner.hide();
    }
  }


  async getAsset(id) {
    this.spinner.show();

    try {
      const asset = await this.http.get<Asset>(this.rest.ENDPOINT + "/assets/" + id).toPromise();
      this.selectedAsset = asset;
    } catch (error) {
      console.error("Error fetching asset: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  get_query() {

    let query = {};

    query['companyId'] = this.userManagementService.getCompany()._id;
    query['deleted'] = false;
    this.status_filter != 'Disposed' ? query['isDisposed'] = false : query['isDisposed'] = true;
    this.status_filter != 'Under Maintanance' ? query['underMaintenance'] = false : query['underMaintenance'] = true;
    this.class_filter != "All" ? query['classId'] = this.class_filter : query = query;
    this.user_filter != "All" ? query['assignedTo'] = this.user_filter : query = query;
    this.group_filter != "All" ? query['groupId'] = this.group_filter : query = query;

    return query;
  }

  async updateAsset() {

    this.spinner.show();

    try {
      const res = await this.http.put(this.rest.ENDPOINT + "/assets/" + this.selectedAsset._id, {...this.selectedAsset,userId:this.userManagementService.getUser()._id}).toPromise();

      console.log(res);

      await this.getAssets(); 

      this.toastr.success(`Asset ${this.selectedAsset.name} successfully updated!`);

    } catch (error) {
      console.error("Error updating asset: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async deleteAsset() {
    this.spinner.show();
    this.selectedAsset.deleted = true;

    try {

      const res = await this.http.put(this.rest.ENDPOINT + "/assets/" + this.selectedAsset._id, this.selectedAsset).toPromise();
      
      await this.getAssets();

      this.toastr.success(`Asset ${this.selectedAsset.name} successfully deleted!`);

    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  getAssetClassById(classId): string {
    for (let i = 0; i < this.assetClasses.length; i++) {
      if (this.assetClasses[i]._id == classId) {
        return this.assetClasses[i].name;
      }
    }
    return "";
  }

  getAssetMaterialById(classId): string {

    for (let i = 0; i < this.assetClasses.length; i++) {
      if (this.assetClasses[i]._id == classId) {
        return this.assetClasses[i].material;
      }
    }

    return "";
  }


  getAssetClassDescriptionById(classId): string {

    for (let i = 0; i < this.assetClasses.length; i++) {
      if (this.assetClasses[i]._id == classId) {
        if (!this.assetClasses[i].description) {
          return "";
        }
        return this.assetClasses[i].description;
      }
    }

    return "";
  }

  getAssetCurrentValue(asset: Asset): number {

    this.spinner.show();

    let now: Date = new Date();
    let dateBroughtInUse = new Date(asset.DateBroughtInUse);

    let daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);
    let monthsLeftToLifeEnd = this.getUseFulLife(asset.classId) - daysAssetAsBeenInUse;


    let residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);

    let value = (((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsLeftToLifeEnd) || asset.purchasePrice;
    this.spinner.hide();

    if (value < 0) {
      return residualValue;
    }

    return value + residualValue;

  }

  getAssetValueAtDate(asset: Asset, date: Date): number {
    this.spinner.show();

    let now: Date = new Date(date);
    let dateBroughtInUse = new Date(asset.DateBroughtInUse);

    let daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);
    let monthsLeftToLifeEnd = this.getUseFulLife(asset.classId) - daysAssetAsBeenInUse;


    let residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);

    let value = ((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsLeftToLifeEnd;
    this.spinner.hide();

    if (value < 0) {
      return 0 + residualValue;
    }
    return value + residualValue;
  }





  getResidualvalue(classId, purchasePrice): number {

    let residualPercentage = 0;

    for (let i = 0; i < this.assetClasses.length; i++) {

      if (this.assetClasses[i]._id == classId) {
        residualPercentage = this.assetClasses[i].residualValue;
      }
    }

    if (residualPercentage <= 0) {
      return 0;
    }

    return (residualPercentage / 100) * purchasePrice;
  }


  getUseFulLife(classId): number {

    let useFulLife = 0;

    for (let i = 0; i < this.assetClasses.length; i++) {

      if (this.assetClasses[i]._id == classId) {
        useFulLife = this.assetClasses[i].usefulLife;
      }
    }

    return useFulLife * 365;
  }

  getDaysDiff(d1: Date, d2: Date) {

    var Difference_In_Time = d2.getTime() - d1.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days < 0) {
      return 0;
    }

    return Difference_In_Days;

  }

  getUseFulLifeInYears(classId): number {

    let useFulLife = 0;
    for (let i = 0; i < this.assetClasses.length; i++) {
      if (this.assetClasses[i]._id == classId) {
        useFulLife = this.assetClasses[i].usefulLife;
      }
    }
    return useFulLife;
  }

  getAccumulatedAssetDepreciationLifeToDate(asset: Asset) {

    let now: Date = new Date();

    let dateBroughtInUse = new Date(asset.DateBroughtInUse);

    let usefullLife_in_days = this.getUseFulLife(asset.classId)

    let daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);

    let depreciation_days = daysAssetAsBeenInUse > usefullLife_in_days ? usefullLife_in_days : daysAssetAsBeenInUse


    let residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);



    let value = (((asset.purchasePrice - residualValue /**depreciatable amount*/) / this.getUseFulLife(asset.classId))/**In months */ * depreciation_days) || 0;

    return value;

  }





  getAccumulatedAssetDepreciationThisYear(asset: Asset) {

    let now: Date = new Date();

    let firstDayOfYear = new Date(now.getFullYear(), 0, 1);

    let monthsAssetAsBeenInUseThisYear = 0;

    if (new Date(asset.DateBroughtInUse) < firstDayOfYear) {

      monthsAssetAsBeenInUseThisYear = this.getDaysDiff(firstDayOfYear, now);

    } else {

      monthsAssetAsBeenInUseThisYear = this.getDaysDiff(new Date(asset.DateBroughtInUse), now);

    }


    let residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);

    let value = ((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsAssetAsBeenInUseThisYear;

    let depreciatable_amount = asset.purchasePrice - residualValue;

    if (this.getAccumulatedAssetDepreciationLifeToDate(asset) + residualValue == asset.purchasePrice) {
      return 0;
    }

    return value;
  }


  getAccumulatedAssetDepreciationBtnDates(asset: Asset, startDate: Date, endDate: Date) {

    let daysAssetAsBeenInUse = 0;

    //console.log("start: "+startDate+" End: "+endDate);

    startDate = new Date(startDate);

    endDate = new Date(endDate);

    const dateBroughtInUse = new Date(asset.DateBroughtInUse);



    let assetEndLifeDate: Date = this.getLastDateOfDepreciation(new Date(asset.DateBroughtInUse), this.getUseFulLife(asset.classId));



    //console.log("start: "+startDate+" End: "+endDate+" Brought In Use: "+dateBroughtInUse+ " Asset end life Date: "+assetEndLifeDate+" Usefull life Days: "+this.getUseFulLife(asset.classId));

    if (startDate <= dateBroughtInUse && endDate >= assetEndLifeDate) {
      //console.log("within asset life");
      daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, assetEndLifeDate);

    }

    else if (startDate >= dateBroughtInUse && endDate <= assetEndLifeDate) {
      // console.log("within asset life");
      daysAssetAsBeenInUse = this.getDaysDiff(startDate, endDate);
    }

    else if (startDate <= dateBroughtInUse && endDate > dateBroughtInUse && endDate < assetEndLifeDate) {
      // console.log("btn asset start life");
      daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, endDate);
    }

    else if (startDate >= dateBroughtInUse && startDate < assetEndLifeDate && endDate > assetEndLifeDate) {
      //console.log("btn asset end");
      daysAssetAsBeenInUse = this.getDaysDiff(startDate, assetEndLifeDate);
    }

    else if ((startDate <= dateBroughtInUse && endDate <= dateBroughtInUse) || (startDate >= assetEndLifeDate && endDate >= assetEndLifeDate)) {
      //console.log("Outside asset life");
      daysAssetAsBeenInUse = 0;
    }
    //console.log(asset.name);
    // console.log("ASSETS HAS BEEN IN USE: "+daysAssetAsBeenInUse);

    let residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);

    let depreciatable_amount = asset.purchasePrice - residualValue;

    // console.log("residualValue: "+residualValue);

    // console.log("depr amount: "+depreciatable_amount);

    let value = ((depreciatable_amount / this.getUseFulLife(asset.classId)) * daysAssetAsBeenInUse);

    return value;

  }


  getLastDateOfDepreciation(broughtInUse: Date, lifeTimeInDays: number): Date {

    broughtInUse.setDate(broughtInUse.getDate() + lifeTimeInDays);
    return broughtInUse;

  }



  /**
   * Asset Class
   */

  async addAssetClass() {
    this.spinner.show();
    this.selectedAssetClass.companyId = this.userManagementService.getCompany()._id;
    this.selectedAssetClass.createdBy = this.userManagementService.getUser()._id;

    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/assetclasses", this.selectedAssetClass).toPromise();
      console.log(res);
      await this.getAssetClasses();
      this.toastr.success(`Asset class ${this.selectedAssetClass.name} successfully added!`);
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  /**
   * Create Default Asset class
   */



  async getAssetClasses() {
    this.spinner.show();
    try {
      const classes = await this.http.get<Array<AssetClass>>(`${this.rest.ENDPOINT}/assetclasses?companyId=${this.userManagementService.getCompany()._id}&&deleted=false`).toPromise();
      this.assetClasses = classes;
      await this.getAssets(); // Make sure this is an async function or returns a Promise
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async updateAssetClass() {

    this.spinner.show();
    try {
      const res = await this.http.put(this.rest.ENDPOINT + "/assetclasses/" + this.selectedAssetClass._id, this.selectedAssetClass).toPromise();
      console.log(res);

      await this.getAssetClasses(); // Assuming this is an async function
      this.toastr.success("Asset class successfully updated!");
    } catch (error) {
      console.error("Error updating asset class: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async deleteAssetClass() {
   
    this.spinner.show();

    this.selectedAssetClass.deleted = true;

    try {
      const res = await this.http.put(this.rest.ENDPOINT + "/assetclasses/" + this.selectedAssetClass._id, this.selectedAssetClass).toPromise();

      console.log(res);

      await this.getAssetClasses(); // Assuming this is an async function
      this.toastr.success(`Asset class ${this.selectedAssetClass.name} successfully deleted!`);
    } catch (error) {
      console.error("Error deleting asset class: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  /**
   * Asset Locations
   */


  async addAssetLocation() {
    this.spinner.show();
    this.selectedAssetLocation.companyId = this.userManagementService.getCompany()._id;

    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/locations", this.selectedAssetLocation).toPromise();
      console.log(res);

      await this.getAssetLocations(); // Assuming this is an async function
      this.toastr.success(`Location ${this.selectedAssetLocation.name} successfully added!`);

    } catch (error) {
      console.error("Error adding location: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async getAssetLocations() {
    this.spinner.show();

    try {
      const locations = await this.http.get<Array<AssetLocation>>(`${this.rest.ENDPOINT}/locations?companyId=${this.userManagementService.getCompany()._id}`).toPromise();
      this.locations = locations;
    } catch (error) {
      console.error("Error fetching asset locations: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async updateAssetLocation() {
    this.spinner.show();

    try {
      const res = await this.http.put(this.rest.ENDPOINT + "/locations/" + this.selectedAssetLocation._id, this.selectedAssetLocation).toPromise();
      console.log(res);

      await this.getAssetLocations(); // Assuming this is an async function
      this.toastr.success("Location successfully updated!");
    } catch (error) {
      console.error("Error updating location: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async deleteAssetLocation() {

    this.spinner.show();

    this.selectedAssetLocation.deleted = true;

    try {
      const res = await this.http.put(this.rest.ENDPOINT + "/locations/" + this.selectedAssetLocation._id,this.selectedAssetLocation).toPromise();
      console.log(res);

      await this.getAssetLocations(); // Assuming this is an async function
      this.toastr.success(`Location ${this.selectedAssetLocation.name} successfully deleted!`);
    } catch (error) {
      console.error("Error deleting location: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }

//TODO: THIS MUST BE DONE IN THE BACKEND
  getLocationNameById(locationId) {
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i]._id == locationId) {
        return this.locations[i].name;
      }
    }
  }

  /**
   * Asset Logs
   */

 


  async getAssetLogs(assetId) {
    this.spinner.show();
    try {
      const logs = await this.http.get<Array<AssetLog>>(this.rest.ENDPOINT + '/assetlogs?assetId=' + assetId).toPromise();
      this.assetLogs = logs;
    } catch (error) {
      console.error("Error fetching asset logs: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  /**
   * Assets Maintenance history
   */

  async addAssetMaintenanceHistory() {
    this.spinner.show();
    this.selectedAssetMaintenceHistory.assetId = this.selectedAsset._id;
    this.selectedAssetMaintenceHistory.createdBy = this.userManagementService.getUserIdByEmail(this.userManagementService.getUser().email);
    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/amhs", this.selectedAssetMaintenceHistory).toPromise();
      console.log(res);

      await this.getMaintenanceHistories(this.selectedAsset._id); // Assuming this is an async function
    } catch (error) {
      console.error("Error adding asset maintenance history: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async getMaintenanceHistories(assetId) {
    try {
      const amhs = await this.http.get<Array<AssetMaintenanceHistory>>(`${this.rest.ENDPOINT}/amhs/?assetId=${assetId}&&deleted=false`).toPromise();
      this.assetMaintenanceHistories = amhs;
    } catch (error) {
      console.error("Error fetching maintenance histories: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    }
  }


  async updateMaintenanceHistory() {
    this.spinner.show();
    try {
      await this.http.put(this.rest.ENDPOINT + '/amhs/' + this.selectedAssetMaintenceHistory._id, this.selectedAssetMaintenceHistory).toPromise();
      await this.getMaintenanceHistories(this.selectedAsset._id); // Assuming this is an async function
      
    } catch (error) {
      console.error("Error updating maintenance history: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async deleteMaintenanceHistory() {
    this.spinner.show();
    this.selectedAssetMaintenceHistory.deleted = true;
    try {
      await this.http.put(this.rest.ENDPOINT + "/amhs/" + this.selectedAssetMaintenceHistory._id,this.selectedAssetMaintenceHistory).toPromise();
      await this.getMaintenanceHistories(this.selectedAssetMaintenceHistory._id);
      
    } catch (error) {
      console.error("Error deleting maintenance history: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  /**
   * Asset disposal / write off
   */

  async addDisposedAsset() {
    this.spinner.show();

    try {
      this.selectedDisposedAsset.assetId = this.selectedAsset._id;
      this.selectedDisposedAsset.profitMade = this.selectedDisposedAsset.auctionPrice - this.getAssetCurrentValue(this.selectedAsset);
      this.selectedDisposedAsset.currentValue = this.getAssetCurrentValue(this.selectedAsset);
      this.selectedDisposedAsset.disposedBy = this.userManagementService.getUserIdByEmail(this.userManagementService.getUser().email);

      const res = await this.http.post(this.rest.ENDPOINT + '/disposedassets', this.selectedDisposedAsset).toPromise();
      console.log(res);

      this.toastr.success("Asset successfully disposed!");

    } catch (error) {
      console.error("Error disposing asset: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async getDisposedAssets() {
    this.spinner.show();

    try {
      const disposedassets = await this.http.get<Array<DisposedAssets>>(this.rest.ENDPOINT + '/disposedassets/?assetId=' + this.selectedAsset._id).toPromise();
      this.disposedAssests = disposedassets;
    } catch (error) {
      console.error("Error fetching disposed assets: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  /**
   * Asset components 
   */

  async addAssetComponent() {
    this.spinner.show();

    try {
      this.selectedAssetComponent.assetId = this.selectedAsset._id;

      const res = await this.http.post(this.rest.ENDPOINT + "/assetcomponents", this.selectedAssetComponent).toPromise();
      console.log(res);

      await this.getAssetComponent();
     
    } catch (error) {
      console.error("Error adding asset component: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async getAssetComponent() {
    this.spinner.show();
    try {
      const res = await this.http.get<Array<AssetComponent>>(`${this.rest.ENDPOINT}/assetcomponents/?assetId=${this.selectedAsset._id}&&deleted=false`).toPromise();
      this.assetComponents = res;
    } catch (error) {
      console.error("Error fetching asset components: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  async updateAssetComponent() {
    this.spinner.show();

    try {
      await this.http.put(this.rest.ENDPOINT + "/assetcomponents/" + this.selectedAssetComponent._id, this.selectedAssetComponent).toPromise();
      await this.getAssetComponent();
      
    } catch (error) {

      console.error("Error updating asset component: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);

    } finally {
      this.spinner.hide();
    }
  }


  async deleteAssetComponent() {
    this.spinner.show();
    this.selectedAssetComponent.deleted = true;
    try {
      await this.http.put(this.rest.ENDPOINT + "/assetcomponents/" + this.selectedAssetComponent._id, this.selectedAssetComponent).toPromise();
      await this.getAssetComponent();
      
    } catch (error) {
      console.error("Error deleting asset component: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }


  convertAssetToFull(asset: Fullasset): Fullasset {

    console.log(asset);

    let fullasset = new Fullasset();

    fullasset.assetNo = asset.assetNo;
    fullasset.assetName = asset.assetName;
    fullasset.serialNumber = asset.serialNumber;
    fullasset.assetClass = asset.assetClass;
    fullasset.assetClassDescription = asset.assetClassDescription;
    fullasset.material = asset.material;
    fullasset.location = asset.location
    fullasset.department = asset.department
    fullasset.person = asset.person
    fullasset.ownership = asset.ownership;
    fullasset.created = formatDate(new Date(asset.created));
    fullasset.acquired = formatDate(new Date(asset.acquired));
    fullasset.broughtInUse = formatDate(new Date(asset.broughtInUse));
    fullasset.usefullLife = asset.usefullLife;
    fullasset.residualValue = asset.residualValue;
    fullasset.cost = asset.cost;
    fullasset.openingDepreciation = asset.openingDepreciation;
    fullasset.adty = asset.adty;
    fullasset.adld = asset.adld;
    fullasset.netBookValue = asset.netBookValue
   

    return fullasset;

  }

  getFullAssetArray(assets: Array<Fullasset>): Array<Fullasset> {

    let fullassets = new Array<Fullasset>();

    //console.log(assets);  

    for (let i = 0; i < assets.length; i++) {
      fullassets.push(this.convertAssetToFull(assets[i]))
    }

    return fullassets;

  }

  getCleanFullAssets(fullassets: Array<Fullasset>) {
    let clean_assets = [];
    for (let i = 0; i < fullassets.length; i++) {
      let clean_asset = Object.assign({}, this.fullAssets[i]);
      delete clean_asset['_id'];
      clean_assets.push(clean_asset);
    }
    return clean_assets;
  }

  exportToExel() {
    this.spinner.show();
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: "Assets",
      useBom: true,
      noDownload: false,
      headers: ["Asset No", "Asset Name", 'Serial Number', "Asset Class", "Asset Class Description", "Material", "Location", "Department", "Responsible Person", "Ownership", "Date Created", "Date Acquired", "Date Brought In use", "Usefull Life", "Residual Value", "Cost", "Net Book Value", "Accumulated Depreciation Life To Date", "Accumulated Depreciation This Year"],
      nullToEmptyString: true,
    };
    new Angular5Csv(this.getFullAssetArray(this.fullAssets), 'Elico Asset Management Report', options);
    this.spinner.hide();
  }

}
