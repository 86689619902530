<div class="container-fluid">
  <div class="row">

    <!-- Main Sidebar -->

    <app-sidemenu></app-sidemenu>
    <!-- End Main Sidebar -->

    <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

     <app-topmenu></app-topmenu>


      <!-- / .main-navbar   DASH BOARD CONTENT HERE-->
      <div class="main-content-container container-fluid px-4">
        <!-- Page Header -->
        <div class="page-header row no-gutters py-4">
          <div class="col-12 col-sm-12 text-center text-sm-left mb-0">
            <span class="text-uppercase page-subtitle">Users</span>
            <h3 align="center" class="page-title">User Management</h3>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12 people-container">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link text-uppercase active" id="home-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">Users</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-uppercase" id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">Groups</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <!-- Users -->
                  <div class="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
                    <br> 
                    
                       <form class="top-form">

                          <input    type="text" class="filter" [(ngModel)]="userFilter.fullname" name="n_filter"  placeholder="Filter" width="300">
                          <button class="btn btn-elico add" data-toggle="modal" (click)="newUser()" data-target="#addMember"> <i class="fas fa-user-plus"></i>&nbsp; Invite New Member</button>

                       </form>
                      
                    
                     <div class="card">
                        <table class="table">
                       
                            <tbody>
                              <tr *ngFor="let user of this.userManagementService.users | filterBy: userFilter">
                                <td>
                                  <a href="javascript:;">
                                 
                                  <span class="details">
                                      <div class="name">
                                          {{user.fullname}}
                                      </div>
                                      <div class="email">
                                          {{user.email}}
                                      </div>
                                  </span>
                                 </a>
                                </td>

                                <td class="btns btn-group">
                                    
                                      <!-- isLoggedInUser -->
                                    <button    (click)="editUser(user)" class="btn btn-elico" data-toggle="modal" data-target="#addMember"><i class="fas fa-edit"></i>  Edit</button>
                                    <button    (click)="resetPass(user)" class="btn btn-secondary" data-toggle="modal" data-target="#changePassword"><i class="fas fa-lock-open"></i>  Reset Password</button>
                                    <button  [disabled]="this.userManagementService.isLoggedInUser(user.email)" (click)="selectedUserId=user._id;" class="btn btn-secondary" data-toggle="modal" data-target="#removeUser"><i class="fas fa-times"></i>  Remove</button>
                                </td>
                                
                              </tr> 
                              
                            </tbody>
                          </table>
                     </div>
                  </div>
                  <!-- Groups -->
                  <div class="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                    <br> 

                    <form class="top-form">
                      <input type="text" [(ngModel)]="groupFilter.name" class="filter" name="g_filter" placeholder="Filter" width="300">
                      <button class="btn btn-elico add" data-toggle="modal" (click)="newGroup()" data-target="#addGroup"> <i class="fas fa-users"></i>&nbsp; *New Group</button>
                    </form>
                   
                    <div class="card">
                       <table class="table">
                      
                           <tbody>
                             <tr>
                               <th>Name</th>
                              
                               <th>Open</th>
                               <th>Delete</th>
                             </tr>
                             <tr *ngFor="let group of this.userManagementService.groups  | filterBy: groupFilter"> 
                              <td>{{group.name}}</td>
                              
                              <td><button class="btn btn-elico" data-toggle="modal" data-target="#addGroup"  (click)="this.userManagementService.selectedGroup=group; this.userManagementService.getGroupMembers();">Open</button></td>
                              <td><button (click)="selectedGroupId=group._id;" class="btn btn-secondary" data-toggle="modal" data-target="#removeGroup"><i class="fas fa-times"></i>  Remove</button></td>
                               
                             </tr> 
                             
                           </tbody>
                         </table>
                    </div>
                  </div>
                  
                </div> 
          </div>
        </div>
       


       
      </div>
      <app-footer></app-footer>
    </main>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="addMember" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Member</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #invitationForm="ngForm">
          <div class="form-group">
              <div class="row">
                  <div class="col-md-12">
                    <label>Full Name</label>
                    <input type="text" placeholder="Full Name" [(ngModel)]="fullname" name="f_name" class="form-control" required>
                  </div>
                  
                </div>
            <div class="row">
              <div class="col-md-6">
                <label>Email</label>
                <input type="email" placeholder="E-Mail Address" [(ngModel)]="email" name="email" class="form-control" required>
              </div>
              <div class="col-md-6">
                  <label>Role</label>
               <select class="form-control" aria-placeholder="Role" [(ngModel)]="role" name="role">
                 <option *ngFor="let role of this.userManagementService.roles" [value]="role._id">{{role.name}}</option>
               </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button *ngIf="!isEditing" type="button" [disabled]="!invitationForm.form.valid" (click)="this.userManagementService.inviteNewMember(email,role,fullname)" class="btn btn-elico" data-dismiss="modal">Invite</button>
        <button *ngIf="isEditing" type="button" [disabled]="!invitationForm.form.valid" (click)="this.userManagementService.updateMember(email,role,fullname,selectedUserId)" class="btn btn-elico" data-dismiss="modal">Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="removeUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete User</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         <p>Are you sure, you want to delete this user?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="this.userManagementService.removeCompanyUser(selectedUserId)" class="btn btn-danger" data-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Reset Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <div class="col-md-12">
              <label>New Password</label>
             <input type="password" placeholder="New Password" [(ngModel)]="new_password" name="_new_password" class="form-control" required>
           </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="this.userManagementService.resetPassword(new_password,email)" class="btn btn-danger" data-dismiss="modal">Reset</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Group Modal -->

  <div class="modal fade" id="addGroup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Group</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form #groupForm="ngForm">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12">
                    <label>Name</label>
                    <input type="text" placeholder="Group Name" [(ngModel)]="this.userManagementService.selectedGroup.name" name="group" class="form-control" required>
                  </div>
                  
                 
                </div>
              </div>
              <div class="form-group">
                  <div class="row">
                    
                    <div class="col-md-12">
                        <label>Group Description</label>
                        <textarea type="text" placeholder="Group Description" [(ngModel)]="this.userManagementService.selectedGroup.description" name="description" class="form-control"></textarea>
                    </div>
                   
                  </div>
                </div>
              <button type="button" [hidden]="this.userManagementService.selectedGroup._id" [disabled]="!groupForm.form.valid" (click)="this.userManagementService.addGroup()" class="btn btn-elico">Save</button>
              <button type="button" [hidden]="!this.userManagementService.selectedGroup._id" [disabled]="!groupForm.form.valid" (click)="this.userManagementService.updateGroup()" class="btn btn-elico">Update</button>
              <div *ngIf="this.userManagementService.selectedGroup._id" class="form-group">
                <hr>
                <div class="row">
                    <div class="col-md-4">
                        <label>All Users</label>
                     <select multiple class="form-control">
                       <option  *ngFor="let user of this.userManagementService.users" [hidden]="this.userManagementService.isAlreadyMember(user.email)" (click)="this.userManagementService.selectedUser=user" [value]="user.email">{{this.userManagementService.getNameFromEmail(user.email)}}</option>
                     </select>
                    </div>
                    <div class="col-md-3 action-btns">
                   
                     
                          <button class="btn btn-elico" [disabled]="!this.userManagementService.selectedUser._id" (click)="this.userManagementService.addGroupMember()">Add</button>
                     
                  
                          <button [disabled]="!this.userManagementService.selectedGroupMember._id" (click)="this.userManagementService.removeMember(this.userManagementService.selectedGroupMember._id)" class="btn btn-elico">Remove</button>
                      
                       
                                         
                     </div>
                    <div class="col-md-4">
                        <label>Group Members</label>
                     <select multiple class="form-control" >
                       <option *ngFor="let member of this.userManagementService.groupMembers" (click)="this.userManagementService.selectedGroupMember=member" [value]="member.email">{{this.userManagementService.getNameFromEmail(member.email)}}</option>
                     </select>
                    </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="removeGroup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Delete Group</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
             <p>
               Are you sure, you want to delete this group?
            </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" (click)="this.userManagementService.removeGroup(selectedGroupId)" class="btn btn-danger" data-dismiss="modal">Delete</button>
            </div>
          </div>
        </div>
      </div>
