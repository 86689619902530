<div class="container signup-container py-5">
  <div class="row">
      <div class="col-md-12">
          
          <div class="row">
              <div class="col-md-4 mx-auto">

                  <!-- form card login -->
                  <div class="card rounded-0">
                      <div class="card-header">
                            <p align="center">
                                <img width="150"  src="../../../assets/img/Elico-white.png" alt="" srcset="">
                            </p>
                      </div>
                      <div class="card-body">
                          <form class="form" #signupForm="ngForm">
                              <div class="form-group">
                                 
                                  <input type="email" [(ngModel)]="email" placeholder="E-mail Address" class="form-control rounded-0" name="email_" required>   
                              </div>
                              
                              <div class="form-group">
                                 
                                  <input type="password" name="pass"  [(ngModel)]="newPassword" placeholder="New Password" class="form-control rounded-0 " required>
                                  
                              </div>
                              <div class="form-group">
                                 
                                  <input type="password" name="cp"  [(ngModel)]="confirmPassword" placeholder="Confirm Password" class="form-control rounded-0 "  required>
                                  
                              </div>
                             
                              

                              <button  [disabled]="!signupForm.form.valid" (click)="signup()" class="btn form-control  btn-elico" id="btnLogin">SIGNUP</button>
                              
                              <br>
                              
                             

                          
                          
                            <hr>
                            <div class="text-center">
                              <a id="footer" href="#/home"><small><small>Elico Asset Management Application &copy; {{year}} All Rights Reserved </small></small></a> &nbsp; &nbsp;
                              
                              
                            </div>
                          </form>
                      </div>
                      <!--/card-block-->
                  </div>
                  <!-- /form card login -->

              </div>


          </div>
          <!--/row-->

      </div>
      <!--/col-->
  </div>
  <!--/row-->
</div>
<!--/container-->

