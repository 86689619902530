export class Fullasset {
   _id: string;
   assetNo:string;
   assetName:string;
   serialNumber:string;
   assetClass:string;
   assetClassDescription:string;
   material:string;
   location:string;
   department:string;
   person:string;
   ownership:string;
   created:string;
   acquired:string;
   broughtInUse:string;
   usefullLife:number;
   residualValue:number;
   cost:number;
   openingDepreciation:number;
   adty:number;
   adld:number;
   netBookValue:number;
}
