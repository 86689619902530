import * as tslib_1 from "tslib";
import { RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';
export function tokenGetter() {
    return localStorage.getItem('access_token');
}
var AARouteReuseStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(AARouteReuseStrategy, _super);
    function AARouteReuseStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AARouteReuseStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    AARouteReuseStrategy.prototype.store = function (route, handle) {
    };
    AARouteReuseStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    AARouteReuseStrategy.prototype.retrieve = function (route) {
        return null;
    };
    AARouteReuseStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return false; // default is true if configuration of current and future route are the same
    };
    return AARouteReuseStrategy;
}(RouteReuseStrategy));
export { AARouteReuseStrategy };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
