export class DisposedAssets {
    _id:any;

    assetId : string;

    reasonForDisposal : string;

    disposedBy : string;

    currentValue : number;

    profitMade : number;

    auctionPrice:number=0;

    disposalDate: Date;

    created:Date;

    deleted:boolean;

    modified:Date;
}
