
        <aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
          <div class="main-navbar">

            <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
              <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
                <div class="d-table m-auto">
                  <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 120px;" src="../../../assets/img/logo.png" >
                  
                </div>
              </a>
              <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none">
                <i class="material-icons">&#xE5C4;</i>
              </a>
            </nav>

          </div>

          <!---search bar here--->
          <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
            <div class="input-group input-group-seamless ml-3">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fas fa-search"></i>
                </div>
              </div>
              <input class="navbar-search form-control" type="text" placeholder="Search for something..." aria-label="Search"> </div>
          </form>

          <div class="nav-wrapper">
            <ul  class="nav flex-column">
              <li (click)="this.auth.goToRout('dashboard')" class="nav-item">
                <a class="nav-link" href="javascript:;">
                  <i class="material-icons">dashboard</i>
                  <span>Dashboard</span>
                </a>
              </li>
              <li (click)="this.auth.goToRout('assets')" class="nav-item">
                <a class="nav-link " href="javascript:;">
                  <i class="material-icons">time_to_leave</i>
                  <span>Assets</span>
                </a>
              </li>
              <li *ngIf="this.userManager.isSuperUser()"  class="nav-item">
                <a class="nav-link " href="#/users">
                  <i class="material-icons">group</i>
                  <span>Users</span>
                </a>
              </li>
              <li class="nav-item" (click)="this.auth.goToRout('reports')">
                <a class="nav-link " href="javascript:;">
                  <i class="material-icons">timeline</i>
                  <span>Reports</span>
                </a>
              </li>
              <li class="nav-item" (click)="this.auth.goToRout('settings')">
                <a class="nav-link " href="javascript:;">
                  <i class="material-icons">settings</i>
                  <span>Settings</span>
                </a>
              </li>
              
             
            </ul>
          </div>
        </aside>
