import { RestService } from './../services/rest.service';
import { UserManagementService } from './../services/user-management.service';
import { Component, OnInit } from '@angular/core';
import { Group } from '../models/group';
import { CompanyUser } from '../models/company-user';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  constructor(public userManagementService:UserManagementService,public rest:RestService) { }

  role:String= "";
  email:String = "";
  fullname:String="";
  selectedUserId:String ="";
  selectedGroupId:String ="";
  new_password:String="";

  isEditing = false;

  userFilter: any = {fullname:''};

  groupFilter: any = { name:''};

  ngOnInit() {
    this.userManagementService.getCompanyUsers();
    this.userManagementService.getRoles();
    this.userManagementService.getGroups();
  }

  getImage(id):String{
    return this.rest.ENDPOINT+"/stream/img-"+id; 
  }  

  newGroup(){
    this.userManagementService.selectedGroup = new Group(); 
  }

  editUser(user:CompanyUser){
      this.isEditing = true;
      this.email = user.email;
      this.fullname = user.fullname;
      this.role = user.role._id;
      this.selectedUserId= user._id;
  }

  newUser(){
    this.isEditing = false;
    this.email = ""
    this.fullname = "";
    this.role = "";
  }

  resetPass(user:CompanyUser){
     this.email = user.email;
  }

  



}
