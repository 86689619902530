export class Report {
    
    _id:any;

    name : String;

    companyId: String;

    createdBy:  String;

    type: String;

    query: String;

    deleted:boolean;

    description:String;

    created:Date;
    

    modified: Date;

}
