<div class="container-fluid">
  <div class="row">

    <!-- Main Sidebar -->

    <app-sidemenu></app-sidemenu>
    <!-- End Main Sidebar -->

    <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

     <app-topmenu></app-topmenu>


      <!-- / .main-navbar   DASH BOARD CONTENT HERE-->
      <div class="main-content-container container-fluid px-4">
        <!-- Page Header -->
        <div class="page-header row no-gutters py-4">
          <div class="col-12 col-sm-12 text-center text-sm-left mb-0">
            
            <h3 align="center" class="page-title">Assets Management</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 people-container">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link text-uppercase active" id="assets-tab" data-toggle="tab" href="#assets" role="tab" aria-controls="users" aria-selected="true">Assets</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-uppercase" id="classes-tab" data-toggle="tab" href="#classes" role="tab" aria-controls="groups" aria-selected="false">Asset Classes</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-uppercase" id="locations-tab" data-toggle="tab" href="#locations" role="tab" aria-controls="locations" aria-selected="false">Asset Locations</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <!-- Users -->
                  <div class="tab-pane fade show active" id="assets" role="tabpanel" aria-labelledby="assets-tab">
                       <br>
                       <div class="form-row filters">
                        <div class="col">
                          <label >Search By Name</label>
                          <input type="text" class="form-control" [(ngModel)]="assetFilter.assetName" placeholder="Search By Name" width="300"> &nbsp;
                        </div>
                        <div class="col">
                          <label style="font-size: 12px;" >Search By Asset No.</label>
                          <input type="text" class="form-control" [(ngModel)]="assetNumberFilter.assetNo" placeholder="Search By Asset No." width="300"> &nbsp;
                        </div>
                        <div class="col">
                          <label >Filter By Status</label>
                          <select name="assetStats" class="form-control" [(ngModel)]="assetService.status_filter" (change)="onFilterChange($event)"> 
                            <option value="Active">Active</option>
                            <option value="Disposed">Disposed/Written Off</option>
                            <option value="Under Maintanance">Under Maintanance</option>
                         </select> 
                        </div>
                        <div class="col">
                          <label >Filter By Class</label>
                          <select name="assetStats" class="form-control" [(ngModel)]="assetService.class_filter" (change)="onFilterChange($event)"> 
                            <option value="All">All</option>
                            <option *ngFor="let clas of this.assetService.assetClasses" [value]="clas._id">{{clas.name}}</option> 
                          </select> 
                        </div>
                        <div class="col">
                          <label >Filter By User</label>
                          <select name="assetStats" class="form-control" [(ngModel)]="assetService.user_filter" (change)="onFilterChange($event)"> 
                            <option value="All">All</option>
                            <option *ngFor="let user of this.userManagementService.users" [value]="user._id">{{this.userManagementService.getNameFromEmail(user.email)}}</option>
                            <option value="unassigned">Unassigned</option>
                          </select> 
                        </div>
                        <div class="col">
                          <label >Filter By Group</label>
                          <select name="assetStats" class="form-control" [(ngModel)]="assetService.group_filter" (change)="onFilterChange($event)">
                            <option value="All">All</option>
                            <option *ngFor="let group of this.userManagementService.groups" [value]="group._id">{{group.name}}</option>
                          </select> 
                        </div>
                        <div class="col">
                          <label >Add New Asset</label>
                          <button class="btn btn-elico form-control" (click)="newAsset()" data-toggle="modal" data-target="#addAsset"> <i class="material-icons">time_to_leave</i>&nbsp; *New Asset</button>
                        </div>
                       
                        <div class="col">
                          <label for="importDropdown">Bulk Import</label>
                          <div class="dropdown">
                            <button class="btn btn-elico  form-control dropdown-toggle" type="button" id="importDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Import Options
                            </button>
                            <div class="dropdown-menu" aria-labelledby="importDropdown">
                              <a class="dropdown-item" style="color: lightskyblue;" href="../../../assets/templates/elico_asset_import_template.csv" download >Download Import Template</a>
                              <div class="dropdown-divider"></div>
                              <label class="dropdown-item btn-elico form-control" for="csv" style="margin: auto; width: 98%;">
                                <i class="material-icons" style="color: aliceblue;">attach_file</i> Attach CSV File
                              </label>
                              <input type="file" hidden placeholder="" accept=".xlsx, .csv" id="csv" name="csvFile" (change)="csv2Array($event)">
                            </div>
                          </div>
                        </div>
                        
                        <div class="col">
                          <label >Export All To Excel</label>
                          <button class="btn btn-elico form-control" (click)="this.assetService.exportToExel()"> <i class="fas fa-file-excel"></i> Export To Excel</button>
                        </div>
                      </div>
                      <br>
                     <div class="card"> 
                       <table class="table ">
                         <tr  class="table-header">
                           <th>Picture</th>
                           <th>Asset No</th>
                           <th>Name</th>
                           <th>Class</th>
                           <th>Assigned</th> 
                           <th>Group</th>
                           <th>Cost</th>
                           <th>Net Book Value</th>
                           <th>More</th> 
                         </tr>
                         <tr  *ngFor="let asset of this.assetService.fullAssets | filterBy: assetFilter | filterBy: assetNumberFilter"  >
                            <td width="5%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view" ><img width="40" height="40" class="user-avatar rounded-circle mr-2" [src]="getAssetImage(asset._id)" onError="this.src='../../../assets/img/asset.png'" ></td> 
                            <td width="10%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">{{asset.assetNo}}</td> 
                            
                            <td width="15%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">{{asset.assetName}}</td>
                            <td width="8%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">{{asset.assetClass}}</td>
                            <td width="8%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">{{asset.person}}</td>
                            <td width="8%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">{{asset.department}}</td>
                            <td width="15%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">N$ {{roundTo2dp(asset.cost)}}</td>
                            <td width="15%" (click)="this.selectedAssetId = asset._id; this.assetService.selectedFullAsset=asset" data-toggle="modal" data-target="#assetPicture" title="Click to view">N$ {{roundTo2dp(asset.netBookValue)}}</td>  
                            <td width="5%" >
                            <i class="fas fa-ellipsis-h"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                
                                </i>
                                <div class="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuButton">
                                  <a  [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="editAsset(asset)" data-toggle="modal" data-target="#addAsset" href="javascript:;"><i class="far fa-edit"></i> Edit</a>

                                  <!-- <a [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="this.assetService.selectedAsset=asset;this.assetService.getAssetComponent()" data-toggle="modal" data-target="#Components"  href="javascript:;"><i class="fas fa-puzzle-piece"></i> Components</a>
                                   -->
                                  <a [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="this.assetService.selectedFullAsset=asset;" data-toggle="modal" data-target="#barcode" href="javascript:;"><i class="fas fa-barcode"></i> Barcode</a>
                                  <a [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="this.assetService.selectedFullAsset=asset; this.assetService.getAssetLogs(asset._id);" data-toggle="modal" data-target="#assetLogs" href="javascript:;"><i class="fas fa-list-ul"></i> Asset Logs</a> 
                                  <a [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="this.assetService.selectedFullAsset=asset; this.assetService.getMaintenanceHistories(asset._id); assetService.getAsset(asset._id);" data-toggle="modal" data-target="#maintance_history" href="javascript:;"><i class="fas fa-wrench"></i> Maintain</a>
                                  <a [hidden]="asset.isDisposed==true" class="dropdown-item" (click)="this.assetService.selectedFullAsset=asset;assetService.getAsset(asset._id);" data-toggle="modal" data-target="#dispose_asset" href="javascript:;"><i class="fas fa-minus-circle"></i> Dispose/ Write Off</a>
                                  <a  class="dropdown-item" (click)="this.assetService.selectedFullAsset=asset; assetService.getAsset(asset._id);" data-toggle="modal" data-target="#removeAsset" href="javascript:;"><i class="fas fa-trash-alt"></i> Delete</a> 
                                </div>  
                                 
                            </td>
                            
                        </tr>

                       </table>
                     </div>
                  </div>
                  <!-- Groups -->
                  <div class="tab-pane fade" id="classes" role="tabpanel" aria-labelledby="classes-tab">
                    <br> 
                    <p>
                      <input type="text" [(ngModel)]="classFilter.name" class="filter" placeholder="Filter" width="300">
                      <button class="btn btn-elico add" data-toggle="modal" (click)="newClass()" data-target="#addClass">*New Class </button>
                    </p>

                    <div class="card">
                      <table class="table">  
                        <tr class="table-header">
                          <th>Class Code</th>
                          <th>Name</th>
                          <th>Useful Life(Years)</th>
                          <th>Material </th>
                          <th>Residual Value</th>
                          <th>Edit</th> 
                          <th>Delete</th>    
                        </tr>
                        
                        <tr *ngFor="let clas of this.assetService.assetClasses  | filterBy: classFilter" [title]="clas.description">
                          <td>{{clas.classPrefix}}</td>
                          <td>{{clas.name}}</td>
                          <td>{{clas.usefulLife}}</td>
                          <td>{{clas.material}}</td>
                          <td>{{clas.residualValue}}</td>
                          <td><button  data-toggle="modal" (click)="this.assetService.selectedAssetClass=clas;" data-target="#addClass" class="btn btn-elico">Edit</button></td>
                          <td><button  data-toggle="modal" (click)="selectedClassId=clas._id;" data-target="#removeClass" class="btn btn-secondary">Delete</button></td>
                        </tr>

                      </table>
                    </div>
                   
                  </div>

                  <!-- Locations -->

                  <div class="tab-pane fade" id="locations" role="tabpanel" aria-labelledby="locations-tab">
                    <br> 
                    <p>
                      <input type="text" [(ngModel)]="locationFilter.name" class="filter" placeholder="Filter" width="300">
                      <button class="btn btn-elico add" data-toggle="modal" (click)="newLocation()" data-target="#addLocation">*New Location</button>
                    </p>

                    <div class="card"> 
                      <table class="table">  
                        <tr class="table-header">
                          <th>Name</th>
                          <th>Street</th>
                          <th>Town</th>
                          <th>Country</th>
                          <th>Edit</th> 
                          <th>Delete</th>    
                        </tr>
                        
                        <tr *ngFor="let location of this.assetService.locations  | filterBy: locationFilter">

                          <td>{{location.name}}</td>
                          <td>{{location.street}}</td>
                          <td>{{location.town}}</td>
                          <td>{{location.country}}</td>
                          <td><button  data-toggle="modal" (click)="this.assetService.selectedAssetLocation=location;" data-target="#addLocation" class="btn btn-elico">Edit</button></td>
                          <td><button  data-toggle="modal" (click)="selectedLocationId=location._id;" data-target="#removeLocation" class="btn btn-secondary">Delete</button></td>

                        </tr>

                      </table>
                    </div>
                   
                  </div>
                  
                </div> 
          </div>
        </div>
        
      </div>
      <app-footer></app-footer>
    </main>
  </div>
</div>

<asset-form [display]="showAssetForm"></asset-form>

<!-- Modal -->
<div class="modal fade" id="addClass"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog addasset-modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">*New Class</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #classForm="ngForm">
            <div class="form-group">
              <div class="row">
                  <div class="col-md-6">
                     <label>Class Name</label>
                      <input type="text" [(ngModel)]="this.assetService.selectedAssetClass.name" name="_name" placeholder="Class Name" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label>Useful Life (Years) </label>
                        <input type="number" [(ngModel)]="this.assetService.selectedAssetClass.usefulLife" name="_usefulLife" placeholder="Useful Life In Years" class="form-control">
                      </div>
              </div>
            </div>
            <div class="form-group">
                <div class="row">
                    
                    <div class="col-md-12">
                        <label>Material</label>
                        <input type="text" [(ngModel)]="this.assetService.selectedAssetClass.material" name="_material" placeholder="Material" class="form-control" required>
                    </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <label>Residual Value (%)</label>
                        <input type="number" [(ngModel)]="this.assetService.selectedAssetClass.residualValue" name="_residualValue" placeholder="Residual Value" class="form-control" required>
                      </div>
                     
                      

                      <div class="col-md-6">
                          <label>Asset Class Code</label>
                          <input type="text" [(ngModel)]="this.assetService.selectedAssetClass.classPrefix" name="_classPrefix" placeholder="e.g MVC for vehicles" class="form-control">
                      </div>
                </div>
              </div>
              <div class="form-group">
                  <div class="row">
                      <div class="col-md-12">
                          <label>Description</label>
                        <textarea type="number" [(ngModel)]="this.assetService.selectedAssetClass.description" name="_description" placeholder="Description (Optional)" class="form-control" ></textarea>
                      </div>
                  </div>
                  
                </div>
              
          </form>
        </div>
        <div class="modal-footer"> 
          <button type="button"  class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!this.assetService.selectedAssetClass._id" type="button" (click)="this.assetService.addAssetClass()" data-dismiss="modal" [disabled]="!classForm.form.valid"  class="btn btn-elico">Save</button>
          <button *ngIf="this.assetService.selectedAssetClass._id" type="button" (click)="this.assetService.updateAssetClass()"  data-dismiss="modal" [disabled]="!classForm.form.valid"  class="btn btn-elico">Update</button>
        </div>
      </div>
    </div>
</div>

  <div class="modal fade" id="removeClass" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Class</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         <p>
           Are you sure, you want to delete this Class?
        </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="this.assetService.deleteAssetClass()" class="btn btn-danger" data-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="dispose_asset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Dispose Asset</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
             <form #assetDisposal="ngForm">
               <div class="form-group">
                 <div class="row">
                    <div class="col-md-12">
                        <label>Reason For Disposal/Write Off</label>
                        <input type="text" [(ngModel)]="this.assetService.selectedDisposedAsset.reasonForDisposal" name="a_rofd" placeholder="Reason For Disposal/Write Off" class="form-control">
                    </div>
                 </div>
               </div>
               <div class="form-group">
                  <div class="row">
                     <div class="col-md-6">
                         <label>Auction Price (if applicable)</label>
                         <input type="number" [(ngModel)]="this.assetService.selectedDisposedAsset.auctionPrice" name="d_ap" placeholder="Auction Price" class="form-control">
                     </div>
                     <div class="col-md-6"> 
                        <label>Disposal/Write Off Date</label>
                        <input type="date" [(ngModel)]="this.assetService.selectedDisposedAsset.disposalDate" name="d_date" placeholder="Disposal/Write Off Date" class="form-control">
                    </div>
                  </div>
                </div>
             </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" (click)="this.assetService.addDisposedAsset()" class="btn btn-elico" data-dismiss="modal">Save</button>
          </div>
        </div>
      </div>
  </div>

  <!-- Asset Modal -->
<div class="modal fade" id="removeAsset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Asset</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p>
         Are you sure, you want to delete this Asset?
      </p>
      </div>
      <div class="modal-footer"> 
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="this.assetService.deleteAsset()" class="btn btn-danger" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="Components" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Components</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <table class="table">
           <tr class="table-header"> 

             <th>Picture</th>
             <th>Name</th>
             <th>Edit</th>
             <th>Delete</th>

           </tr>
           <tr *ngFor="let component of this.assetService.assetComponents">
              <td></td>
              <td>{{component.name}}</td>
              <td><button (click)="this.assetService.selectedAssetComponent=component; isNewComponent=true"  class="btn btn-elico">Edit</button></td>
              <td><button (click)="this.assetService.selectedAssetComponent=component"  data-toggle="modal" data-target="#removeComponent" class="btn btn-danger">Delete</button></td>
              <td></td>
           </tr>
 
         </table>
         <br>
         <form #compForm="ngForm" *ngIf="isNewComponent">
          <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <input type="text" [(ngModel)]="this.assetService.selectedAssetComponent.name" name="l_name" placeholder="Component Name" class="form-control" required>
                </div>  
            </div>
          </div>

         </form>

         <button [hidden]="isNewComponent" (click)="newComponent()" class="btn btn-elico">*New Record</button>
         <button [hidden]="!isNewComponent" (click)="cancelComponent()" class="btn btn-secondary">Cancel</button>
      </div>

      <div class="modal-footer"> 
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" [hidden]="this.assetService.selectedAssetComponent._id" (click)="this.assetService.addAssetComponent()" class="btn btn-elico" >Save</button>
        <button type="button" [hidden]="!this.assetService.selectedAssetComponent._id" (click)="this.assetService.updateAssetComponent()" class="btn btn-elico" >Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="maintance_history" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog addasset-modal" role="document">
      <div class="modal-content"> 
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{this.assetService.selectedAsset.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <input type="checkbox" name="maintananceStatus" id="aum" [(ngModel)]="this.assetService.selectedAsset.underMaintenance" (change)="onMaintananceChange($event)"> <label for="aum">Asset Under Maintanance</label>
           <table class="table">
             
             <tr class="table-header" >
  
               <th>Name</th>
               <th>Cost</th>
               <th>Created By</th>
               <th>Date</th>
               <th>Edit</th>
               <th>Delete</th>
  
             </tr>
             <tr *ngFor="let maintanance of this.assetService.assetMaintenanceHistories" [title]="maintanance.description">
                
                <td>{{maintanance.name}}</td>
                <td>{{maintanance.cost}}</td>
                <td>{{this.userManagementService.getUserNameById(maintanance.createdBy)}}</td>
                <td>{{maintanance.date | date:'fullDate'}}</td>
                <td><button (click)="this.assetService.selectedAssetMaintenceHistory=maintanance; isNewMaintanance=true"  class="btn btn-elico">Edit</button></td>
                <td><button (click)="this.assetService.selectedAssetMaintenceHistory=maintanance"  data-toggle="modal" data-target="#removeMaintanance" class="btn btn-danger">Delete</button></td>
                
             </tr>
   
           </table>
           <br>
           <form #maintForm="ngForm" *ngIf="isNewMaintanance">
            <div class="form-group">
              <div class="row">
                  <div class="col-md-12">
                      <input type="text" [(ngModel)]="this.assetService.selectedAssetMaintenceHistory.name" name="m_name" placeholder="Work Done" class="form-control" required>
                  </div>
                    
              </div>
            </div>
            <div class="form-group">
                <div class="row">
                    
                    <div class="col-md-6">
                        <input type="number" [(ngModel)]="this.assetService.selectedAssetMaintenceHistory.cost" name="m_cost" placeholder="Cost" class="form-control" required>
                    </div> 
                    <div class="col-md-6">
                        <input type="date" [(ngModel)]="this.assetService.selectedAssetMaintenceHistory.date" name="m_date" placeholder="Date" class="form-control" required>
                    </div>   
                </div>
              </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <textarea [(ngModel)]="this.assetService.selectedAssetMaintenceHistory.description"  name="m_description" class="form-control" required></textarea>
                </div>
              </div>
            </div>
  
           </form>
  
           <button [hidden]="isNewMaintanance" (click)="newMaintanance()" class="btn btn-elico">*New Record</button>
           <button [hidden]="!isNewMaintanance" (click)="cancelMaintanance()" class="btn btn-secondary">Cancel</button>
        </div>
  
        <div class="modal-footer"> 
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" [hidden]="this.assetService.selectedAssetMaintenceHistory._id" (click)="this.assetService.addAssetMaintenanceHistory()" class="btn btn-elico" >Save</button>
          <button type="button" [hidden]="!this.assetService.selectedAssetMaintenceHistory._id" (click)="this.assetService.updateMaintenanceHistory()" class="btn btn-elico" >Update</button>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade" id="removeComponent" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Location</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p>
         Are you sure, you want to delete this Component?
      </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="this.assetService.deleteAssetComponent()" class="btn btn-danger" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="removeMaintanance" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Maintanance</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
         <p>
           Are you sure, you want to delete this Record?
        </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" (click)="this.assetService.deleteMaintenanceHistory()" class="btn btn-danger" data-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade" id="barcode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{this.assetService.selectedAsset.name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p>
        <ngx-barcode id="pbc" [bc-value]="this.assetService.selectedFullAsset.assetNo" [bc-display-value]="true"></ngx-barcode>
      </p> 
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-elico" (click)="printbar()" data-dismiss="modal"><i class="fas fa-print"></i> Print</button>

      </div>
      
    </div>
  </div>
</div>

<div class="modal fade" id="assetLogs" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog addasset-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{this.assetService.selectedAsset.name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-bordered"> 
          <tr class="table-header">

            <th>Action</th>
            <th>Effect Level</th>
            <th>User</th>
            <th>Asset Modified on</th>

          </tr>
          <tr *ngFor="let log of this.assetService.assetLogs">
             <td [title]="log.action">{{getShortAction(log.action)}}</td>
             <td>{{log.effectLevel}}</td>  
             <td>{{this.userManagementService.getUserNameById(log.userId)}}</td> 
             <td>{{log.created | date:'fullDate'}}</td>
          </tr>

        </table>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
       

      </div>
      
    </div>
  </div>
</div>


<!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->

<div class="modal fade" id="assetPicture" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  addasset-modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 align="center"  class="modal-title" id="exampleModalLabel">{{this.assetService.selectedAsset.name}}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span> 
          </button>
        </div>
        <div class="modal-body">
            <div class="container-flid">
              <div class="row">
                  <div class="col-md-6 ">
                      <p align="center">
                          <img width="100%"   class="user-avatar rounded mr-2" [src]="getAssetImage(selectedAssetId)" onError="this.src='../../../assets/img/asset.png'" >
                         </p> 
                         <p align="center">
                            <label class="btn btn-elico" for="asset"><i class="material-icons"> add_photo_alternate</i> Change Picture </label> 
                          <input  type="file" hidden placeholder="" accept="image/*" id="asset"  name="assetImage"  (change)="handleAssetsImageInput($event.target.files)">
                         </p> 
                         <p align="center">
                            <ngx-barcode id="abc" [bc-value]="this.assetService.selectedFullAsset.assetNo" [bc-display-value]="true"></ngx-barcode>
                            <br>
                            <button class="btn btn-elico" data-toggle="modal" data-target="#barcode" data-dismiss="modal">Print Barcode</button>
                         </p>
                  </div>
                  <div class="col-md-6 deatils"> 
                      <h5 >Asset Details</h5>  
                    
                      <hr>  
                      <address >
                        <p><i class="fas fa-car"></i> Asset Name: {{this.assetService.selectedFullAsset.assetName}}</p>
                        <p><i class="fas fa-barcode"></i> Asset No: {{this.assetService.selectedFullAsset.assetNo}}</p>
                        <p><i class="fas fa-map-marker-alt"></i>  Location: {{this.assetService.selectedFullAsset.location}}</p>  
                        <p> <i class="far fa-calendar-alt"></i> Aquired On:  {{this.assetService.selectedFullAsset.acquired | date:'fullDate'}}</p>
                        <p><i class="far fa-calendar-alt"></i> Brought In Use On:  {{this.assetService.selectedFullAsset.broughtInUse | date:'fullDate'}}</p>
                        <p><i class="far fa-user"></i> Assigned To: {{this.assetService.selectedFullAsset.person}}</p>
                        <p><i class="fas fa-users"></i> Assigned To Group: {{this.assetService.selectedFullAsset.department}}</p>
                        <p><i class="fas fa-dollar-sign"></i> Cost: N$ {{roundTo2dp(this.assetService.selectedFullAsset.cost)}}</p>
                        <p><i class="fas fa-dollar-sign"></i> Net Book Value: N$ {{roundTo2dp(this.assetService.selectedFullAsset.netBookValue)}}</p>
                        <p><i class="fas fa-object-group"></i> Asset Class: {{this.assetService.selectedFullAsset.assetClass}}</p>

                      </address>  
                  </div> 
              </div>
            </div>
             
            
            
        </div>
        <div class="modal-footer"> 

          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          
        </div>
      </div>
    </div>
</div>


<!-- Modal Location -->
<div class="modal fade" id="addLocation"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">*New Location</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #locationForm="ngForm">
          <div class="form-group">
            <div class="row">
                <div class="col-md-6">
                    <input type="text" [(ngModel)]="this.assetService.selectedAssetLocation.name" name="l_name" placeholder="Location Name" class="form-control">
                  </div>
                  <div class="col-md-6">
                      <input type="text" [(ngModel)]="this.assetService.selectedAssetLocation.street" name="street" placeholder="Street Name" class="form-control">
                  </div>
            </div>
          </div>
          <div class="form-group">
              <div class="row">
                  <div class="col-md-6">
                    <input type="text" [(ngModel)]="this.assetService.selectedAssetLocation.town" name="town" placeholder="Town" class="form-control" >
                  </div>
                  <div class="col-md-6">
                      <input type="text" [(ngModel)]="this.assetService.selectedAssetLocation.country" name="country" placeholder="Country" class="form-control" required>
                  </div>
              </div>
            </div>
            
            
        </form>
      </div>
      <div class="modal-footer">
        <button type="button"  class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button *ngIf="!this.assetService.selectedAssetLocation._id" type="button" (click)="this.assetService.addAssetLocation()" data-dismiss="modal" [disabled]="!locationForm.form.valid"  class="btn btn-elico">Save</button>
        <button *ngIf="this.assetService.selectedAssetLocation._id" type="button" (click)="this.assetService.updateAssetLocation()" data-dismiss="modal" [disabled]="!locationForm.form.valid"  class="btn btn-elico">Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="removeLocation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Location</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       <p>
         Are you sure, you want to delete this Locations?
      </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" (click)="this.assetService.deleteAssetLocation()" class="btn btn-danger" data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>


