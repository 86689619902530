<router-outlet>
    <ngx-spinner 
    size = "large"
    color = "#29ffc6"
    
    [fullScreen] = "true"
    >
    <p style="color: white" > Loading... </p></ngx-spinner>
</router-outlet>

