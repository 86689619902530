/**
 * asset-conditions.enum.ts
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const GOOD:string = 'Good';
const BETTER:string = 'Better';
const BAD:string = 'Bad';

const ASSET_CONDITIONS: string[] = [GOOD, BETTER, BAD];

export { ASSET_CONDITIONS, GOOD, BETTER, BAD };

