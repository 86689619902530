import { AssetMaintenanceHistory } from './../models/asset-maintenance-history';
import { FilesService } from './../services/files.service';
import { RestService } from './../services/rest.service';
import { AssetLocation } from './../models/asset-location';
import { UserManagementService } from './../services/user-management.service';
import { AssetsService } from './../services/assets.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AssetClass } from '../models/asset-class';
import { Asset } from '../models/asset';
import { AssetComponent } from '../models/asset-component';
import { Group } from '../models/group';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { getAllDebugNodes } from '@angular/core/src/debug/debug_node';
import { ASSET_CONDITIONS } from '../../config/enums/asset-conditions.enum';


@Component({
  selector: 'app-asset-module',
  templateUrl: './asset-module.component.html',
  styleUrls: ['./asset-module.component.css'],

})
export class AssetModuleComponent implements OnInit {

  constructor(public spinner: NgxSpinnerService, private toastr: ToastrService, public files: FilesService, public assetService: AssetsService, public userManagementService: UserManagementService, public rest: RestService) {
  }

  assetFilter: any = { assetName: '', assetNo: '' };

  classFilter: any = { name: '' };
  assetNumberFilter: any = { assetNo: '' };

  locationFilter: any = { name: '' };

  selectedClassId = "";

  selectedAssetId = "";

  selectedLocationId = "";

  conditions = ASSET_CONDITIONS;

  public address: Object;

  PictureToBeUploaded: File;

  isNewComponent = false;

  isNewMaintanance = false;

  assetStatus = "Active";

  fileReaded: Blob = new Blob();

  imported_assets = new Array<Asset>();

  all_curret_asset_classes = new Array<AssetClass>()

  //asset form flag
  showAssetForm: boolean = false;



  ngOnInit() {
    this.loadInitialData();
  }

  private loadInitialData() {
    this.assetService.getAssetClasses();
    this.assetService.getAssetLocations();
    this.userManagementService.getCompanyUsers();
    this.userManagementService.getGroups();
  }

  csv2Array(fileInput: any) {
    this.fileReaded = fileInput.target.files[0];
    this.readCSVFile(this.fileReaded);
  }

  private readCSVFile(file: Blob) {
    const reader = new FileReader();
    reader.onload = (e) => this.processCSVData(reader.result as string);
    reader.onerror = (e) => this.toastr.error('Error reading file');
    reader.readAsText(file);
  }

  private processCSVData(csv: string) {
    const lines = this.parseCSV(csv);
    if (this.isValidCSVFormat(lines)) {
      this.importAssetsFromCSV(lines);
    } else {
      this.toastr.error("The file doesn't appear to be in a correct format");
    }
  }

  private parseCSV(csv: string): string[][] {
      return csv
          .split(/\r\n|\n/)                    // Split by newlines
          .filter(line => line.trim() !== '')   // Exclude empty or whitespace-only lines
          .map(line => line.split(','));        // Split each line by commas
  }

  private isValidCSVFormat(lines: string[][]): boolean {
    return lines.length > 1 && lines[0].length === 12;
  } 



 
  private async importAssetsFromCSV(lines: string[][]) {
    const validationErrors = this.validateCSVLines(lines);
    if (validationErrors.length > 0) {
        // Handle errors, e.g., log them or throw an exception
        console.error("CSV Validation Errors:", validationErrors);
        this.toastr.error("CSV Validation Errors: " + validationErrors.join('\n'));
        return;
    }
    // If validation passes, proceed with importing
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const asset = this.createAssetFromLine(line);
        await this.assetService.ImportAsset(asset, i);
    }

    this.assetService.getAssets();
  }

  validateCSVLines(lines: string[][]): string[] {
    const errors: string[] = [];
    const expectedColumnCount = 12;

    // Start from 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];

        // Skip lines that are already filtered out
        if (line.length === 0 || line.every(field => field.trim() === '')) {
            continue;
        }

        // Check for the correct number of columns
        if (line.length !== expectedColumnCount) {
            errors.push(`Line ${i + 1}: Incorrect number of columns.`);
            continue;
        }

        // Validate each required field
        if (!this.isValidString(line[0])) { // Name
            errors.push(`Line ${i + 1}: Invalid name.`);
        }
        if (!this.isValidString(line[1])) { // Serial number
            errors.push(`Line ${i + 1}: Invalid serial number.`);
        }
        // Add additional field validations as needed

        // Validate date fields
        if (!this.isValidDate(line[10])) { // Acquisition date
            errors.push(`Line ${i + 1}: Invalid acquisition date. Format must be YYYY-MM-DD.`);
        }
        if (!this.isValidDate(line[11])) { // Date brought in use
            errors.push(`Line ${i + 1}: Invalid date brought in use. Format must be YYYY-MM-DD.`);
        }

        // Check if date brought in use is after acquisition date
        const acquisitionDate = Date.parse(this.getAgularDate(line[10]));
        const broughtInUseDate = Date.parse(this.getAgularDate(line[11]));
        if (broughtInUseDate < acquisitionDate) {
            errors.push(`Line ${i + 1}: Date brought in use is before acquisition date.`);
        }
    }

    return errors;
  }


  // Helper functions to validate different types of fields
  isValidString(value) {
    return typeof value === 'string' && value.trim() !== '';
  }

 isValidDate(dateString) {
    // Regular expression to match the date format 'YYYY-MM-DD'
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(dateString)) {
        return false;
    }

    // Check if it's an actual date
    const timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
}



  createAssetFromLine(line: string[]): Asset {
    const asset = new Asset();
    asset.name = line[0];
    asset.serial_number = line[1];
    asset.assignedTo = this.getAssignedUser(line[4]);
    asset.groupId = this.getGroup(line[3]);
    asset.classId = this.getAssetClass(line[8]);
    asset.condition = this.getCondtion(line[6]);
    asset.location = this.getLocation(line[2]);
    asset.supplier = line[7];
    asset.financialNo = line[5];
    asset.purchasePrice = parseFloat(line[9]);
    asset.aquistionDate = this.getDate(line[10]);
    asset.DateBroughtInUse = this.getDate(line[11]);
    return asset;
  }


  private similarity(s1: string, s2: string): number {
    const longer = s1.length >= s2.length ? s1 : s2;
    const shorter = s1.length < s2.length ? s1 : s2;
    const longerLength = longer.length;
    return longerLength === 0 ? 1.0 : (longerLength - this.editDistance(longer, shorter)) / longerLength;
  }

  private editDistance(s1: string, s2: string): number {
    const costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0)
          costs[j] = j;
        else if (j > 0) {
          const newValue = Math.min(costs[j - 1], lastValue, costs[j]) + (s1.charAt(i - 1) !== s2.charAt(j - 1) ? 1 : 0);
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }


  getDate(dateString): Date {
    return new Date(Date.parse(this.getAgularDate(dateString)));
  }

  getAgularDate(date: string): string {
    let data = date.split("/");
    return data[2] + '-' + data[1] + '-' + data[0];
  }



  getGroup(name: string): string {
    let group_id = ""
    let accurancy_level = 0;

    for (let i = 0; i < this.userManagementService.groups.length; i++) {
      let similarity = this.similarity(this.userManagementService.groups[i].name, name);
      if (similarity > accurancy_level) {
        accurancy_level = similarity;
        group_id = this.userManagementService.groups[i]._id;
      }
    }

    if (group_id == "") {
      console.log("Group not found in the system, defaulting to the first group in the system.", this.userManagementService.groups[0]);
      group_id = this.userManagementService.groups[0]._id;
    }
    return group_id;
  }

  getAssignedUser(name: string) {
    let user_id = "unassigned"
    let accurancy_level = 0;

    if (name == "" || name == "unassigned") {
      return user_id;
    }

    for (let i = 0; i < this.userManagementService.users.length; i++) {
      let similarity = this.similarity(this.userManagementService.users[i].fullname, name);
      if (similarity >= accurancy_level) {
        accurancy_level = similarity;
        user_id = this.userManagementService.users[i]._id;
      }
    }

    return user_id;
  }

  getAssetClass(name: string) {
    let class_id = ""
    let accurancy_level = 0;

    for (let i = 0; i < this.assetService.assetClasses.length; i++) {
      let similarity = this.similarity(this.assetService.assetClasses[i].classPrefix, name);
      if (similarity > accurancy_level) {
        accurancy_level = similarity;
        class_id = this.assetService.assetClasses[i]._id;
      }
    }

    if (class_id == "") {
      class_id = this.assetService.assetClasses[0]._id;
    }

    return class_id;
  }

  getCondtion(name: string) {
    let condition_id = ""
    let accurancy_level = 0;

    for (let i = 0; i < this.conditions.length; i++) {
      let similarity = this.similarity(this.conditions[i], name);
      if (similarity > accurancy_level) {
        accurancy_level = similarity;
        condition_id = this.conditions[i];
      }
    }

    if (condition_id == "") {
      condition_id = this.conditions[0];
    }

    return condition_id;
  }

  getLocation(name: string) {
    let location_id = ""
    let accurancy_level = 0;

    for (let i = 0; i < this.assetService.locations.length; i++) {
      let similarity = this.similarity(this.assetService.locations[i].name, name);
      if (similarity > accurancy_level) {
        accurancy_level = similarity;
        location_id = this.assetService.locations[i]._id;
      }
    }

    if (location_id == "") {
      location_id = this.assetService.locations[0]._id;
    }

    return location_id;

  }


  // ngDoCheck(){

  //   //this.cdr.detectChanges();

  newComponent() {
    this.assetService.selectedAssetComponent = new AssetComponent();
    this.isNewComponent = true;
  }



  cancelComponent() {
    this.assetService.selectedAssetComponent = new AssetComponent();
    this.isNewComponent = false;
  }

  newMaintanance() {
    this.assetService.selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
    this.isNewMaintanance = true;
  }


  cancelMaintanance() {
    this.assetService.selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
    this.isNewMaintanance = false;
  }


  newClass() {
    this.assetService.selectedAssetClass = new AssetClass();
  }

  editAsset(asset) {
    this.assetService.selectedFullAsset = asset;
    this.assetService.getAsset(asset._id)
    this.showAssetForm = true;
  }

  newAsset() {
    this.showAssetForm = true;
    this.assetService.selectedAsset = new Asset();
  }

  newLocation() {
    this.assetService.selectedAssetLocation = new AssetLocation();
  }

  getAssetImage(id): String {
    return this.rest.ENDPOINT + "/stream/img-" + id;
  }

  handleAssetsImageInput(files) {
    this.PictureToBeUploaded = files[0];
    this.uploadAssetPicture();
  }

  uploadAssetPicture() {
    this.files.postFile(this.PictureToBeUploaded, this.selectedAssetId).subscribe(
      res => {
        console.log(res);
        window.location.reload();
      }
    )
  }

  printbar() {
    let popupWinindow
    let innerContents = document.getElementById("pbc").innerHTML;
    popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

  getShortAction(action: string) {
    if (action.length > 17) {
      return action.substring(0, 16) + "...";
    }
    return action;
  }



  onFilterChange(event) {
    this.assetService.getAssets();
  }



  onMaintananceChange(values) {
    console.log(values.currentTarget.checked);
    this.assetService.updateAsset();
    this.assetService.getAssets();
  }

  roundTo2dp(num: number) {

    return (Math.round(num * 100) / 100).toFixed(2);


  }







}
