import { Company } from "./company";
import { Role } from "./role";

export class CompanyUser {
    
    _id:any;

    email : string;

    fullname:string;

    companyId: Company

    role: Role;

    deleted:boolean;

    created: Date;
}
