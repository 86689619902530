/**
 * headers.const.ts
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const DEPARTMENT = 'Department';
const SERIAL_NUMBER = 'Serial number';
const ACTIVE_ASSETS = 'Active Assets';
const UNDER_MAINTENANCE = 'Under Maintenance';
const ASSETS_WRITTEN_OFF = 'Assets Written Off';
const USERS = 'Users';
const LOCATIONS = 'Locations';
const RESPONSIBLE_PERSON = 'Responsible Person';
const DATE_CREATED = 'Date Created';
const DATE_ACQUIRED = 'Date Acquired';
const DATE_BROUGHT_IN_USE = 'Date Brought In use';
const USEFULL_LIFE = 'Useful Life';
const RESIDUAL_VALUE = 'Residual Value';
const COST = 'Cost';
const NET_BOOK_VALUE = 'Net Book Value';
const ACCUMULATED_DEPRECIATION_LIFE_TO_DATE = 'Accumulated Depreciation Life To Date';
const ACCUMULATED_DEPRECIATION_THIS_YEAR = 'Accumulated Depreciation This Year';
const ASSET_NAME = 'Asset Name';
const BARCODE = 'Barcode';
const ASSET_CLASS = 'Asset Class';
const LOCATION = 'Location';
const REASON_FOR_SALE_SCRAPPING = 'Reason For Sale/Scrapping';
const DATE_SOLD_SCRAPPING = 'Date Sold/Scrapping';
const AMOUNT_SOLD_FOR_SALE_SCRAPPING = 'Amount Sold For Sale/Scrapping';
const PROFIT_LOSS = 'Profit/Loss';
const ACCUMULATED_DEPRECIATION_LIFE_TO_SALE_SCRAPPING_DATE = 'Accumulated Depreciation Life To Sale/Scrapping Date';
const NET_BOOK_VALUE_AT_SALE_SCRAPPING = 'Net Book Value at Sale/Scrapping';
const OLD_ASSET_NO = 'Old Asset No';
const NEW_ASSET_NO = 'New Asset No';
const TRANSFER_DATE = 'Transfer Date';
const ACCUMULATED_DEPRECIATION_LIFE_TO_TRANSFER_DATE = 'Accumulated Depreciation Life To Transfer Date';
const NET_BOOK_VALUE_AT_TRANSFER = 'Net Book Value at Transfer';
const ASSET_NO = 'Asset No';
const ASSET_CLASS_DESCRIPTION = 'Asset Class Description';
const MATERIAL = 'Material';
const OWNERSHIP = 'Ownership';

const ACCUMULATED_DEPRECIATION_OPENING = 'Opening Acc Depreciation';
const ACCUMULATED_DEPRECIATION_CURRENT_YEAR = 'Current Year Acc Depreciation';
const ACCUMULATED_DEPRECIATION_CLOSING = 'Closing Acc Depreciation';

const DEFAULT_HEADER: string[] = [ASSET_NO, ASSET_NAME, SERIAL_NUMBER, ASSET_CLASS, ASSET_CLASS_DESCRIPTION, MATERIAL, LOCATION, DEPARTMENT, RESPONSIBLE_PERSON, OWNERSHIP, DATE_CREATED, DATE_ACQUIRED, DATE_BROUGHT_IN_USE, USEFULL_LIFE, RESIDUAL_VALUE, COST, ACCUMULATED_DEPRECIATION_OPENING, ACCUMULATED_DEPRECIATION_CURRENT_YEAR, ACCUMULATED_DEPRECIATION_CLOSING, NET_BOOK_VALUE];
const DEPARTMENT_HEADER: string[] = [DEPARTMENT, SERIAL_NUMBER, ACTIVE_ASSETS, UNDER_MAINTENANCE, ASSETS_WRITTEN_OFF, USERS, LOCATIONS, RESPONSIBLE_PERSON, DATE_CREATED, DATE_ACQUIRED, DATE_BROUGHT_IN_USE, USEFULL_LIFE, RESIDUAL_VALUE, COST, NET_BOOK_VALUE, ACCUMULATED_DEPRECIATION_LIFE_TO_DATE, ACCUMULATED_DEPRECIATION_THIS_YEAR];
const SOLD_ASSETS_HEADER: string[] = [ASSET_NAME, BARCODE, SERIAL_NUMBER, ASSET_CLASS, LOCATION, REASON_FOR_SALE_SCRAPPING, DATE_SOLD_SCRAPPING, COST, AMOUNT_SOLD_FOR_SALE_SCRAPPING, PROFIT_LOSS, ACCUMULATED_DEPRECIATION_LIFE_TO_SALE_SCRAPPING_DATE, NET_BOOK_VALUE_AT_SALE_SCRAPPING];
const TRANSFERRED_ASSETS_HEADER: string[] = [ASSET_NAME, SERIAL_NUMBER, ASSET_CLASS, LOCATION, OLD_ASSET_NO, NEW_ASSET_NO, TRANSFER_DATE, COST, ACCUMULATED_DEPRECIATION_LIFE_TO_TRANSFER_DATE, NET_BOOK_VALUE_AT_TRANSFER];

export {
    DEPARTMENT,
    SERIAL_NUMBER,
    ACTIVE_ASSETS,
    UNDER_MAINTENANCE,
    ASSETS_WRITTEN_OFF,
    USERS,
    LOCATIONS,
    RESPONSIBLE_PERSON,
    DATE_CREATED,
    DATE_ACQUIRED,
    DATE_BROUGHT_IN_USE,
    USEFULL_LIFE,
    RESIDUAL_VALUE,
    COST,
    NET_BOOK_VALUE,
    ACCUMULATED_DEPRECIATION_LIFE_TO_DATE,
    ACCUMULATED_DEPRECIATION_THIS_YEAR,
    ASSET_NAME,
    BARCODE,
    ASSET_CLASS,
    LOCATION,
    REASON_FOR_SALE_SCRAPPING,
    DATE_SOLD_SCRAPPING,
    AMOUNT_SOLD_FOR_SALE_SCRAPPING,
    PROFIT_LOSS,
    ACCUMULATED_DEPRECIATION_LIFE_TO_SALE_SCRAPPING_DATE,
    NET_BOOK_VALUE_AT_SALE_SCRAPPING,
    OLD_ASSET_NO,
    NEW_ASSET_NO,
    TRANSFER_DATE,
    ACCUMULATED_DEPRECIATION_LIFE_TO_TRANSFER_DATE,
    NET_BOOK_VALUE_AT_TRANSFER,
    ASSET_NO,
    ASSET_CLASS_DESCRIPTION,
    DEFAULT_HEADER,
    DEPARTMENT_HEADER,
    SOLD_ASSETS_HEADER,
    TRANSFERRED_ASSETS_HEADER
};