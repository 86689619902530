import { CompanyUser } from '../models/company-user';
import { Company } from './../models/company';
import { UserManagementService } from './../services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit {



  constructor(public userManagementService:UserManagementService) { }

  company = new Company();

  year = new Date().getFullYear()

  ngOnInit() {
    this.userManagementService.loadCompanies();
    this.userManagementService.getRoles();
  }

  select(company:CompanyUser){
    this.userManagementService.selectCompany(company);
  }

}
