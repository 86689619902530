export class User {

    _id:any;

    

    email: string;

    password:  string;

    deleted:boolean;

    imageUrl:string;

    created:Date;

    modified: Date;
    

}
