import { Asset } from './asset';

export class AssetLog {

    _id:any;

    assetId : string;

    userId : string;

    action: string;

    state: Asset;

    effectLevel: string;

    created: Date;
    
    deleted:boolean;

}
