export class GroupMember {
    _id:any;

    email : String;

    groupId:  String;

    deleted:boolean;

    created:Date;
}
