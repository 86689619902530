import * as tslib_1 from "tslib";
import { AssetMaintenanceHistory } from './../models/asset-maintenance-history';
import { FilesService } from './../services/files.service';
import { RestService } from './../services/rest.service';
import { AssetLocation } from './../models/asset-location';
import { UserManagementService } from './../services/user-management.service';
import { AssetsService } from './../services/assets.service';
import { OnInit } from '@angular/core';
import { AssetClass } from '../models/asset-class';
import { Asset } from '../models/asset';
import { AssetComponent } from '../models/asset-component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ASSET_CONDITIONS } from '../../config/enums/asset-conditions.enum';
var AssetModuleComponent = /** @class */ (function () {
    function AssetModuleComponent(spinner, toastr, files, assetService, userManagementService, rest) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.files = files;
        this.assetService = assetService;
        this.userManagementService = userManagementService;
        this.rest = rest;
        this.assetFilter = { assetName: '', assetNo: '' };
        this.classFilter = { name: '' };
        this.assetNumberFilter = { assetNo: '' };
        this.locationFilter = { name: '' };
        this.selectedClassId = "";
        this.selectedAssetId = "";
        this.selectedLocationId = "";
        this.conditions = ASSET_CONDITIONS;
        this.isNewComponent = false;
        this.isNewMaintanance = false;
        this.assetStatus = "Active";
        this.fileReaded = new Blob();
        this.imported_assets = new Array();
        this.all_curret_asset_classes = new Array();
        //asset form flag
        this.showAssetForm = false;
    }
    AssetModuleComponent.prototype.ngOnInit = function () {
        this.loadInitialData();
    };
    AssetModuleComponent.prototype.loadInitialData = function () {
        this.assetService.getAssetClasses();
        this.assetService.getAssetLocations();
        this.userManagementService.getCompanyUsers();
        this.userManagementService.getGroups();
    };
    AssetModuleComponent.prototype.csv2Array = function (fileInput) {
        this.fileReaded = fileInput.target.files[0];
        this.readCSVFile(this.fileReaded);
    };
    AssetModuleComponent.prototype.readCSVFile = function (file) {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function (e) { return _this.processCSVData(reader.result); };
        reader.onerror = function (e) { return _this.toastr.error('Error reading file'); };
        reader.readAsText(file);
    };
    AssetModuleComponent.prototype.processCSVData = function (csv) {
        var lines = this.parseCSV(csv);
        if (this.isValidCSVFormat(lines)) {
            this.importAssetsFromCSV(lines);
        }
        else {
            this.toastr.error("The file doesn't appear to be in a correct format");
        }
    };
    AssetModuleComponent.prototype.parseCSV = function (csv) {
        return csv
            .split(/\r\n|\n/) // Split by newlines
            .filter(function (line) { return line.trim() !== ''; }) // Exclude empty or whitespace-only lines
            .map(function (line) { return line.split(','); }); // Split each line by commas
    };
    AssetModuleComponent.prototype.isValidCSVFormat = function (lines) {
        return lines.length > 1 && lines[0].length === 12;
    };
    AssetModuleComponent.prototype.importAssetsFromCSV = function (lines) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var validationErrors, i, line, asset;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        validationErrors = this.validateCSVLines(lines);
                        if (validationErrors.length > 0) {
                            // Handle errors, e.g., log them or throw an exception
                            console.error("CSV Validation Errors:", validationErrors);
                            this.toastr.error("CSV Validation Errors: " + validationErrors.join('\n'));
                            return [2 /*return*/];
                        }
                        i = 1;
                        _a.label = 1;
                    case 1:
                        if (!(i < lines.length)) return [3 /*break*/, 4];
                        line = lines[i];
                        asset = this.createAssetFromLine(line);
                        return [4 /*yield*/, this.assetService.ImportAsset(asset, i)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.assetService.getAssets();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssetModuleComponent.prototype.validateCSVLines = function (lines) {
        var errors = [];
        var expectedColumnCount = 12;
        // Start from 1 to skip header
        for (var i = 1; i < lines.length; i++) {
            var line = lines[i];
            // Skip lines that are already filtered out
            if (line.length === 0 || line.every(function (field) { return field.trim() === ''; })) {
                continue;
            }
            // Check for the correct number of columns
            if (line.length !== expectedColumnCount) {
                errors.push("Line " + (i + 1) + ": Incorrect number of columns.");
                continue;
            }
            // Validate each required field
            if (!this.isValidString(line[0])) { // Name
                errors.push("Line " + (i + 1) + ": Invalid name.");
            }
            if (!this.isValidString(line[1])) { // Serial number
                errors.push("Line " + (i + 1) + ": Invalid serial number.");
            }
            // Add additional field validations as needed
            // Validate date fields
            if (!this.isValidDate(line[10])) { // Acquisition date
                errors.push("Line " + (i + 1) + ": Invalid acquisition date. Format must be YYYY-MM-DD.");
            }
            if (!this.isValidDate(line[11])) { // Date brought in use
                errors.push("Line " + (i + 1) + ": Invalid date brought in use. Format must be YYYY-MM-DD.");
            }
            // Check if date brought in use is after acquisition date
            var acquisitionDate = Date.parse(this.getAgularDate(line[10]));
            var broughtInUseDate = Date.parse(this.getAgularDate(line[11]));
            if (broughtInUseDate < acquisitionDate) {
                errors.push("Line " + (i + 1) + ": Date brought in use is before acquisition date.");
            }
        }
        return errors;
    };
    // Helper functions to validate different types of fields
    AssetModuleComponent.prototype.isValidString = function (value) {
        return typeof value === 'string' && value.trim() !== '';
    };
    AssetModuleComponent.prototype.isValidDate = function (dateString) {
        // Regular expression to match the date format 'YYYY-MM-DD'
        var regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(dateString)) {
            return false;
        }
        // Check if it's an actual date
        var timestamp = Date.parse(dateString);
        return !isNaN(timestamp);
    };
    AssetModuleComponent.prototype.createAssetFromLine = function (line) {
        var asset = new Asset();
        asset.name = line[0];
        asset.serial_number = line[1];
        asset.assignedTo = this.getAssignedUser(line[4]);
        asset.groupId = this.getGroup(line[3]);
        asset.classId = this.getAssetClass(line[8]);
        asset.condition = this.getCondtion(line[6]);
        asset.location = this.getLocation(line[2]);
        asset.supplier = line[7];
        asset.financialNo = line[5];
        asset.purchasePrice = parseFloat(line[9]);
        asset.aquistionDate = this.getDate(line[10]);
        asset.DateBroughtInUse = this.getDate(line[11]);
        return asset;
    };
    AssetModuleComponent.prototype.similarity = function (s1, s2) {
        var longer = s1.length >= s2.length ? s1 : s2;
        var shorter = s1.length < s2.length ? s1 : s2;
        var longerLength = longer.length;
        return longerLength === 0 ? 1.0 : (longerLength - this.editDistance(longer, shorter)) / longerLength;
    };
    AssetModuleComponent.prototype.editDistance = function (s1, s2) {
        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i === 0)
                    costs[j] = j;
                else if (j > 0) {
                    var newValue = Math.min(costs[j - 1], lastValue, costs[j]) + (s1.charAt(i - 1) !== s2.charAt(j - 1) ? 1 : 0);
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    };
    AssetModuleComponent.prototype.getDate = function (dateString) {
        return new Date(Date.parse(this.getAgularDate(dateString)));
    };
    AssetModuleComponent.prototype.getAgularDate = function (date) {
        var data = date.split("/");
        return data[2] + '-' + data[1] + '-' + data[0];
    };
    AssetModuleComponent.prototype.getGroup = function (name) {
        var group_id = "";
        var accurancy_level = 0;
        for (var i = 0; i < this.userManagementService.groups.length; i++) {
            var similarity = this.similarity(this.userManagementService.groups[i].name, name);
            if (similarity > accurancy_level) {
                accurancy_level = similarity;
                group_id = this.userManagementService.groups[i]._id;
            }
        }
        if (group_id == "") {
            console.log("Group not found in the system, defaulting to the first group in the system.", this.userManagementService.groups[0]);
            group_id = this.userManagementService.groups[0]._id;
        }
        return group_id;
    };
    AssetModuleComponent.prototype.getAssignedUser = function (name) {
        var user_id = "unassigned";
        var accurancy_level = 0;
        if (name == "" || name == "unassigned") {
            return user_id;
        }
        for (var i = 0; i < this.userManagementService.users.length; i++) {
            var similarity = this.similarity(this.userManagementService.users[i].fullname, name);
            if (similarity >= accurancy_level) {
                accurancy_level = similarity;
                user_id = this.userManagementService.users[i]._id;
            }
        }
        return user_id;
    };
    AssetModuleComponent.prototype.getAssetClass = function (name) {
        var class_id = "";
        var accurancy_level = 0;
        for (var i = 0; i < this.assetService.assetClasses.length; i++) {
            var similarity = this.similarity(this.assetService.assetClasses[i].classPrefix, name);
            if (similarity > accurancy_level) {
                accurancy_level = similarity;
                class_id = this.assetService.assetClasses[i]._id;
            }
        }
        if (class_id == "") {
            class_id = this.assetService.assetClasses[0]._id;
        }
        return class_id;
    };
    AssetModuleComponent.prototype.getCondtion = function (name) {
        var condition_id = "";
        var accurancy_level = 0;
        for (var i = 0; i < this.conditions.length; i++) {
            var similarity = this.similarity(this.conditions[i], name);
            if (similarity > accurancy_level) {
                accurancy_level = similarity;
                condition_id = this.conditions[i];
            }
        }
        if (condition_id == "") {
            condition_id = this.conditions[0];
        }
        return condition_id;
    };
    AssetModuleComponent.prototype.getLocation = function (name) {
        var location_id = "";
        var accurancy_level = 0;
        for (var i = 0; i < this.assetService.locations.length; i++) {
            var similarity = this.similarity(this.assetService.locations[i].name, name);
            if (similarity > accurancy_level) {
                accurancy_level = similarity;
                location_id = this.assetService.locations[i]._id;
            }
        }
        if (location_id == "") {
            location_id = this.assetService.locations[0]._id;
        }
        return location_id;
    };
    // ngDoCheck(){
    //   //this.cdr.detectChanges();
    AssetModuleComponent.prototype.newComponent = function () {
        this.assetService.selectedAssetComponent = new AssetComponent();
        this.isNewComponent = true;
    };
    AssetModuleComponent.prototype.cancelComponent = function () {
        this.assetService.selectedAssetComponent = new AssetComponent();
        this.isNewComponent = false;
    };
    AssetModuleComponent.prototype.newMaintanance = function () {
        this.assetService.selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
        this.isNewMaintanance = true;
    };
    AssetModuleComponent.prototype.cancelMaintanance = function () {
        this.assetService.selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
        this.isNewMaintanance = false;
    };
    AssetModuleComponent.prototype.newClass = function () {
        this.assetService.selectedAssetClass = new AssetClass();
    };
    AssetModuleComponent.prototype.editAsset = function (asset) {
        this.assetService.selectedFullAsset = asset;
        this.assetService.getAsset(asset._id);
        this.showAssetForm = true;
    };
    AssetModuleComponent.prototype.newAsset = function () {
        this.showAssetForm = true;
        this.assetService.selectedAsset = new Asset();
    };
    AssetModuleComponent.prototype.newLocation = function () {
        this.assetService.selectedAssetLocation = new AssetLocation();
    };
    AssetModuleComponent.prototype.getAssetImage = function (id) {
        return this.rest.ENDPOINT + "/stream/img-" + id;
    };
    AssetModuleComponent.prototype.handleAssetsImageInput = function (files) {
        this.PictureToBeUploaded = files[0];
        this.uploadAssetPicture();
    };
    AssetModuleComponent.prototype.uploadAssetPicture = function () {
        this.files.postFile(this.PictureToBeUploaded, this.selectedAssetId).subscribe(function (res) {
            console.log(res);
            window.location.reload();
        });
    };
    AssetModuleComponent.prototype.printbar = function () {
        var popupWinindow;
        var innerContents = document.getElementById("pbc").innerHTML;
        popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
        popupWinindow.document.open();
        popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
        popupWinindow.document.close();
    };
    AssetModuleComponent.prototype.getShortAction = function (action) {
        if (action.length > 17) {
            return action.substring(0, 16) + "...";
        }
        return action;
    };
    AssetModuleComponent.prototype.onFilterChange = function (event) {
        this.assetService.getAssets();
    };
    AssetModuleComponent.prototype.onMaintananceChange = function (values) {
        console.log(values.currentTarget.checked);
        this.assetService.updateAsset();
        this.assetService.getAssets();
    };
    AssetModuleComponent.prototype.roundTo2dp = function (num) {
        return (Math.round(num * 100) / 100).toFixed(2);
    };
    return AssetModuleComponent;
}());
export { AssetModuleComponent };
