<div class="modal fade" *ngIf="display" id="addAsset" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog addasset-modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">*New Asset</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #assetForm="ngForm">
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Asset Name *</label>
                <input type="text" [(ngModel)]="this.assetService.selectedAsset.name" name="_name"
                  placeholder="Asset Name" class="form-control">
              </div>
              <div class="col-md-4">
                <label>Assigned To *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.assignedTo" name="assignedTo"
                  required>
                  <option disabled value="">Assign Asset</option>
                  <option *ngFor="let user of this.userManagementService.users" [value]="user._id">
                    {{this.userManagementService.getNameFromEmail(user.email)}}</option>
                  <option value="unassigned">Unassigned</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Group *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.groupId" name="groupId"
                  required>
                  <option disabled value="">Select Group</option>
                  <option *ngFor="let group of this.userManagementService.groups" [value]="group._id">{{group.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Asset Class *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.classId" name="classId"
                  required>
                  <option disabled value="">Select Asset Class</option>
                  <option *ngFor="let clas of this.assetService.assetClasses" [value]="clas._id">{{clas.name}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Asset Condition *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.condition"
                  name="assetcondition" required>
                  <option disabled value="">Select Group</option>
                  <option *ngFor="let condition of this.conditions" [value]="condition">{{condition}}</option>
                </select>
              </div>

              <div class="col-md-4">
                <label>Asset Location *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.location" name="location"
                  required>
                  <option disabled value="">Select Location</option>
                  <option *ngFor="let location of this.assetService.locations" [value]="location._id">{{location.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Asset Supplier *</label>
                <input type="text" autocomplete="on" [(ngModel)]="this.assetService.selectedAsset.supplier"
                  name="supplier" placeholder="Asset Supplier" class="form-control" required>
              </div>

              <div class="col-md-4">
                <label>Purchase Price *</label>
                <input type="number" [(ngModel)]="this.assetService.selectedAsset.purchasePrice" name="purchasePrice"
                  placeholder="(N$) Purchase Price" class="form-control" required>
              </div>
              <div class="col-md-4">
                <label>Ownership *</label>
                <select class="form-control" [(ngModel)]="this.assetService.selectedAsset.ownership" name="_ownership"
                  required>
                  <option disabled value="">Select Asset Ownership</option>
                  <option *ngFor="let owner_s of ownerships" [value]="owner_s">{{owner_s}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Financial Reference No *</label>
                <input type="text" [(ngModel)]="this.assetService.selectedAsset.financialNo" name="financialNo"
                  placeholder="Financial Reference No" class="form-control">
              </div>

              <div class="col-md-4">
                <label>Aquistion Date</label>
                <input type="date" [(ngModel)]="this.assetService.selectedAsset.aquistionDate" name="aquistionDate"
                  placeholder="Aquistion Date" class="form-control" required>
              </div>

              <div class="col-md-4">
                <label>Date Brought In Use</label>
                <input type="date" [(ngModel)]="this.assetService.selectedAsset.DateBroughtInUse"
                  name="dateBroughtInUse" placeholder="Date Brought In Use" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label>Serial Number</label>
                <input type="text" [(ngModel)]="this.assetService.selectedAsset.serial_number" name="serialNumber"
                  placeholder="Serial Number" class="form-control">
              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" [hidden]="assetService.selectedAsset._id" (click)="this.assetService.addAsset()"
          data-dismiss="modal" class="btn btn-elico">Save</button>
        <button type="button" [hidden]="!assetService.selectedAsset._id" (click)="this.assetService.updateAsset()"
          data-dismiss="modal" class="btn btn-elico">Update</button>
      </div>
    </div>
  </div>
</div>