
import { CompanyUser } from './../models/company-user';
import { UserSetting } from './../models/user-setting';
import { Company } from './../models/company';
import { RestService } from './rest.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';





@Injectable()
export class AuthService { 
  
  currentCompany = new Company();

  currentUser = new User();

  public company_link:BehaviorSubject<string> = new BehaviorSubject("../../../assets/img/com-default.png");

  constructor(private spinner:NgxSpinnerService, private toastr:ToastrService, public jwtHelper: JwtHelperService,private http:HttpClient,private rest:RestService,private router:Router) { }


  login(email,password){ 

    this.spinner.show();
     
     this.http.post(this.rest.ENDPOINT+"/login", { email:email, password:password }, this.rest.httpOtions).subscribe(
       res=>{
         if(res["success"]){
           this.toastr.success(res['message']);
           this.saveAuth(res['user'],res['token']);
           this.goToRout('/organisation');
         }else{
            
          this.toastr.error(res['message']);
          console.log(res);
         }
         this.spinner.hide();
       },error=>{
        this.toastr.error(this.rest.ERROR_MESSAGE);
        this.spinner.hide();
       }
     );

  }

  signup(email,password){
    this.spinner.show();
    this.http.post(this.rest.ENDPOINT+"/signup",{email:email,password:password},this.rest.httpOtions).subscribe(
      res=>{
         if(res['success']){
            /**
             * save user and token on the local storage
             */
            this.saveAuth(res['user'],res['token']);
            this.goToRout('/organisation');
         }else{
           console.log(res);
         }
         this.spinner.hide();
      }
     
    )
  }

  /**
   * this will be used by both login & signup
   */

  signin(setting:UserSetting,email){

       this.spinner.show();

      if(setting.lastOrganisation){

          this.saveCompany(setting.lastOrganisation,setting)
          this.firstLogin(setting.lastPage);
          
      }else{
        this.http.get<Array<CompanyUser>>(this.rest.ENDPOINT+'/companyusers/?email='+email,this.rest.httpOtions).subscribe(
          companies=>{
            if(companies.length == 0 ||companies.length>1){
              this.goToRout('/organisation');
            }else{ 
              
              this.saveCompany(companies[0].companyId._id,setting)
              
              this.firstLogin(setting.lastPage || '/dashboard');
             
              
            }
            this.spinner.hide();

          }
        )
      }
  }

  firstLogin(page){
    //let flag = 0;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([page])); 
  }

  /**
   * 
   * @param companyId compnay id to be saved on the local storage
   * @param setting Current user's settings
   */
  saveCompany(companyId:String,setting:UserSetting){

      
      this.spinner.show();

      this.http.get<Company>(this.rest.ENDPOINT+'/companies/'+companyId,this.rest.httpOtions).subscribe(
        company=>{

          this.currentCompany = company;
          
          localStorage.setItem('company',JSON.stringify(company));

         
          /**
           * Update user setting's last organisation
           */
          setting.lastOrganisation = companyId;

           this.http.put<UserSetting>(this.rest.ENDPOINT+"/usersettings/"+setting._id,setting,this.rest.httpOtions).subscribe(
             settings=>{
               console.log(settings);
               this.spinner.hide();
             }
           )
        }
      )
  }

  saveAuth(user,token){
    this.currentUser = user;
    localStorage.setItem('user',JSON.stringify(user));
    localStorage.setItem('token',JSON.stringify(token));
  }
 

  logout(){
    
      
      this.goToRout('/login');

      
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  goToRout(link:String){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([link])); 
  }

  



}
