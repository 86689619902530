/**
 * index.ts
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

//formate date to DD/MM/YYYY

const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export { formatDate };