export class Company {
    _id:any;

    name : String = "";

    email:  String = "";

    tellphone:  String = "";

    deleted: boolean;

    assetCount:number;

    imageUrl: String = "";

    created: Date;

    modified: Date;
    
    fmonth :Number;

    fdate: Number;
    

    fys:String;
}
