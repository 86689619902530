<div class="main-navbar sticky-top elico-nav">
  <!-- Main Navbar -->
  <nav class="navbar align-items-stretch  navbar-light  flex-md-nowrap p-0 ">
    <!-- <form action="#" class="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
      <div class="input-group input-group-seamless ml-3">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <input class="navbar-search form-control" type="text" placeholder="Search for something..." aria-label="Search"> </div>
    </form> -->
    

    <ul class="navbar-nav border-left flex-row ml-auto vertical-center" >
      <li data-toggle="modal" data-target="#company"  (click)="this.userManagementService.getCompany()" class="nav-item ">
          <img class="company-avater mr-2" [src]="auth.company_link.value" onError="this.src='../../../assets/img/com-default.png'" >
          
      </li> 
    </ul >
    <ul class="navbar-nav time flex-row mr-auto " >
      <li data-toggle="modal" class="nav-item ">
        {{now | date:'medium'}}
      </li> 
    </ul >

    <ul class="navbar-nav border-left flex-row ml-auto">
      
        
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-nowrap px-3" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
          <img width="40" height="40" class="user-avatar rounded-circle mr-2" [src]="this.userManagementService.link.value" onError="this.src='../../../assets/img/defaultuser.png'" >
          <span class="d-none d-md-inline-block">{{this.userManagementService.fullname.value}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-small">
          <a data-toggle="modal" data-target="#cp"  class="dropdown-item" href="javascript:;">
            <i  class="material-icons">&#xE7FD;</i> Profile</a>
           <div class="dropdown-divider"></div>
           <a class="dropdown-item" href="#/organisation"><i class="material-icons">&#xE7FD;</i> Switch Organizations</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" (click)="this.auth.logout()" href="javascript:;">
            <i class="material-icons text-danger">&#xE879;</i>Logout</a>
        </div>
      </li>
    </ul>
    <nav class="nav">
      <a href="#/login" class="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left" data-toggle="collapse" data-target=".header-navbar" aria-expanded="false" aria-controls="header-navbar">
        <i class="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  </nav>
</div>


<div class="modal fade" id="company" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{this.userManagementService.currentCompany.name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span> 
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-md-5"> 
                <p align="center">
                  <img width="100%"   class="user-avatar rounded mr-2" [src]="getImage(this.auth.currentCompany._id)" onError="this.src='../../../assets/img/defaultuser.png'" >
                 </p> 
                 <p align="center">
                  <label class="btn btn-elico" (click)="image_id=this.auth.currentCompany._id;" for="comp_pic"><i class="material-icons"> add_photo_alternate</i> Change Picture </label>  
                  <input  type="file" hidden placeholder="" accept="image/*" id="comp_pic"   name="__sampleFile" (change)="handleFileInput($event.target.files)">
                 </p> 
              </div>
          
          <div class="col-md-7"> 
              <form #companyForm="ngForm">
                <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                        <label>Name</label>
                        <input type="text" name="com_name" [(ngModel)]="this.auth.currentCompany.name"  class="form-control" required>
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                        <label>E-Mail</label>
                        <input type="text" name="com_email" [(ngModel)]="this.auth.currentCompany.email" class="form-control" required>
                      </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                        <label>Telphone</label>
                        <input type="text" name="com_tel" [(ngModel)]="this.auth.currentCompany.tellphone" class="form-control" required>
                      </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" [disabled]="!companyForm.form.valid" (click)="this.userManagementService.updateCompany()" class="btn btn-elico" data-dismiss="modal">Update</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{this.userManagementService.getNameFromEmail(this.userManagementService.getUser().email)}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span> 
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5"> 
            <p align="center">
              <img width="100%"   class="user-avatar rounded mr-2" [src]="getImage(this.userManagementService.getUser()._id)" onError="this.src='../../../assets/img/defaultuser.png'" >
             </p> 
             <p align="center">
                <label class="btn btn-elico" (click)="image_id=this.userManagementService.getUser()._id" for="profile"><i class="material-icons"> add_photo_alternate</i> Change Picture </label>  
              <input  type="file" hidden placeholder="" accept="image/*" id="profile"  name="sampleFile" (change)="handleFileInput($event.target.files)">
             </p> 
          </div>
          <div class="col-md-7"> 
              <form #changePasswordForm="ngForm">
                <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                        <label>Old Password</label>
                        <input type="password" name="old_password" [(ngModel)]="oldPassword"  class="form-control" required>
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                        <label>New Password</label>
                        <input type="password" name="new_password" [(ngModel)]="newPassword" class="form-control" required>
                      </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="password" name="con_password" [(ngModel)]="confirmPassword" class="form-control" required>
                      </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" [disabled]="!changePasswordForm.form.valid" (click)="changePassword()" class="btn btn-elico" data-dismiss="modal">Update</button>
      </div>
    </div>
  </div>
</div>
