<div class="container-fluid">
  <div class="row">

    <!-- Main Sidebar -->
    <app-sidemenu></app-sidemenu>
    <!-- End Main Sidebar -->

    <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

      <app-topmenu></app-topmenu>

      <!-- / .main-navbar   DASH BOARD CONTENT HERE-->
      <div class="main-content-container container-fluid px-4">
        <!-- Page Header -->
        <div class="page-header row no-gutters py-4">
          <div class="col-12 col-sm-12 text-center text-sm-left mb-0">
            <h3 align="center" class="page-title">Settings</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 people-container">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link text-uppercase active" id="home-tab" data-toggle="tab" href="#general" role="tab"
                  aria-controls="general" aria-selected="true">General</a>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <!-- Users -->
              <br>

              <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                <div class="card col-md-6" style="margin: auto;">
                  <div class="row">
                    <div class="col-md-5">
                      <p align="center">
                        <img width="100%" class="user-avatar rounded mr-2"
                          [src]="getImage(this.auth.currentCompany._id)"
                          onError="this.src='../../../assets/img/defaultuser.png'">
                      </p>
                      <p align="center">
                        <label class="btn btn-elico" (click)="image_id=this.auth.currentCompany._id;" for="org_pic"><i
                            class="material-icons"> add_photo_alternate</i> Change Picture </label>
                        <input type="file" hidden placeholder="" accept="image/*" id="org_pic" name="__sampleFile"
                          (change)="handleFileInput($event.target.files)">
                      </p>
                    </div>

                    <div class="col-md-7">
                      <br>
                      <form #companyForm="ngForm">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Business Name</label>
                              <input type="text" name="com_name" [(ngModel)]="userManagementService.currentCompany.name"
                                class="form-control" required>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>E-Mail</label>
                              <input type="text" name="com_email"
                                [(ngModel)]="userManagementService.currentCompany.email" class="form-control" required>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Telphone</label>
                              <input type="text" name="com_tel"
                                [(ngModel)]="userManagementService.currentCompany.tellphone" class="form-control"
                                required>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Financial Year Start</label>
                              <select class="form-control" aria-placeholder="Role" name="fdate"
                                [(ngModel)]="userManagementService.currentCompany.fys">
                                <option value="01-01">January 1st</option>
                                <option value="02-01">February 1st</option>
                                <option value="03-01">March 1st</option>
                                <option value="04-01">April 1st</option>
                                <option value="05-01">May 1st</option>
                                <option value="06-01">June 1st</option>
                                <option value="07-01">July 1st</option>
                                <option value="08-01">August 1st</option>
                                <option value="09-01">September 1st</option>
                                <option value="10-01">October 1st</option>
                                <option value="11-01">November 1st</option>
                                <option value="12-01">December 1st</option>
                              </select>
                            </div>
                          </div>

                          <button type="button" [disabled]="!companyForm.form.valid"
                            (click)="userManagementService.updateCompany()" class="btn btn-elico"
                            data-dismiss="modal">Update</button>
                        </div> <!-- Missing closing div for row -->
                      </form>
                      <br>
                    </div>
                  </div> <!-- Closing div for row -->
                </div> <!-- Closing div for card -->
              </div> <!-- Closing div for tab-pane -->
            </div> <!-- Closing div for tab-content -->
          </div> <!-- Closing div for people-container -->
        </div> <!-- Closing div for row -->
      </div> <!-- Closing div for main-content-container -->
      <app-footer></app-footer>
    </main> <!-- Closing div for main -->
  </div> <!-- Closing div for row -->
</div> <!-- Closing div for container-fluid -->
