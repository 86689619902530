import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Http, Headers} from '@angular/http';
import { RequestOptions } from '@angular/http/src/base_request_options';
import { RestService } from './rest.service';

@Injectable()
export class FilesService {

 

  constructor(private http: Http,private rest:RestService) { }

  postFile(fileToUpload:File,id){

    this.deleteFile("img-"+id);

    const endpoint = this.rest.ENDPOINT+"/upload";

    const formData:FormData = new FormData();
      
      formData.append('_id',id);
      formData.append('size',''+fileToUpload.size)
      formData.append('sampleFile',fileToUpload,fileToUpload.name);
      
   

    let headers = new Headers();
    
    headers.append('Accept', 'application/json');
    
    return this.http.post(endpoint, formData,{headers})
        
       
  }

  deleteFile(filename){
    this.http.delete(this.rest.ENDPOINT+'/files/'+filename).subscribe(
      res=>{
        console.log(res);
      }
    )
  }

}
