export class AssetClass {
    
    _id : any;

    name : string;

    classPrefix: string;

    companyId: string;

    classCount: number;

    createdBy:  string;

    usefulLife:  number;

    startOfDepriciation: string ="Date Brought In Use";

    material:  string;

    residualValue :  number;

    depreciationRate:  number;

    isDefault: boolean;

    deleted:boolean;

    description:string; 

    created:Date;

    modified: Date;
}
