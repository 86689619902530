<div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h3 class="display-6">Pick A Plan That Is Best For Your Business</h3>
   
  </div>

  <div class="container">
    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Silver</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">N$500 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>100 users</li>
            <li>500 Items</li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-elico">10 days Trail</button>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Gold</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">N$950 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
              <li>350 users</li>
              <li>1000 Items</li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-elico">10 days Trail</button>
        </div>
      </div>
      <div class="card mb-4 shadow-sm">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Platinum</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">N$1300 <small class="text-muted">/ mo</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
              <li>Unlimited users</li>
              <li>Unlimited Items</li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-elico">10 days Trail</button>
        </div>
      </div>
    </div>
