export class Asset {
    _id:any;

    name : string;

    barcode: string;

    companyId:  string;

    assignedTo:  string;

    createdBy:  string;

    groupId:  string;

    location:  string;

    financialNo:  string = "None";

    condition:  string;

    supplier:  string;

    classId:  string;

    ownership:string;

    serial_number:string;

    purchasePrice: number;

    deleted:boolean;

    isDisposed:boolean;

    underMaintenance:boolean;

    disposalDate:Date;

    aquistionDate:any;

    DateBroughtInUse:any;
   
    created:Date;

    modified: Date;

    imageUrl:String;
}
