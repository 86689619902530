import { Report } from './../models/report';
import { Group } from './../models/group';
import { AssetsService } from './assets.service';
import { Asset } from './../models/asset';
import { UserManagementService } from './user-management.service';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Fullasset } from '../models/fullasset';
import { AssetLog } from '../models/asset-log';
import { ItemSold } from '../report-models/item-sold';
import { DisposedAssets } from '../models/disposed-assets';
import { TransfferedAsset } from '../report-models/transffered-asset';
import { 
  DEFAULT_HEADER,
  DEPARTMENT_HEADER, 
  SOLD_ASSETS_HEADER, 
  TRANSFERRED_ASSETS_HEADER 
} from '../../config/constants/headers.const';
import { OWNED } from '../../config/enums/asset-ownership.enum';

@Injectable()
export class ReportingService {

  constructor( private spinner:NgxSpinnerService, private toastr:ToastrService, private auth:AuthService, private userManagementService:UserManagementService, private rest:RestService, private http:HttpClient, private assetService:AssetsService, private userManagement:UserManagementService) { }
    /**
     * DASHBAORD STATS,  
     */
    activeassets= 0;
    writtenoffassets= 0;
    assetsundermaintenance=0;
    assetlocations=0;
    accumulatedDepLifeToDate =0;
    accumulatedDepThisYear = 0;
    accumulatedDepRage = 0;
    total_opening=0;

    /**
     * Assets per deparment and work in progress
     */
     workInGrogress =  new Array<Asset>();
     assetsPerDepartment = [];
     selected_header:string[];

     /**
      * Reports
      */
      reports = new Array<Report>();
      selectedReport = new Report();

      /**
       * HEADERS
       */
      sold_assets_hearder = SOLD_ASSETS_HEADER;
      department_header = DEPARTMENT_HEADER;
      transffered_assets_hearder = TRANSFERRED_ASSETS_HEADER;
      default_hearder = DEFAULT_HEADER;

      /**
       * REPORT DATA
       */
      assets_sold = new Array<ItemSold>();
      assets_transffered = new Array<TransfferedAsset>();
      currentAssetsReport = new Array<Fullasset>();

      //ASSET SOLD TOTALS
      is_cost=0;
      is_amount=0;
      is_profit=0;
      is_total_depreciation=0;
      is_total__nbv=0;

      //ASSETS TRANSFERRED TOTALS
      tr_cost=0;
      tr_total_depreciation=0;
      tr_total__nbv=0;

      selected_class = 'btn_open_json';
      
      total_cost = 0;
      total_nbv = 0;

      /**
       * Reports Parameters
       */
      select_date_by:string ="life_to_a_date";

      ownership = OWNED;

      date = new Date();

      start_date = new Date(this.date.getFullYear(), this.date.getMonth(), 2).toISOString().split('T')[0];
      end_date = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString().split('T')[0];

      data_format:string="JSON";

      d_start_date = new Date(this.date.getFullYear(), this.date.getMonth(), 2);
      d_end_date = new Date();
      report_name = "";

      last_financial_year = new Date();
      


   
    getReports(){
      this.spinner.show();
      this.http.get<Array<Report>>(this.rest.ENDPOINT+"/reports").subscribe(
        reports=>{
            this.reports = reports;
            this.spinner.hide();
        }
      )
    }

    getDateFormate(date:string): string{
        let date_array :string[] = date.split("/");

        return date_array[2]+"-"+date_array[1]+"-"+date_array[0];
    }

    populateReportData(name){
       
      this.last_financial_year = null;
      
      this.last_financial_year = new Date(this.getDateFormate(this.end_date))

      this.last_financial_year.setMonth(11);
      
      this.last_financial_year.setDate(31);

      if(new Date(this.getDateFormate(this.end_date))<=this.last_financial_year){
        this.last_financial_year.setFullYear(this.last_financial_year.getFullYear()-1)
      }

     

      this.selected_class = 'btn_open_json';

      this.default_hearder = ["Asset No" ,"Asset Name","Serial number","Asset Class", "Asset Class Description","Material","Location","Department","Responsible Person","Ownership","Date Created","Date Acquired", "Date Brought In use", "Usefull Life","Residual Value", "Cost", "Net Book Value","Accumulated Depreciation Life To Date","Accumulated Depreciation This Year"];
      
      
      this.report_name = name;
      let parameters:String ="&ownership="+this.ownership;

      let query = {};

      query['companyId'] = this.userManagementService.getCompany()._id;
      query['deleted'] = false;
      query['ownership'] = this.ownership;
      query['pull_by'] = this.select_date_by;
      

       if(this.select_date_by=="life_to_a_date"){

         //parameters =parameters+ "&DateBroughtInUse__lte="+this.getDateFormate(this.end_date);
         query['end_date'] = this.end_date;

       }else{

         //parameters = parameters+"&"+this.select_date_by+"__gte="+this.getDateFormate(this.start_date)+"&"+this.select_date_by+"__lte="+this.getDateFormate(this.end_date);
         query['start_date'] = this.start_date;
         query['end_date'] = this.end_date;
      }

      switch (name) {
        case "Active Assets":
            
            
             this.spinner.show();
              this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                  assets=>{
                    this.currentAssetsReport= assets;
                    this.computeTotals();
                    this.open_report();
                    this.spinner.hide();
                  },error=>{
                    this.toastr.error(this.rest.ERROR_MESSAGE)
                    this.spinner.hide();
                  }
              )
          break;

        case "Assets Under Maintenance":
            query['underMaintenance']=true;
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                  this.currentAssetsReport = assets;
                  this.computeTotals();
                  this.open_report();
                  this.spinner.hide();
                },error=>{

                  this.toastr.warning(error.error.message)
                  console.log(error);
                  this.spinner.hide();
                }
            )
          break;

          case "Unassigned Assets":
            query['assignedTo']='unassigned';
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                  this.currentAssetsReport = assets;
                  this.computeTotals();
                  this.open_report();
                  this.spinner.hide();
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          break;

          case "Assets Sold":
            query['isDisposed']=true;
            this.selected_class = 'btn_sold_items';
            this.assets_sold = new Array<ItemSold>();
            this.default_hearder = this.sold_assets_hearder;
            this.spinner.show();
            this.http.post<Array<ItemSold>>(this.rest.ENDPOINT+"/soldassets",query).subscribe(
                assets=>{
                  this.spinner.hide();
                  this.open_report();
                  this.assets_sold = assets;
                  this.computeSoldAssetsTotals();
                },error=>{
                  this.spinner.hide();
                  console.log(error);
                  this.toastr.warning(error.error.message)
                }
            )
          break;

        case "Assets Per Location":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                  this.currentAssetsReport = new Array<Fullasset>();
                  
                  //sort assets by asset.location

                  assets.sort((a,b)=>(a.location > b.location) ? 1 : ((b.location > a.location) ? -1 : 0));

                  this.currentAssetsReport = assets;

                  this.computeTotals();
                  this.open_report();
                  this.spinner.hide();
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          break;

          case "Transferred Assets":
            this.default_hearder = this.transffered_assets_hearder;
            this.selected_class = "btn_trans_items"
            this.assets_transffered = new Array<TransfferedAsset>();
            this.spinner.show();

            this.http.post<Array<TransfferedAsset>>(this.rest.ENDPOINT+"/transferredassets",query).subscribe(
                assets=>{
                  this.assets_transffered = assets;
                  this.open_report();
                  this.computeTransTotals();
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          break;

        case "Assets Per Department":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                  this.currentAssetsReport = new Array<Fullasset>();
                  for (let i = 0; i < this.userManagementService.groups.length; i++) {
                    
                        for (let j = 0; j < assets.length; j++) {
                          if(assets[j].department ==this.userManagementService.groups[i].name){
                              this.currentAssetsReport.push(assets[j]);
                          }
                        } 
                        
                  }
                  this.computeTotals();
                  this.open_report();
                  this.spinner.hide(); 

                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          
          break;

        case "Assets Per User":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                 
                    this.currentAssetsReport = new Array<Fullasset>();

                    //sort assets by asset.assignedTo

                    assets.sort((a,b)=>(a.person > b.person) ? 1 : ((b.person > a.person) ? -1 : 0));
                    
                    this.computeTotals();
                    this.open_report();
                    this.spinner.hide();
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
               
            )
          break;

        case "Fully Depreciated Assets":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullydepreciatedassets",query).subscribe(
                assets=>{
                    this.currentAssetsReport = assets;
        
                   this.computeTotals();
                   this.open_report();
                   this.spinner.hide();
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          break;

        case "Work In Progress":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
              assets=>{
                this.currentAssetsReport = new Array<Fullasset>();
                   for (let i = 0; i < assets.length; i++) {
                      if(!assets[i].broughtInUse){
                        this.currentAssetsReport.push(assets[i]);
                      }
                   }
                   this.computeTotals();
                   this.open_report();
                   this.spinner.hide();
              },error=>{
                this.toastr.error(this.rest.ERROR_MESSAGE)
                this.spinner.hide();
              }
            )
          break;
        case "Assets Per Class":
            this.spinner.show();
            this.http.post<Array<Fullasset>>(this.rest.ENDPOINT+"/fullassets",query).subscribe(
                assets=>{
                  this.currentAssetsReport = new Array<Fullasset>();
                  //sort assets by asset.className

                  assets.sort((a,b)=>(a.assetClass > b.assetClass) ? 1 : ((b.assetClass > a.assetClass) ? -1 : 0));

                  this.currentAssetsReport = assets;

                  this.computeTotals();
                  this.open_report();
                  this.spinner.hide();
                 
                },error=>{
                  this.toastr.error(this.rest.ERROR_MESSAGE)
                  this.spinner.hide();
                }
            )
          break;
      
        default:
          break;
      }
     
    }

    getLifeToADate(asset:Asset){

        if(this.select_date_by=="life_to_a_date"){

           let closing = this.assetService.getAccumulatedAssetDepreciationBtnDates(asset,asset.DateBroughtInUse,new Date(this.getDateFormate(this.end_date)));
  
          return closing*-1;

        }
        
        return this.assetService.getAccumulatedAssetDepreciationLifeToDate(asset)*-1;

    }

    getThisYear(asset:Asset){
      return this.assetService.getAccumulatedAssetDepreciationBtnDates(asset,this.last_financial_year,new Date(this.getDateFormate(this.end_date)))*-1;
    }

    getOpenning(asset:Fullasset){
        
        let opening = asset.adld-asset.adty;

        return opening;

    }

    getNetBookValue(asset:Asset){
      if(this.select_date_by=="life_to_a_date"){
        return this.assetService.getAssetValueAtDate(asset,new Date(this.getDateFormate(this.end_date))) ;
      }else{
         return this.assetService.getAssetCurrentValue(asset);
      }
    }

    openModel(){
      document.getElementById(this.selected_class).click();
    }

    open_report(){
      this.spinner.show();
      switch (this.data_format) {
        case "JSON":

          this.openModel();
          this.spinner.hide();
          break;
          
        case "Web":

            this.openModel();
            this.spinner.hide();
            break;

        case "Excel":

          let from = this.start_date;

          if(this.select_date_by=="life_to_a_date"){
            from = "Inception";
          }
      
          var options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true, 
            showTitle: true,
            title: this.userManagementService.getCompany().name+"-" +this.report_name+" From: "+from+" To: "+this.end_date,
            useBom: true,
            noDownload: false,
            headers: DEFAULT_HEADER,
            nullToEmptyString: true,
          };

          let csv_list:Fullasset[] = this.assetService.getFullAssetArray(this.currentAssetsReport);

          for (let j = 0; j < csv_list.length; j++) {
             csv_list[j].netBookValue = this.currentAssetsReport[j].netBookValue;
             csv_list[j].adld = this.currentAssetsReport[j].adld;
             csv_list[j].adty = this.currentAssetsReport[j].adty;
          }

          if(this.report_name=="Assets Sold"){
            new Angular5Csv(this.assets_sold,'Elico Asset Management Report',options);
            this.spinner.hide();
          }else{
            new Angular5Csv(csv_list,'Elico Asset Management Report',options);
            this.spinner.hide();
          }

          break;

      
        default:
          break;
      }
    }


    initialiseDashboardCounts(){
      this.spinner.show();
      this.http.get(this.rest.ENDPOINT+'/activeassetscount/'+this.userManagementService.getCompany()._id).subscribe(
        res=>{
          this.activeassets = res['count'];
          this.spinner.hide();
        },error=>{
          this.toastr.error(this.rest.ERROR_MESSAGE)
          this.spinner.hide();
        }
      )
      this.http.get(this.rest.ENDPOINT+'/assetswrittenoffcount/'+this.userManagementService.getCompany()._id).subscribe(
        res=>{
          this.writtenoffassets = res['count'];
          this.spinner.hide();
        },error=>{
          this.toastr.error(this.rest.ERROR_MESSAGE)
          this.spinner.hide();
        }
      )
      this.http.get(this.rest.ENDPOINT+'/assetsundermaintenancecount/'+this.userManagementService.getCompany()._id).subscribe(
        res=>{
          this.assetsundermaintenance = res['count'];
          this.spinner.hide();
        },error=>{
          this.toastr.error(this.rest.ERROR_MESSAGE)
          this.spinner.hide();
        }
      )
      this.http.get(this.rest.ENDPOINT+'/locationscount/'+this.userManagementService.getCompany()._id).subscribe(
        res=>{
          this.assetlocations = res['count'];
          this.spinner.hide();
        },error=>{
          this.toastr.error(this.rest.ERROR_MESSAGE)
          this.spinner.hide();
        }
      )
    }

    getWorkInProgress(){
      for (let i = 0; i < this.assetService.assets.length; i++) {
        
        if(!this.assetService.assets[i].DateBroughtInUse){
          this.workInGrogress.push(this.assetService.assets[i]);
        }
        
      }
    }

    getAssetsPerDepartment(){

      console.log("Function executed!");

      for (let i = 0; i < this.userManagementService.groups.length; i++) {
          let companyAssetObject = {
            department: this.userManagementService.groups[i].name,
            assets : 0,
            cost:0
          }

          let count = 0;
          let cost = 0;

          for (let j = 0; j < this.assetService.assets.length; j++) {
              if(this.assetService.assets[j].groupId==this.userManagementService.groups[i]._id){
                count++
                cost = cost+this.assetService.assets[j].purchasePrice;
              }
          }

          companyAssetObject.assets = count;
          companyAssetObject.cost = cost; 

          this.assetsPerDepartment.push(companyAssetObject);

      }
    }

    initialiseDashboardStats(){
      this.spinner.show();
       this.workInGrogress = [];
       this.assetsPerDepartment = new Array<Asset>();
       this.http.get<Array<Asset>>(this.rest.ENDPOINT+"/assets/?companyId="+this.userManagementService.getCompany()._id+"&isDisposed=false").subscribe(
         assets=>{
           this.assetService.assets= assets; 
          
           this.http.get<Array<Group>>(this.rest.ENDPOINT+"/groups/?companyId="+this.userManagementService.getCompany()._id).subscribe(
            groups=>{

              this.userManagementService.groups= groups;
              this.getAssetsPerDepartment();
              this.getWorkInProgress();
              this.spinner.hide();

            },error=>{
              this.toastr.error(this.rest.ERROR_MESSAGE)
              this.spinner.hide();
            }
          )

         },error=>{
          this.toastr.error(this.rest.ERROR_MESSAGE)
          this.spinner.hide();
        }
       )
    }


    roundTo2dp(num:Number){
      let x = num.toFixed(2);
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
          x = x.replace(pattern, "$1,$2");
      return x;
      
    }

    computeTotals(){
      this.total_cost=0;
      this.total_nbv =0;

      this.accumulatedDepLifeToDate=0;

      this.accumulatedDepThisYear=0;

      this.accumulatedDepRage = 0;

      this.total_opening = 0;

      for (let i = 0; i < this.currentAssetsReport.length; i++) {
       
        this.total_cost = this.total_cost + this.currentAssetsReport[i].cost;

        this.total_opening = this.total_opening + (this.currentAssetsReport[i].adld-this.currentAssetsReport[i].adty);

        let nbv = this.currentAssetsReport[i].netBookValue;

        this.total_nbv = this.total_nbv + nbv;
       
        this.accumulatedDepThisYear =  this.accumulatedDepThisYear+ this.currentAssetsReport[i].adty;

        this.accumulatedDepLifeToDate = this.accumulatedDepLifeToDate+ this.currentAssetsReport[i].adld;

       ///this.accumulatedDepRage = this.accumulatedDepRage+this.assetService.getAccumulatedAssetDepreciationBtnDates(this.currentAssetsReport[i],this.d_start_date,this.d_end_date);
        
      }
    }

    computeSoldAssetsTotals(){
      this.is_cost=0;
      this.is_amount=0;
      this.is_profit =0;
      this.is_total_depreciation=0;
      this.is_total__nbv=0;

      for (let i = 0; i < this.assets_sold.length; i++) {
        this.is_cost= this.is_cost+this.assets_sold[i].cost;
        this.is_amount = this.is_amount+this.assets_sold[i].amount_sold_for;
        this.is_profit = this.is_profit+this.assets_sold[i].profit_or_loss;
        this.is_total_depreciation = this.is_total_depreciation+this.assets_sold[i].accumulated_depreciation_life_to_sale_date;
        this.is_total__nbv = this.is_total__nbv+this.assets_sold[i].net_book_value_at_sale;
      }
    }

    computeTransTotals(){
      this.tr_cost=0;
     
      this.tr_total_depreciation=0;
      this.tr_total__nbv=0;

      for (let i = 0; i < this.assets_transffered.length; i++) {
        this.tr_cost= this.tr_cost+this.assets_transffered[i].cost;
        this.tr_total_depreciation = this.tr_total_depreciation+this.assets_transffered[i].accumulated_depreciation_life_to_transfere_date;
        this.tr_total__nbv = this.tr_total__nbv+this.assets_transffered[i].net_book_value_at_trans;
      }
    }





}
