import { FilesService } from './../services/files.service';
import { AuthService } from './../services/auth.service';
import { RestService } from './../services/rest.service';
import { UserManagementService } from './../services/user-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.css']
})
export class TopmenuComponent implements OnInit {
  public now: Date = new Date();
  constructor(public files:FilesService, public userManagementService:UserManagementService,public rest:RestService,public auth:AuthService) { 
    setInterval(() => {
      this.now = new Date();
    }, 1);

  }


   oldPassword ="";
   newPassword ="";
   confirmPassword="";

   userPictureToBeUploaded:File;

   image_id ="";

  ngOnInit() {
    this.userManagementService.loadCompanies();
    if(!this.auth.currentCompany._id){
      this.auth.currentCompany = this.userManagementService.getCompany();
    }
    this.auth.company_link.next(this.getImage(this.auth.currentCompany._id))
    this.userManagementService.link.next(this.getImage(JSON.parse(localStorage.getItem('user'))['_id']))
  }

  getImage(id:string):string{
    let link = "6767tfhfyu";
    link= this.rest.ENDPOINT+"/stream/img-"+id;
    return link;
  }  
  
  handleFileInput(files) {
    this.userPictureToBeUploaded = files[0];
    this.uploadPicture(this.image_id);
  }

  uploadPicture(id){
    this.files.postFile(this.userPictureToBeUploaded,id).subscribe(
      res=>{
        console.log(res);
        window.location.reload(); 
      }, err=>{
        console.log(err);
      }
    )
  }

  changePassword(){
    if(this.newPassword==this.confirmPassword){
        this.userManagementService.updatePassword(this.oldPassword,this.newPassword);
    }else{
      alert("Passwords are not matching!");
    }
  }

}
