/**
 * asset-ownership.enum.ts
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const OWNED = 'Owned';
const LEASED = 'Leased';

const ASSET_OWNERSHIP: string[] = [OWNED, LEASED];

export { ASSET_OWNERSHIP, OWNED, LEASED };