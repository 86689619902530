import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(public auth:AuthService, public userService:UserManagementService) { }

  email:String;

  year = new Date().getFullYear();
  
  newPassword:String;
  confirmPassword:String;

  ngOnInit() {
      this.userService.testRole();
  }

  signup(){
      if(this.newPassword==this.confirmPassword){
          this.auth.signup(this.email,this.newPassword);
      }else{
        console.log("Passwords are not matching.")
      }
  }

}
