import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FilesService } from '../services/files.service';
import { RestService } from '../services/rest.service';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  constructor(public files:FilesService, public userManagementService:UserManagementService,public rest:RestService,public auth:AuthService) { }

  userPictureToBeUploaded:File;

  image_id ="";
  
  ngOnInit() {
    this.userManagementService.loadCompanies();
    this.userManagementService.currentCompany = this.userManagementService.getCompany();
    if(!this.auth.currentCompany._id){
      this.auth.currentCompany = this.userManagementService.getCompany();
      
    }
    this.auth.company_link.next(this.getImage(this.auth.currentCompany._id))
  }

  getImage(id):string{
    let link = "6767tfhfyu";
    link= this.rest.ENDPOINT+"/stream/img-"+id;
    return link;
  } 

  handleFileInput(files) {
    this.userPictureToBeUploaded = files[0];
    this.uploadPicture(this.image_id);
  }

  uploadPicture(id){
    this.files.postFile(this.userPictureToBeUploaded,id).subscribe(
      res=>{
        console.log(res);
        window.location.reload(); 
      }
    )
  }

}
