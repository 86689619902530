import * as tslib_1 from "tslib";
import { AssetComponent } from './../models/asset-component';
import { AssetMaintenanceHistory } from './../models/asset-maintenance-history';
import { AssetLocation } from './../models/asset-location';
import { DisposedAssets } from './../models/disposed-assets';
import { AssetLog } from './../models/asset-log';
import { UserManagementService } from './user-management.service';
import { AssetClass } from './../models/asset-class';
import { Asset } from './../models/asset';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Fullasset } from '../models/fullasset';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { NgxSpinnerService } from 'ngx-spinner';
import { ASSETS } from '../../config/constants/end-points.const';
import { formatDate } from '../util';
var AssetsService = /** @class */ (function () {
    function AssetsService(spinner, toastr, http, rest, userManagementService) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.http = http;
        this.rest = rest;
        this.userManagementService = userManagementService;
        /**
         * Assets
         */
        this.selectedAsset = new Asset();
        this.assets = new Array();
        /**
         * Full Assets (Elico cloud 22-04-2022)
         */
        this.selectedFullAsset = new Fullasset();
        this.fullAssets = new Array();
        /**
         * Assets Class
         */
        this.selectedAssetClass = new AssetClass();
        this.assetClasses = new Array();
        /**
         * Asset Logs
         */
        this.selectedLog = new AssetLog();
        this.assetLogs = new Array();
        /**
        * Disposed Assets
        */
        this.selectedDisposedAsset = new DisposedAssets();
        this.disposedAssests = new Array();
        /**
         * Locations
         */
        this.selectedAssetLocation = new AssetLocation;
        this.locations = new Array();
        /**
         * Assets Maintance History,
         */
        this.selectedAssetMaintenceHistory = new AssetMaintenanceHistory();
        this.assetMaintenanceHistories = Array();
        /**
         * Asset components
         */
        this.selectedAssetComponent = new AssetComponent();
        this.assetComponents = new Array();
        this.status_filter = 'Active';
        this.class_filter = 'All';
        this.user_filter = 'All';
        this.group_filter = "All";
    }
    AssetsService.prototype.addAsset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var asset, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.spinner.show();
                        this.selectedAsset.companyId = this.userManagementService.getCompany()._id;
                        this.selectedAsset.createdBy = this.userManagementService.getUser()._id;
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + ASSETS, tslib_1.__assign({}, this.selectedAsset, { userId: this.userManagementService.getUser()._id })).toPromise()];
                    case 1:
                        asset = _a.sent();
                        console.log(asset);
                        //this.addAssetLog(asset._id, "Asset asset created", HIGH, asset);
                        this.getAssets();
                        this.toastr.success("Asset " + asset.name + " successfully added!");
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 4];
                    case 3:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.ImportAsset = function (asset, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.spinner.show();
                        asset.companyId = this.userManagementService.getCompany()._id;
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/assets", tslib_1.__assign({}, asset, { userId: this.userManagementService.getUser()._id })).toPromise()];
                    case 1:
                        response = _a.sent();
                        console.log("Asset " + asset.name + " successfully imported!");
                        return [3 /*break*/, 4];
                    case 2:
                        error_2 = _a.sent();
                        console.error("Error importing asset: ", error_2);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 4];
                    case 3:
                        //this.getAssets();
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getAssets = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullassets, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/fullassets", this.get_query()).toPromise()];
                    case 2:
                        fullassets = _a.sent();
                        this.fullAssets = fullassets;
                        console.log(fullassets);
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        console.error("Error fetching assets: ", error_3);
                        this.toastr.info("No assets found");
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getAsset = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var asset, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/assets/" + id).toPromise()];
                    case 2:
                        asset = _a.sent();
                        this.selectedAsset = asset;
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        console.error("Error fetching asset: ", error_4);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.get_query = function () {
        var query = {};
        query['companyId'] = this.userManagementService.getCompany()._id;
        query['deleted'] = false;
        this.status_filter != 'Disposed' ? query['isDisposed'] = false : query['isDisposed'] = true;
        this.status_filter != 'Under Maintanance' ? query['underMaintenance'] = false : query['underMaintenance'] = true;
        this.class_filter != "All" ? query['classId'] = this.class_filter : query = query;
        this.user_filter != "All" ? query['assignedTo'] = this.user_filter : query = query;
        this.group_filter != "All" ? query['groupId'] = this.group_filter : query = query;
        return query;
    };
    AssetsService.prototype.updateAsset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assets/" + this.selectedAsset._id, tslib_1.__assign({}, this.selectedAsset, { userId: this.userManagementService.getUser()._id })).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssets()];
                    case 3:
                        _a.sent();
                        this.toastr.success("Asset " + this.selectedAsset.name + " successfully updated!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_5 = _a.sent();
                        console.error("Error updating asset: ", error_5);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.deleteAsset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAsset.deleted = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assets/" + this.selectedAsset._id, this.selectedAsset).toPromise()];
                    case 2:
                        res = _a.sent();
                        return [4 /*yield*/, this.getAssets()];
                    case 3:
                        _a.sent();
                        this.toastr.success("Asset " + this.selectedAsset.name + " successfully deleted!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_6 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getAssetClassById = function (classId) {
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                return this.assetClasses[i].name;
            }
        }
        return "";
    };
    AssetsService.prototype.getAssetMaterialById = function (classId) {
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                return this.assetClasses[i].material;
            }
        }
        return "";
    };
    AssetsService.prototype.getAssetClassDescriptionById = function (classId) {
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                if (!this.assetClasses[i].description) {
                    return "";
                }
                return this.assetClasses[i].description;
            }
        }
        return "";
    };
    AssetsService.prototype.getAssetCurrentValue = function (asset) {
        this.spinner.show();
        var now = new Date();
        var dateBroughtInUse = new Date(asset.DateBroughtInUse);
        var daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);
        var monthsLeftToLifeEnd = this.getUseFulLife(asset.classId) - daysAssetAsBeenInUse;
        var residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);
        var value = (((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsLeftToLifeEnd) || asset.purchasePrice;
        this.spinner.hide();
        if (value < 0) {
            return residualValue;
        }
        return value + residualValue;
    };
    AssetsService.prototype.getAssetValueAtDate = function (asset, date) {
        this.spinner.show();
        var now = new Date(date);
        var dateBroughtInUse = new Date(asset.DateBroughtInUse);
        var daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);
        var monthsLeftToLifeEnd = this.getUseFulLife(asset.classId) - daysAssetAsBeenInUse;
        var residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);
        var value = ((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsLeftToLifeEnd;
        this.spinner.hide();
        if (value < 0) {
            return 0 + residualValue;
        }
        return value + residualValue;
    };
    AssetsService.prototype.getResidualvalue = function (classId, purchasePrice) {
        var residualPercentage = 0;
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                residualPercentage = this.assetClasses[i].residualValue;
            }
        }
        if (residualPercentage <= 0) {
            return 0;
        }
        return (residualPercentage / 100) * purchasePrice;
    };
    AssetsService.prototype.getUseFulLife = function (classId) {
        var useFulLife = 0;
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                useFulLife = this.assetClasses[i].usefulLife;
            }
        }
        return useFulLife * 365;
    };
    AssetsService.prototype.getDaysDiff = function (d1, d2) {
        var Difference_In_Time = d2.getTime() - d1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if (Difference_In_Days < 0) {
            return 0;
        }
        return Difference_In_Days;
    };
    AssetsService.prototype.getUseFulLifeInYears = function (classId) {
        var useFulLife = 0;
        for (var i = 0; i < this.assetClasses.length; i++) {
            if (this.assetClasses[i]._id == classId) {
                useFulLife = this.assetClasses[i].usefulLife;
            }
        }
        return useFulLife;
    };
    AssetsService.prototype.getAccumulatedAssetDepreciationLifeToDate = function (asset) {
        var now = new Date();
        var dateBroughtInUse = new Date(asset.DateBroughtInUse);
        var usefullLife_in_days = this.getUseFulLife(asset.classId);
        var daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, now);
        var depreciation_days = daysAssetAsBeenInUse > usefullLife_in_days ? usefullLife_in_days : daysAssetAsBeenInUse;
        var residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);
        var value = (((asset.purchasePrice - residualValue /**depreciatable amount*/) / this.getUseFulLife(asset.classId)) /**In months */ * depreciation_days) || 0;
        return value;
    };
    AssetsService.prototype.getAccumulatedAssetDepreciationThisYear = function (asset) {
        var now = new Date();
        var firstDayOfYear = new Date(now.getFullYear(), 0, 1);
        var monthsAssetAsBeenInUseThisYear = 0;
        if (new Date(asset.DateBroughtInUse) < firstDayOfYear) {
            monthsAssetAsBeenInUseThisYear = this.getDaysDiff(firstDayOfYear, now);
        }
        else {
            monthsAssetAsBeenInUseThisYear = this.getDaysDiff(new Date(asset.DateBroughtInUse), now);
        }
        var residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);
        var value = ((asset.purchasePrice - residualValue) / this.getUseFulLife(asset.classId)) * monthsAssetAsBeenInUseThisYear;
        var depreciatable_amount = asset.purchasePrice - residualValue;
        if (this.getAccumulatedAssetDepreciationLifeToDate(asset) + residualValue == asset.purchasePrice) {
            return 0;
        }
        return value;
    };
    AssetsService.prototype.getAccumulatedAssetDepreciationBtnDates = function (asset, startDate, endDate) {
        var daysAssetAsBeenInUse = 0;
        //console.log("start: "+startDate+" End: "+endDate);
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        var dateBroughtInUse = new Date(asset.DateBroughtInUse);
        var assetEndLifeDate = this.getLastDateOfDepreciation(new Date(asset.DateBroughtInUse), this.getUseFulLife(asset.classId));
        //console.log("start: "+startDate+" End: "+endDate+" Brought In Use: "+dateBroughtInUse+ " Asset end life Date: "+assetEndLifeDate+" Usefull life Days: "+this.getUseFulLife(asset.classId));
        if (startDate <= dateBroughtInUse && endDate >= assetEndLifeDate) {
            //console.log("within asset life");
            daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, assetEndLifeDate);
        }
        else if (startDate >= dateBroughtInUse && endDate <= assetEndLifeDate) {
            // console.log("within asset life");
            daysAssetAsBeenInUse = this.getDaysDiff(startDate, endDate);
        }
        else if (startDate <= dateBroughtInUse && endDate > dateBroughtInUse && endDate < assetEndLifeDate) {
            // console.log("btn asset start life");
            daysAssetAsBeenInUse = this.getDaysDiff(dateBroughtInUse, endDate);
        }
        else if (startDate >= dateBroughtInUse && startDate < assetEndLifeDate && endDate > assetEndLifeDate) {
            //console.log("btn asset end");
            daysAssetAsBeenInUse = this.getDaysDiff(startDate, assetEndLifeDate);
        }
        else if ((startDate <= dateBroughtInUse && endDate <= dateBroughtInUse) || (startDate >= assetEndLifeDate && endDate >= assetEndLifeDate)) {
            //console.log("Outside asset life");
            daysAssetAsBeenInUse = 0;
        }
        //console.log(asset.name);
        // console.log("ASSETS HAS BEEN IN USE: "+daysAssetAsBeenInUse);
        var residualValue = this.getResidualvalue(asset.classId, asset.purchasePrice);
        var depreciatable_amount = asset.purchasePrice - residualValue;
        // console.log("residualValue: "+residualValue);
        // console.log("depr amount: "+depreciatable_amount);
        var value = ((depreciatable_amount / this.getUseFulLife(asset.classId)) * daysAssetAsBeenInUse);
        return value;
    };
    AssetsService.prototype.getLastDateOfDepreciation = function (broughtInUse, lifeTimeInDays) {
        broughtInUse.setDate(broughtInUse.getDate() + lifeTimeInDays);
        return broughtInUse;
    };
    /**
     * Asset Class
     */
    AssetsService.prototype.addAssetClass = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetClass.companyId = this.userManagementService.getCompany()._id;
                        this.selectedAssetClass.createdBy = this.userManagementService.getUser()._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/assetclasses", this.selectedAssetClass).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetClasses()];
                    case 3:
                        _a.sent();
                        this.toastr.success("Asset class " + this.selectedAssetClass.name + " successfully added!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_7 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create Default Asset class
     */
    AssetsService.prototype.getAssetClasses = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var classes, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/assetclasses?companyId=" + this.userManagementService.getCompany()._id + "&&deleted=false").toPromise()];
                    case 2:
                        classes = _a.sent();
                        this.assetClasses = classes;
                        return [4 /*yield*/, this.getAssets()];
                    case 3:
                        _a.sent(); // Make sure this is an async function or returns a Promise
                        return [3 /*break*/, 6];
                    case 4:
                        error_8 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.updateAssetClass = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assetclasses/" + this.selectedAssetClass._id, this.selectedAssetClass).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetClasses()];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        this.toastr.success("Asset class successfully updated!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_9 = _a.sent();
                        console.error("Error updating asset class: ", error_9);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.deleteAssetClass = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetClass.deleted = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assetclasses/" + this.selectedAssetClass._id, this.selectedAssetClass).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetClasses()];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        this.toastr.success("Asset class " + this.selectedAssetClass.name + " successfully deleted!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_10 = _a.sent();
                        console.error("Error deleting asset class: ", error_10);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Asset Locations
     */
    AssetsService.prototype.addAssetLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetLocation.companyId = this.userManagementService.getCompany()._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/locations", this.selectedAssetLocation).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetLocations()];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        this.toastr.success("Location " + this.selectedAssetLocation.name + " successfully added!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_11 = _a.sent();
                        console.error("Error adding location: ", error_11);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getAssetLocations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var locations, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/locations?companyId=" + this.userManagementService.getCompany()._id).toPromise()];
                    case 2:
                        locations = _a.sent();
                        this.locations = locations;
                        return [3 /*break*/, 5];
                    case 3:
                        error_12 = _a.sent();
                        console.error("Error fetching asset locations: ", error_12);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.updateAssetLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/locations/" + this.selectedAssetLocation._id, this.selectedAssetLocation).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetLocations()];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        this.toastr.success("Location successfully updated!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_13 = _a.sent();
                        console.error("Error updating location: ", error_13);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.deleteAssetLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetLocation.deleted = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/locations/" + this.selectedAssetLocation._id, this.selectedAssetLocation).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetLocations()];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        this.toastr.success("Location " + this.selectedAssetLocation.name + " successfully deleted!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_14 = _a.sent();
                        console.error("Error deleting location: ", error_14);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    //TODO: THIS MUST BE DONE IN THE BACKEND
    AssetsService.prototype.getLocationNameById = function (locationId) {
        for (var i = 0; i < this.locations.length; i++) {
            if (this.locations[i]._id == locationId) {
                return this.locations[i].name;
            }
        }
    };
    /**
     * Asset Logs
     */
    AssetsService.prototype.getAssetLogs = function (assetId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var logs, error_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + '/assetlogs?assetId=' + assetId).toPromise()];
                    case 2:
                        logs = _a.sent();
                        this.assetLogs = logs;
                        return [3 /*break*/, 5];
                    case 3:
                        error_15 = _a.sent();
                        console.error("Error fetching asset logs: ", error_15);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Assets Maintenance history
     */
    AssetsService.prototype.addAssetMaintenanceHistory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetMaintenceHistory.assetId = this.selectedAsset._id;
                        this.selectedAssetMaintenceHistory.createdBy = this.userManagementService.getUserIdByEmail(this.userManagementService.getUser().email);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/amhs", this.selectedAssetMaintenceHistory).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getMaintenanceHistories(this.selectedAsset._id)];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        return [3 /*break*/, 6];
                    case 4:
                        error_16 = _a.sent();
                        console.error("Error adding asset maintenance history: ", error_16);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getMaintenanceHistories = function (assetId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var amhs, error_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/amhs/?assetId=" + assetId + "&&deleted=false").toPromise()];
                    case 1:
                        amhs = _a.sent();
                        this.assetMaintenanceHistories = amhs;
                        return [3 /*break*/, 3];
                    case 2:
                        error_17 = _a.sent();
                        console.error("Error fetching maintenance histories: ", error_17);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.updateMaintenanceHistory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + '/amhs/' + this.selectedAssetMaintenceHistory._id, this.selectedAssetMaintenceHistory).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getMaintenanceHistories(this.selectedAsset._id)];
                    case 3:
                        _a.sent(); // Assuming this is an async function
                        return [3 /*break*/, 6];
                    case 4:
                        error_18 = _a.sent();
                        console.error("Error updating maintenance history: ", error_18);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.deleteMaintenanceHistory = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetMaintenceHistory.deleted = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/amhs/" + this.selectedAssetMaintenceHistory._id, this.selectedAssetMaintenceHistory).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getMaintenanceHistories(this.selectedAssetMaintenceHistory._id)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_19 = _a.sent();
                        console.error("Error deleting maintenance history: ", error_19);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Asset disposal / write off
     */
    AssetsService.prototype.addDisposedAsset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_20;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.selectedDisposedAsset.assetId = this.selectedAsset._id;
                        this.selectedDisposedAsset.profitMade = this.selectedDisposedAsset.auctionPrice - this.getAssetCurrentValue(this.selectedAsset);
                        this.selectedDisposedAsset.currentValue = this.getAssetCurrentValue(this.selectedAsset);
                        this.selectedDisposedAsset.disposedBy = this.userManagementService.getUserIdByEmail(this.userManagementService.getUser().email);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + '/disposedassets', this.selectedDisposedAsset).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        this.toastr.success("Asset successfully disposed!");
                        return [3 /*break*/, 5];
                    case 3:
                        error_20 = _a.sent();
                        console.error("Error disposing asset: ", error_20);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getDisposedAssets = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var disposedassets, error_21;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + '/disposedassets/?assetId=' + this.selectedAsset._id).toPromise()];
                    case 2:
                        disposedassets = _a.sent();
                        this.disposedAssests = disposedassets;
                        return [3 /*break*/, 5];
                    case 3:
                        error_21 = _a.sent();
                        console.error("Error fetching disposed assets: ", error_21);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Asset components
     */
    AssetsService.prototype.addAssetComponent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_22;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        this.selectedAssetComponent.assetId = this.selectedAsset._id;
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/assetcomponents", this.selectedAssetComponent).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getAssetComponent()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_22 = _a.sent();
                        console.error("Error adding asset component: ", error_22);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.getAssetComponent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_23;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/assetcomponents/?assetId=" + this.selectedAsset._id + "&&deleted=false").toPromise()];
                    case 2:
                        res = _a.sent();
                        this.assetComponents = res;
                        return [3 /*break*/, 5];
                    case 3:
                        error_23 = _a.sent();
                        console.error("Error fetching asset components: ", error_23);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.updateAssetComponent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_24;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assetcomponents/" + this.selectedAssetComponent._id, this.selectedAssetComponent).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getAssetComponent()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_24 = _a.sent();
                        console.error("Error updating asset component: ", error_24);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.deleteAssetComponent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_25;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedAssetComponent.deleted = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/assetcomponents/" + this.selectedAssetComponent._id, this.selectedAssetComponent).toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getAssetComponent()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_25 = _a.sent();
                        console.error("Error deleting asset component: ", error_25);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AssetsService.prototype.convertAssetToFull = function (asset) {
        console.log(asset);
        var fullasset = new Fullasset();
        fullasset.assetNo = asset.assetNo;
        fullasset.assetName = asset.assetName;
        fullasset.serialNumber = asset.serialNumber;
        fullasset.assetClass = asset.assetClass;
        fullasset.assetClassDescription = asset.assetClassDescription;
        fullasset.material = asset.material;
        fullasset.location = asset.location;
        fullasset.department = asset.department;
        fullasset.person = asset.person;
        fullasset.ownership = asset.ownership;
        fullasset.created = formatDate(new Date(asset.created));
        fullasset.acquired = formatDate(new Date(asset.acquired));
        fullasset.broughtInUse = formatDate(new Date(asset.broughtInUse));
        fullasset.usefullLife = asset.usefullLife;
        fullasset.residualValue = asset.residualValue;
        fullasset.cost = asset.cost;
        fullasset.openingDepreciation = asset.openingDepreciation;
        fullasset.adty = asset.adty;
        fullasset.adld = asset.adld;
        fullasset.netBookValue = asset.netBookValue;
        return fullasset;
    };
    AssetsService.prototype.getFullAssetArray = function (assets) {
        var fullassets = new Array();
        //console.log(assets);  
        for (var i = 0; i < assets.length; i++) {
            fullassets.push(this.convertAssetToFull(assets[i]));
        }
        return fullassets;
    };
    AssetsService.prototype.getCleanFullAssets = function (fullassets) {
        var clean_assets = [];
        for (var i = 0; i < fullassets.length; i++) {
            var clean_asset = Object.assign({}, this.fullAssets[i]);
            delete clean_asset['_id'];
            clean_assets.push(clean_asset);
        }
        return clean_assets;
    };
    AssetsService.prototype.exportToExel = function () {
        this.spinner.show();
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: "Assets",
            useBom: true,
            noDownload: false,
            headers: ["Asset No", "Asset Name", 'Serial Number', "Asset Class", "Asset Class Description", "Material", "Location", "Department", "Responsible Person", "Ownership", "Date Created", "Date Acquired", "Date Brought In use", "Usefull Life", "Residual Value", "Cost", "Net Book Value", "Accumulated Depreciation Life To Date", "Accumulated Depreciation This Year"],
            nullToEmptyString: true,
        };
        new Angular5Csv(this.getFullAssetArray(this.fullAssets), 'Elico Asset Management Report', options);
        this.spinner.hide();
    };
    return AssetsService;
}());
export { AssetsService };
