/**
 * end-points.const.ts
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const ASSETS: string = '/assets';

const END_POINTS: string[] = [ASSETS];

export { END_POINTS, ASSETS };
