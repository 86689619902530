import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { UserManagementService } from './user-management.service';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(private toaster:ToastrService, public auth: AuthService, public router: Router, private usermanager:UserManagementService) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    if(
      !this.auth.isAuthenticated() || 
       this.usermanager.getUserRole() !== expectedRole
    ) {
      this.toaster.warning("No Authentication");
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
