import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public auth:AuthService) { }

  email:String;
  password:String;

  year = new Date().getFullYear();


  ngOnInit() {
  }

  login(){
    this.auth.login(this.email,this.password);
  }
  

}
