export class Group {
    _id:any;

    name : string;

    companyId: String;

    createdBy:  String;

    deleted:boolean;

    description:string;

    created:Date;

    modified: Date;
}
