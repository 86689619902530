export class AssetLocation {

    _id:any;
    
    name : string;

    street : string;

    town : string;

    country : string;

    companyId: string;

    deleted: boolean;

    created: Date;

    modified: Date;

}
