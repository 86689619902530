export class AssetMaintenanceHistory {

    _id:any;

    name : String;

    assetId: String;

    createdBy:  String;

    cost:  Number;

    description:String;

    date: Date;

    created:Date;

    modified: Date;

    deleted:boolean;
}
