/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i4 from "ngx-spinner";
import * as i5 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["color", "#29ffc6"], ["size", "large"]], null, null, null, i3.View_NgxSpinnerComponent_0, i3.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, null, 0, i4.NgxSpinnerComponent, [i4.NgxSpinnerService, i1.ChangeDetectorRef], { size: [0, "size"], color: [1, "color"], fullScreen: [2, "fullScreen"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["style", "color: white"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "large"; var currVal_1 = "#29ffc6"; var currVal_2 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
