import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  constructor(public userManager:UserManagementService,public auth:AuthService) { }

  ngOnInit() {
    this.userManager.getCompanyUsers();
    this.userManager.getRoles();
  }

  

}
