import * as tslib_1 from "tslib";
import { GroupMember } from './../models/group-member';
import { Group } from './../models/group';
import { AuthService } from './auth.service';
import { User } from './../models/user';
import { CompanyUser } from './../models/company-user';
import { Company } from './../models/company';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
var UserManagementService = /** @class */ (function () {
    function UserManagementService(spinner, toastr, http, rest, auth) {
        this.spinner = spinner;
        this.toastr = toastr;
        this.http = http;
        this.rest = rest;
        this.auth = auth;
        this.companyUsers = new Array();
        this.companies = new Array();
        /**
         * Users
         */
        this.selectedUser = new CompanyUser();
        this.users = new Array();
        this.fullname = new BehaviorSubject("Your name");
        this.link = new BehaviorSubject("https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png");
        this._id = new BehaviorSubject("_id");
        /**
         * Groups
         */
        this.selectedGroup = new Group();
        this.groups = new Array();
        this.selectedGroupMember = new GroupMember();
        this.groupMembers = new Array();
        this.currentCompany = new Company();
        /**
         * Roles
         */
        this.roles = new Array();
        this.SUPER_USER = "Super User";
        this.USER = "User";
    }
    /**
     * Load all companies which the current user is a member of
     *
     */
    UserManagementService.prototype.getCompanyIds = function () {
        var ids = '';
        for (var i = 0; i < this.companyUsers.length; i++) {
            ids = ids + this.companyUsers[i].companyId;
            if (i + 1 != this.companyUsers.length) {
                ids = ids + ",";
            }
        }
        return ids;
    };
    UserManagementService.prototype.loadCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, companyUsers, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        user = this.getUser();
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/companyusers/?email=" + user.email + "&&populate=companyId").toPromise()];
                    case 2:
                        companyUsers = _a.sent();
                        this.companyUsers = companyUsers;
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getCompanyUsers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var users, username, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/companyusers/?companyId=" + this.getCompany()._id).toPromise()];
                    case 2:
                        users = _a.sent();
                        console.log(users);
                        this.users = users;
                        username = this.getNameFromEmail(JSON.parse(localStorage.getItem('user'))['email']);
                        this.fullname.next(username);
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getNameFromEmail = function (email) {
        for (var j = 0; j < this.users.length; j++) {
            if (this.users[j].email == email && this.users[j].fullname) {
                return this.users[j].fullname;
            }
        }
        return email.split("@")[0];
    };
    UserManagementService.prototype.signupCompany = function (comp) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, company, companyUsers, settings, innerError_1, innerError_2, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        console.log(comp);
                        this.getRoles();
                        user = this.getUser();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 11, 12, 13]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + '/companies', tslib_1.__assign({}, comp, { user_email: user.email }), this.rest.httpOtions).toPromise()];
                    case 2:
                        company = _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 9, , 10]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/companyusers/?email=" + user.email + "&&companyId=" + company._id, this.rest.httpOtions).toPromise()];
                    case 4:
                        companyUsers = _a.sent();
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/usersettings/?userId=" + companyUsers[0]._id, this.rest.httpOtions).toPromise()];
                    case 6:
                        settings = _a.sent();
                        this.auth.currentCompany = company;
                        localStorage.setItem('company', JSON.stringify(company));
                        this.auth.signin(settings[0], user.email);
                        return [3 /*break*/, 8];
                    case 7:
                        innerError_1 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 8];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        innerError_2 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 10];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        error_3 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 13];
                    case 12:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.inviteNewMember = function (email, role, fullname) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newMember, companyUser, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        newMember = new CompanyUser();
                        newMember.companyId = this.getCompany()._id;
                        newMember.email = email;
                        newMember.role = role;
                        newMember.fullname = fullname;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/companyusers", tslib_1.__assign({}, newMember, { sender: this.getNameFromEmail(this.getUser().email), company: this.getCompany().name })).toPromise()];
                    case 2:
                        companyUser = _a.sent();
                        console.log(companyUser);
                        this.getCompanyUsers();
                        this.toastr.success("User successfully added!");
                        return [3 /*break*/, 5];
                    case 3:
                        error_4 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        console.error("Error adding company user", error_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.updateMember = function (email, role, fullname, userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newMember, companyUser, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        newMember = new CompanyUser();
                        newMember.companyId = this.getCompany()._id;
                        newMember.email = email;
                        newMember.role = role;
                        newMember.fullname = fullname;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/companyusers/" + userId, newMember).toPromise()];
                    case 2:
                        companyUser = _a.sent();
                        console.log(companyUser);
                        return [4 /*yield*/, this.getCompanyUsers()];
                    case 3:
                        _a.sent();
                        this.toastr.success("User updated successfully!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_5 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.changeRole = function (companyUser, roleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        companyUser.role = roleId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/companyusers/" + companyUser._id, companyUser).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getCompanyUsers()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_6 = _a.sent();
                        // Handle error here. For example, you might want to log the error or display a message.
                        console.error("An error occurred while changing the role", error_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.removeCompanyUser = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.delete(this.rest.ENDPOINT + "/companyusers/" + id).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getCompanyUsers()];
                    case 3:
                        _a.sent();
                        this.toastr.success("User successfully deleted!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_7 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.selectCompany = function (companyUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, settings, userSetting, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        user = this.getUser();
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/usersettings/?userId=" + companyUser._id, this.rest.httpOtions).toPromise()];
                    case 2:
                        settings = _a.sent();
                        userSetting = settings[0];
                        userSetting.lastOrganisation = companyUser.companyId._id;
                        this.auth.currentCompany = companyUser.companyId;
                        localStorage.setItem('company', JSON.stringify(companyUser.companyId));
                        this.auth.signin(userSetting, user.email);
                        return [3 /*break*/, 5];
                    case 3:
                        error_8 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getRoles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var roles, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/roles", this.rest.httpOtions).toPromise()];
                    case 2:
                        roles = _a.sent();
                        this.roles = roles;
                        return [3 /*break*/, 5];
                    case 3:
                        error_9 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getRoleId = function (name) {
        var id = "";
        for (var i = 0; i < this.roles.length; i++) {
            if (this.roles[i].name == name) {
                id = this.roles[i]._id;
            }
        }
        return id;
    };
    UserManagementService.prototype.getRoleName = function (id) {
        var name = "";
        for (var i = 0; i < this.roles.length; i++) {
            if (this.roles[i]._id == id) {
                name = this.roles[i].name;
            }
        }
        return name;
    };
    UserManagementService.prototype.getUser = function () {
        var user = new User();
        user = JSON.parse(localStorage.getItem('user'));
        return user;
    };
    UserManagementService.prototype.getCompany = function () {
        var company = new Company();
        company = JSON.parse(localStorage.getItem('company'));
        return company;
    };
    UserManagementService.prototype.isLoggedInUser = function (email) {
        return this.getUser().email === email;
    };
    UserManagementService.prototype.updateCompanyAssetCount = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var company, res, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        company = this.getCompany();
                        company.assetCount += index;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + '/companies/' + company._id, company).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(company);
                        localStorage.removeItem("company");
                        localStorage.setItem('company', JSON.stringify(company));
                        return [3 /*break*/, 5];
                    case 3:
                        error_10 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Groups
     */
    UserManagementService.prototype.addGroup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedGroup.companyId = this.getCompany()._id;
                        this.selectedGroup.createdBy = this.getUser()._id;
                        this.groupMembers = new Array();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/groups", this.selectedGroup).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        this.toastr.success("Group Successfully added!");
                        this.selectedGroup = res;
                        return [4 /*yield*/, this.getGroups()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_11 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getGroups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groups, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/groups/?companyId=" + this.getCompany()._id).toPromise()];
                    case 2:
                        groups = _a.sent();
                        this.groups = groups;
                        return [3 /*break*/, 5];
                    case 3:
                        error_12 = _a.sent();
                        // Handle error here, such as logging or showing an error message
                        console.error("Error fetching groups:", error_12);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.updateGroup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/groups/" + this.selectedGroup._id, this.selectedGroup).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getGroups()];
                    case 3:
                        _a.sent();
                        this.toastr.success("Group successfully updated!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_13 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.removeGroup = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, 6, 7]);
                        return [4 /*yield*/, this.http.delete(this.rest.ENDPOINT + "/groups/" + id).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getGroups()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.removeAllMembers(id)];
                    case 4:
                        _a.sent();
                        this.toastr.success("Group successfully removed!");
                        return [3 /*break*/, 7];
                    case 5:
                        error_14 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 7];
                    case 6:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getGroupNameById = function (id) {
        for (var i = 0; i < this.groups.length; i++) {
            if (this.groups[i]._id == id) {
                return this.groups[i].name;
            }
        }
    };
    UserManagementService.prototype.getUserNameById = function (id) {
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i]._id == id) {
                return this.getNameFromEmail(this.users[i].email);
            }
        }
        return id;
    };
    UserManagementService.prototype.addGroupMember = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.selectedGroupMember.email = this.selectedUser.email;
                        this.selectedGroupMember.groupId = this.selectedGroup._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/groupmembers", this.selectedGroupMember).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getGroupMembers()];
                    case 3:
                        _a.sent();
                        this.selectedUser = new CompanyUser();
                        this.toastr.success("Group member added!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_15 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getGroupMembers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var members, error_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/groupmembers/?groupId=" + this.selectedGroup._id).toPromise()];
                    case 2:
                        members = _a.sent();
                        this.groupMembers = members;
                        return [3 /*break*/, 5];
                    case 3:
                        error_16 = _a.sent();
                        // Handle error here. For example, you might want to log the error or display a message.
                        console.error("An error occurred while fetching group members", error_16);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.removeMember = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.delete(this.rest.ENDPOINT + "/groupmembers/" + id).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getGroupMembers()];
                    case 3:
                        _a.sent();
                        this.selectedGroupMember = new GroupMember();
                        return [3 /*break*/, 6];
                    case 4:
                        error_17 = _a.sent();
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.removeAllMembers = function (groupId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.delete(this.rest.ENDPOINT + '/groupmembers/?groupId=' + groupId).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        return [4 /*yield*/, this.getGroupMembers()];
                    case 3:
                        _a.sent();
                        this.toastr.success("All members removed successfully!");
                        return [3 /*break*/, 6];
                    case 4:
                        error_18 = _a.sent();
                        console.error("Error removing all members: ", error_18);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.isAlreadyMember = function (email) {
        var isMember = false;
        for (var i = 0; i < this.groupMembers.length; i++) {
            if (this.groupMembers[i].email == email) {
                return true;
            }
        }
        return isMember;
    };
    UserManagementService.prototype.getUserIdByEmail = function (email) {
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].email == email) {
                return this.users[i]._id;
            }
        }
    };
    UserManagementService.prototype.updatePassword = function (oldPassword, newPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/changepassword", {
                                _id: this.getUser()._id,
                                newPassword: newPassword,
                                oldPassword: oldPassword
                            }).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        this.toastr.info(res['message']);
                        return [3 /*break*/, 5];
                    case 3:
                        error_19 = _a.sent();
                        console.error("Error updating password: ", error_19);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.resetPassword = function (newPassword, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_20;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/resetPassword", {
                                email: email,
                                newPassword: newPassword
                            }).toPromise()];
                    case 2:
                        res = _a.sent();
                        console.log(res);
                        this.toastr.info(res['message']);
                        return [3 /*break*/, 5];
                    case 3:
                        error_20 = _a.sent();
                        console.error("Error resetting password: ", error_20);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * company Update
     */
    UserManagementService.prototype.updateCompany = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var company, error_21;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        this.currentCompany._id = this.getCompany()._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.http.put(this.rest.ENDPOINT + "/companies/" + this.currentCompany._id, this.currentCompany).toPromise()];
                    case 2:
                        company = _a.sent();
                        console.log(company);
                        this.toastr.success("Company Successfully Updated!");
                        return [4 /*yield*/, this.getUpdatedCompany()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_21 = _a.sent();
                        console.error("Error updating company: ", error_21);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 6];
                    case 5:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getUpdatedCompany = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var company, error_22;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/companies/" + this.currentCompany._id).toPromise()];
                    case 2:
                        company = _a.sent();
                        console.log(company);
                        localStorage.removeItem("company");
                        this.currentCompany = company;
                        this.auth.currentCompany = company;
                        localStorage.setItem("company", JSON.stringify(company));
                        return [3 /*break*/, 5];
                    case 3:
                        error_22 = _a.sent();
                        console.error("Error fetching updated company details: ", error_22);
                        this.toastr.error(this.rest.ERROR_MESSAGE);
                        return [3 /*break*/, 5];
                    case 4:
                        this.spinner.hide();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UserManagementService.prototype.getUserRole = function () {
        var user = this.getUser();
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].email == user.email) {
                for (var j = 0; j < this.roles.length; j++) {
                    if (this.roles[j]._id == this.users[i].role) {
                        return this.roles[j].name;
                    }
                }
            }
        }
    };
    UserManagementService.prototype.isSuperUser = function () {
        var user = this.getUser();
        var user_email = this.auth.currentUser.email;
        if (!user_email) {
            user_email = user.email;
        }
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].email == user_email) {
                for (var j = 0; j < this.roles.length; j++) {
                    if (this.roles[j]._id == this.users[i].role) {
                        return this.roles[j].name == this.SUPER_USER;
                    }
                }
            }
        }
        return false;
    };
    UserManagementService.prototype.createRole = function (name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_23;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.post(this.rest.ENDPOINT + "/roles", { name: name }).toPromise()];
                    case 1:
                        res = _a.sent();
                        console.log(res);
                        return [3 /*break*/, 3];
                    case 2:
                        error_23 = _a.sent();
                        console.error("Error creating role: ", error_23);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    //chech roles are added
    UserManagementService.prototype.testRole = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var roles, error_24;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.http.get(this.rest.ENDPOINT + "/roles").toPromise()];
                    case 1:
                        roles = _a.sent();
                        if (!(roles.length === 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.createRole(this.SUPER_USER)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.createRole(this.USER)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        console.log("Roles already created...");
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_24 = _a.sent();
                        console.error("Error testing role: ", error_24);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return UserManagementService;
}());
export { UserManagementService };
