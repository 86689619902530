import { AuthGuardService } from './application/services/auth-guard.service';
import { Http, HttpModule } from '@angular/http';
import { FilesService } from './application/services/files.service';
import { AssetsService } from './application/services/assets.service';
import { LicenseService } from './application/services/license.service';
import { UserManagementService } from './application/services/user-management.service';
import { RestService } from './application/services/rest.service';
import { AuthService } from './application/services/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { DashboardComponent } from './application/dashboard/dashboard.component';

import { UserComponent } from './application/user/user.component';
import { LicenseComponent } from './application/license/license.component';
import { ReportComponent } from './application/report/report.component';
import { LogComponent } from './application/log/log.component';
import { NotificationComponent } from './application/notification/notification.component';
import { TopmenuComponent } from './application/topmenu/topmenu.component';
import { SidemenuComponent } from './application/sidemenu/sidemenu.component';
import { FooterComponent } from './application/footer/footer.component';
import { LoginComponent } from './application/login/login.component';

import {RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router'
import { appRoutes } from './routes';
import { SignupComponent } from './application/signup/signup.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { OrganisationComponent } from './application/organisation/organisation.component';
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { ReportingService } from './application/services/reporting.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { AssetModuleComponent } from './application/asset-module/asset-module.component';

import { NgxBarcodeModule } from 'ngx-barcode';
import {JwtModule, JwtModuleOptions} from '@auth0/angular-jwt'
import { RoleGuardService } from './application/services/role-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import {ToastrModule} from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SettingComponent } from './application/setting/setting.component';
import { AssetFormComponent } from './application/asset-module/modals/asset-form/asset-form.component';
import { AssetClassFormComponent } from './application/asset-module/modals/asset-class-form/asset-class-form.component';

export function tokenGetter() {
   return localStorage.getItem('access_token');
}

export class AARouteReuseStrategy extends RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, handle: {}): void {

  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): {} {
     return null;
 }
 shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
   return false; // default is true if configuration of current and future route are the same
 }
}

@NgModule({ 
  declarations: [ 
    AppComponent,
    DashboardComponent,
    UserComponent, 
    LicenseComponent,
    ReportComponent,
    LogComponent,
    NotificationComponent,
    TopmenuComponent,
    SidemenuComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    OrganisationComponent,
    AssetModuleComponent,
    SettingComponent,
    AssetFormComponent,
    AssetClassFormComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes,{useHash:true}),
    FormsModule,
    HttpClientModule,
    FilterPipeModule,
    HttpModule,
    NgxBarcodeModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
    NgxSpinnerModule,
    
  ],
  
  providers: [

    {
      provide: RouteReuseStrategy,
      useClass: AARouteReuseStrategy
    },
    
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    
    AuthService,
    RestService,
    UserManagementService,
    ReportingService,
    LicenseService,
    AssetsService,
    FilesService,
    AuthGuardService,
    RoleGuardService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
