import { AssetClass } from './../models/asset-class';
import { AssetsService } from './assets.service';

import { GroupMember } from './../models/group-member';
import { Group } from './../models/group';
import { UserSetting } from './../models/user-setting';
import { AuthService } from './auth.service';
import { User } from './../models/user';
import { CompanyUser } from './../models/company-user';
import { Company } from './../models/company';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from '../models/role';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { CompanyUserRole } from '../models/company-user-role';

@Injectable()
export class UserManagementService {

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private rest: RestService, private auth: AuthService) { }

  companyUsers = new Array<CompanyUser>();

  companies = new Array<Company>();


  /**
   * Users
   */

  selectedUser = new CompanyUser();

  users = new Array<CompanyUser>();

  public fullname: BehaviorSubject<string> = new BehaviorSubject("Your name");
  public link: BehaviorSubject<string> = new BehaviorSubject("https://i.pinimg.com/originals/0c/3b/3a/0c3b3adb1a7530892e55ef36d3be6cb8.png");
  public _id: BehaviorSubject<string> = new BehaviorSubject("_id");

  /**
   * Groups
   */

  selectedGroup = new Group();
  groups = new Array<Group>();


  selectedGroupMember = new GroupMember();

  groupMembers = new Array<GroupMember>();

  currentCompany = new Company();


  /**
   * Roles
   */
  roles = new Array<Role>();

  SUPER_USER = "Super User";

  USER = "User";




  /**
   * Load all companies which the current user is a member of 
   *
   */

  getCompanyIds() {
    let ids = '';
    for (let i = 0; i < this.companyUsers.length; i++) {
      ids = ids + this.companyUsers[i].companyId;
      if (i + 1 != this.companyUsers.length) {
        ids = ids + ","
      }
    }
    return ids;
  }


  async loadCompanies() {
      this.spinner.show();

      try {
          let user: User = this.getUser();
          const companyUsers = await this.http.get<Array<CompanyUser>>(
              `${this.rest.ENDPOINT}/companyusers/?email=${user.email}&&populate=companyId`
          ).toPromise();

          this.companyUsers = companyUsers;
      } catch (error) {
          this.toastr.error(this.rest.ERROR_MESSAGE);
      } finally {
          this.spinner.hide();
      }
  }


  async getCompanyUsers() {
    this.spinner.show();
    try {
      const users: Array<CompanyUser> = await this.http.get<Array<CompanyUser>>(this.rest.ENDPOINT + "/companyusers/?companyId=" + this.getCompany()._id).toPromise();
      console.log(users);
      this.users = users;
  
      let username = this.getNameFromEmail(JSON.parse(localStorage.getItem('user'))['email']);
      this.fullname.next(username);
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  getNameFromEmail(email: String): string {

    for (let j = 0; j < this.users.length; j++) {

      if (this.users[j].email == email && this.users[j].fullname) {
        return this.users[j].fullname;
      }

    }
    return email.split("@")[0];
  }


  async signupCompany(comp: Company) {
    this.spinner.show();
    console.log(comp);
  
    this.getRoles();
  
    let user = this.getUser();
  
    try {
      const company = await this.http.post<Company>(this.rest.ENDPOINT + '/companies', { ...comp, user_email: user.email }, this.rest.httpOtions).toPromise();

      try{
        const companyUsers = await this.http.get<Array<CompanyUser>>(`${this.rest.ENDPOINT}/companyusers/?email=${user.email}&&companyId=${company._id}`, this.rest.httpOtions).toPromise();
        try {
          const settings = await this.http.get<Array<UserSetting>>(`${this.rest.ENDPOINT}/usersettings/?userId=${companyUsers[0]._id}`, this.rest.httpOtions).toPromise();
          
          this.auth.currentCompany = company;
          localStorage.setItem('company', JSON.stringify(company));
          this.auth.signin(settings[0], user.email);
        } catch (innerError) {
          this.toastr.error(this.rest.ERROR_MESSAGE);
        }
      } catch (innerError) {
        this.toastr.error(this.rest.ERROR_MESSAGE);
      }
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  async inviteNewMember(email, role, fullname) {

    this.spinner.show();

    let newMember = new CompanyUser();
    
    newMember.companyId = this.getCompany()._id;
    newMember.email = email;
    newMember.role = role;
    newMember.fullname = fullname;

    try {
        const companyUser = await this.http.post<CompanyUser>(this.rest.ENDPOINT + "/companyusers", {
            ...newMember,
            sender: this.getNameFromEmail(this.getUser().email),
            company: this.getCompany().name,
        }).toPromise();

        console.log(companyUser);
        this.getCompanyUsers();

        this.toastr.success("User successfully added!");

    } catch (error) {
        this.toastr.error(this.rest.ERROR_MESSAGE);
        console.error("Error adding company user", error);
        // You may also want to show a user-friendly error message here
    } finally {
        this.spinner.hide();
    }
}


async updateMember(email, role, fullname, userId) {
  this.spinner.show();
  let newMember = new CompanyUser();
  newMember.companyId = this.getCompany()._id;
  newMember.email = email;
  newMember.role = role;
  newMember.fullname = fullname;

  try {
    const companyUser = await this.http.put<CompanyUser>(this.rest.ENDPOINT + "/companyusers/" + userId, newMember).toPromise();
    
    console.log(companyUser);

    await this.getCompanyUsers();

    this.toastr.success("User updated successfully!");
  } catch (error) {
    this.toastr.error(this.rest.ERROR_MESSAGE);
  } finally {
    this.spinner.hide();
  }
}


async changeRole(companyUser: CompanyUser, roleId) {
  companyUser.role = roleId;
  
  try {
    const res = await this.http.put(this.rest.ENDPOINT + "/companyusers/" + companyUser._id, companyUser).toPromise();
    console.log(res);
    await this.getCompanyUsers();
  } catch (error) {
    // Handle error here. For example, you might want to log the error or display a message.
    console.error("An error occurred while changing the role", error);
  }
}

async removeCompanyUser(id) {
  this.spinner.show();

  try {
    const res = await this.http.delete(this.rest.ENDPOINT + "/companyusers/" + id).toPromise();
    console.log(res);
    await this.getCompanyUsers();
    this.toastr.success("User successfully deleted!");
  } catch (error) {
    this.toastr.error(this.rest.ERROR_MESSAGE);
  } finally {
    this.spinner.hide();
  }
}


  async selectCompany(companyUser: CompanyUser) {
    this.spinner.show();

    try {
      const user = this.getUser();
      const settings = await this.http.get<Array<UserSetting>>(
        `${this.rest.ENDPOINT}/usersettings/?userId=${companyUser._id}`,
        this.rest.httpOtions
      ).toPromise();

      let userSetting = settings[0];

      userSetting.lastOrganisation = companyUser.companyId._id;

      this.auth.currentCompany = companyUser.companyId;

      localStorage.setItem('company', JSON.stringify(companyUser.companyId));

      this.auth.signin(userSetting, user.email);

    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }

  async getRoles() {
    this.spinner.show();
  
    try {
      const roles: Array<Role> = await this.http.get<Array<Role>>(this.rest.ENDPOINT + "/roles", this.rest.httpOtions).toPromise();
      this.roles = roles;
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  getRoleId(name: string): string {

    let id: string = "";

    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].name == name) {
        id = this.roles[i]._id;
      }
    }

    return id;
  }

  getRoleName(id: String) {
    let name: String = "";

    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i]._id == id) {
        name = this.roles[i].name;
      }
    }

    return name;
  }

  getUser(): User {
    let user = new User();
    user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getCompany(): Company {
    let company = new Company();
    company = JSON.parse(localStorage.getItem('company'));
    return company;
  }

  isLoggedInUser(email): boolean {

    return this.getUser().email === email;
  }

  async updateCompanyAssetCount(index: number) {
    this.spinner.show();
    let company = this.getCompany();
    company.assetCount += index;
  
    try {
      const res = await this.http.put(this.rest.ENDPOINT + '/companies/' + company._id, company).toPromise();
      
      console.log(company);
      localStorage.removeItem("company");
      localStorage.setItem('company', JSON.stringify(company));
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  


  /**
   * Groups
   */

  async addGroup() {
    this.spinner.show();
    this.selectedGroup.companyId = this.getCompany()._id;
    this.selectedGroup.createdBy = this.getUser()._id;
  
    this.groupMembers = new Array<GroupMember>();
  
    try {
      const res = await this.http.post<Group>(this.rest.ENDPOINT + "/groups", this.selectedGroup).toPromise();
      console.log(res);
      this.toastr.success("Group Successfully added!");
      this.selectedGroup = res;
  
      await this.getGroups();

    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  async getGroups() {
  this.spinner.show();

  try {
    const groups: Array<Group> = await this.http.get<Array<Group>>(this.rest.ENDPOINT + "/groups/?companyId=" + this.getCompany()._id).toPromise();
    this.groups = groups;
  } catch (error) {
    // Handle error here, such as logging or showing an error message
    console.error("Error fetching groups:", error);
  } finally {
    this.spinner.hide();
  }
}

async updateGroup() {
  this.spinner.show();
  
  try {
    const res = await this.http.put(this.rest.ENDPOINT + "/groups/" + this.selectedGroup._id, this.selectedGroup).toPromise();
    console.log(res);
    await this.getGroups();
    this.toastr.success("Group successfully updated!");
  } catch (error) {
    this.toastr.error(this.rest.ERROR_MESSAGE);
  } finally {
    this.spinner.hide();
  }
}


async removeGroup(id) {
  this.spinner.show();

  try {
    const res = await this.http.delete(this.rest.ENDPOINT + "/groups/" + id).toPromise();
    console.log(res);
    await this.getGroups();
    await this.removeAllMembers(id);
    this.toastr.success("Group successfully removed!");
  } catch (error) {
    this.toastr.error(this.rest.ERROR_MESSAGE);
  } finally {
    this.spinner.hide();
  }
}


  getGroupNameById(id): string {

    for (let i = 0; i < this.groups.length; i++) {

      if (this.groups[i]._id == id) {
        return this.groups[i].name;
      }
    }
  }

  getUserNameById(id): string {

    for (let i = 0; i < this.users.length; i++) {

      if (this.users[i]._id == id) {
        return this.getNameFromEmail(this.users[i].email);
      }

    }

    return id;
  }

  async addGroupMember() {
    this.spinner.show();
    this.selectedGroupMember.email = this.selectedUser.email;
    this.selectedGroupMember.groupId = this.selectedGroup._id;
  
    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/groupmembers", this.selectedGroupMember).toPromise();
      console.log(res);
      await this.getGroupMembers();
      this.selectedUser = new CompanyUser();
      this.toastr.success("Group member added!");
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  
  async getGroupMembers() {
    this.spinner.show();
  
    try {
      const members: Array<GroupMember> = await this.http.get<Array<GroupMember>>(this.rest.ENDPOINT + "/groupmembers/?groupId=" + this.selectedGroup._id).toPromise();
      this.groupMembers = members;
    } catch (error) {
      // Handle error here. For example, you might want to log the error or display a message.
      console.error("An error occurred while fetching group members", error);
    } finally {
      this.spinner.hide();
    }
  }
  

  async removeMember(id) {
    this.spinner.show();
  
    try {
      const res = await this.http.delete(this.rest.ENDPOINT + "/groupmembers/" + id).toPromise();
      console.log(res);
      await this.getGroupMembers();
      this.selectedGroupMember = new GroupMember();
    } catch (error) {
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  
  async removeAllMembers(groupId) {
    this.spinner.show();
    try {
      const res = await this.http.delete(this.rest.ENDPOINT + '/groupmembers/?groupId=' + groupId).toPromise();
      console.log(res);
      await this.getGroupMembers();
      this.toastr.success("All members removed successfully!");
    } catch (error) {
      console.error("Error removing all members: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  isAlreadyMember(email): Boolean {

    let isMember = false;
    for (let i = 0; i < this.groupMembers.length; i++) {

      if (this.groupMembers[i].email == email) {
        return true;
      }

    }
    return isMember;
  }


  getUserIdByEmail(email) {
    for (let i = 0; i < this.users.length; i++) {

      if (this.users[i].email == email) {
        return this.users[i]._id;
      }
    }
  }

  async updatePassword(oldPassword, newPassword) {
    this.spinner.show();
  
    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/changepassword", {
        _id: this.getUser()._id,
        newPassword: newPassword,
        oldPassword: oldPassword
      }).toPromise();
  
      console.log(res);
      this.toastr.info(res['message']);
    } catch (error) {
      console.error("Error updating password: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  async resetPassword(newPassword, email) {
    this.spinner.show();
  
    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/resetPassword", {
        email: email,
        newPassword: newPassword
      }).toPromise();
  
      console.log(res);
      this.toastr.info(res['message']);
    } catch (error) {
      console.error("Error resetting password: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  /**
   * company Update
   */


  async updateCompany() {
    this.spinner.show();
    this.currentCompany._id = this.getCompany()._id;
  
    try {
      const company = await this.http.put<Company>(this.rest.ENDPOINT + "/companies/" + this.currentCompany._id, this.currentCompany).toPromise();
      console.log(company);
      this.toastr.success("Company Successfully Updated!");
      await this.getUpdatedCompany();
    } catch (error) {
      console.error("Error updating company: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  async getUpdatedCompany() {
    this.spinner.show();
  
    try {
      const company = await this.http.get<Company>(this.rest.ENDPOINT + "/companies/" + this.currentCompany._id).toPromise();
      console.log(company);
  
      localStorage.removeItem("company");
      this.currentCompany = company;
      this.auth.currentCompany = company;
      localStorage.setItem("company", JSON.stringify(company));
    } catch (error) {
      console.error("Error fetching updated company details: ", error);
      this.toastr.error(this.rest.ERROR_MESSAGE);
    } finally {
      this.spinner.hide();
    }
  }
  

  getUserRole() {
    let user = this.getUser();
    for (let i = 0; i < this.users.length; i++) {

      if (this.users[i].email == user.email) {
        for (let j = 0; j < this.roles.length; j++) {

          if (this.roles[j]._id == this.users[i].role) {
            return this.roles[j].name;
          }

        }
      }

    }
  }

  isSuperUser(): boolean {
    let user = this.getUser();

    let user_email = this.auth.currentUser.email;

    if (!user_email) {
      user_email = user.email;
    }

    for (let i = 0; i < this.users.length; i++) {

      if (this.users[i].email == user_email) {

        for (let j = 0; j < this.roles.length; j++) {

          if (this.roles[j]._id == this.users[i].role) {
            return this.roles[j].name == this.SUPER_USER;
          }

        }
      }

    }
    return false;
  }

  async createRole(name) {
    try {
      const res = await this.http.post(this.rest.ENDPOINT + "/roles", { name: name }).toPromise();
      console.log(res);
      // Additional actions upon successful creation can be added here
    } catch (error) {
      console.error("Error creating role: ", error);
      // Error handling actions can be added here
    }
  }
  
  //chech roles are added
  async testRole() {
    try {
      const roles = await this.http.get<Array<Role>>(this.rest.ENDPOINT + "/roles").toPromise();
  
      if (roles.length === 0) {
        await this.createRole(this.SUPER_USER);
        await this.createRole(this.USER);
      } else {
        console.log("Roles already created...");
      }
    } catch (error) {
      console.error("Error testing role: ", error);
      // Error handling actions can be added here
    }
  }
  






}
