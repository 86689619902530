/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidemenu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./sidemenu.component";
import * as i5 from "../services/user-management.service";
import * as i6 from "../services/auth.service";
var styles_SidemenuComponent = [i0.styles];
var RenderType_SidemenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidemenuComponent, data: {} });
export { RenderType_SidemenuComponent as RenderType_SidemenuComponent };
function View_SidemenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "nav-link "], ["href", "#/users"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["group"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Users"]))], null, null); }
export function View_SidemenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 46, "aside", [["class", "main-sidebar col-12 col-md-3 col-lg-2 px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "main-navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "nav", [["class", "navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "navbar-brand w-100 mr-0"], ["href", "#"], ["style", "line-height: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "d-table m-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "d-inline-block align-top mr-1"], ["id", "main-logo"], ["src", "../../../assets/img/logo.png"], ["style", "max-width: 120px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "toggle-sidebar d-sm-inline d-md-none d-lg-none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\uE5C4"])), (_l()(), i1.ɵeld(9, 0, null, null, 9, "form", [["action", "#"], ["class", "main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(11, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "input-group input-group-seamless ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "input-group-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "input", [["aria-label", "Search"], ["class", "navbar-search form-control"], ["placeholder", "Search for something..."], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 27, "div", [["class", "nav-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 26, "ul", [["class", "nav flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 5, "li", [["class", "nav-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.auth.goToRout("dashboard") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "a", [["class", "nav-link"], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["dashboard"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"])), (_l()(), i1.ɵeld(27, 0, null, null, 5, "li", [["class", "nav-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.auth.goToRout("assets") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 4, "a", [["class", "nav-link "], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["time_to_leave"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assets"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidemenuComponent_1)), i1.ɵdid(34, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(35, 0, null, null, 5, "li", [["class", "nav-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.auth.goToRout("reports") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 4, "a", [["class", "nav-link "], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["timeline"])), (_l()(), i1.ɵeld(39, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reports"])), (_l()(), i1.ɵeld(41, 0, null, null, 5, "li", [["class", "nav-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.auth.goToRout("settings") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 4, "a", [["class", "nav-link "], ["href", "javascript:;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(43, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["settings"])), (_l()(), i1.ɵeld(45, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Settings"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.userManager.isSuperUser(); _ck(_v, 34, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SidemenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidemenu", [], null, null, null, View_SidemenuComponent_0, RenderType_SidemenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.SidemenuComponent, [i5.UserManagementService, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidemenuComponentNgFactory = i1.ɵccf("app-sidemenu", i4.SidemenuComponent, View_SidemenuComponent_Host_0, {}, {}, []);
export { SidemenuComponentNgFactory as SidemenuComponentNgFactory };
