import { Component, Input, OnInit } from '@angular/core';
import { AssetsService } from '../../../services/assets.service';
import { UserManagementService } from '../../../services/user-management.service';
import { ASSET_OWNERSHIP } from '../../../../config/enums/asset-ownership.enum';
import { ASSET_CONDITIONS } from '../../../../config/enums/asset-conditions.enum';

@Component({
  selector: 'asset-form',
  templateUrl: './asset-form.component.html',
  styleUrls: ['./asset-form.component.css']
})
export class AssetFormComponent implements OnInit {

  @Input() display: boolean = false;

  ownerships: string[] = ASSET_OWNERSHIP
  
  conditions: string[] = ASSET_CONDITIONS


  constructor(public assetService: AssetsService, public userManagementService:UserManagementService) { }

  ngOnInit() {
  }

  close() {
    this.display = false;
  }

}
