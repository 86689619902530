import { UserManagementService } from './../services/user-management.service';
import { AssetsService } from './../services/assets.service';
import { ReportingService } from './../services/reporting.service';
import { Component, OnInit } from '@angular/core';


import * as jspdf from 'jspdf'; 
 
import html2canvas from 'html2canvas';
import { RestService } from '../services/rest.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  constructor(public reportService: ReportingService, public assetService:AssetsService, public userManagementService:UserManagementService, public rest:RestService,public auth:AuthService) { }

  ngOnInit() {
    this.userManagementService.getGroups();
    this.userManagementService.getCompanyUsers();
    this.assetService.getAssetLocations();
    this.assetService.getAssetClasses();
    this.reportService.getReports();
  }

  selected_asset = "";

  printReport(){
    // let popupWinindow
    // let innerContents = document.getElementById("report").innerHTML;
    // popupWinindow = window.open('', '_blank', 'width=1000,height=1000,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    // popupWinindow.document.open();
    // popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + innerContents + '</html>');
    // popupWinindow.document.close();

    var data = document.getElementById('report');
    html2canvas(data).then(canvas => {
    // Few necessary setting options
    var imgWidth = 295;
    var pageHeight = 210;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
     
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    pdf.save('Report.pdf'); // Generated PDF
    });

    
        
  }

  getImage(id):String{
    let link = "6767tfhfyu";
    link= this.rest.ENDPOINT+"/stream/img-"+id;
    return link;
  }  

  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    //document.body.innerHTML = originalContents;

    document.body.innerHTML = originalContents;
    setTimeout(function() {
                location.reload();
    }, 1000);
    
  }


  selectAsset(asset){
    this.selected_asset=asset;
  }

}
